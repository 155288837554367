/* -----------------------------------
    Custom CSS styles
------------------------------------*/
.d-flex {
  display: flex;
}

/*#shopify-login-form {*/
/*    display: none;*/
/*}*/
#logout-success {
  font-size: 18px;
  margin: 2rem 0 2rem 0;
  background-color: #e5f7f2;
  padding: 5px 15px;
}
.input-success {
  font-weight: 400;
  margin-top: 10px;
}
.input-errors,
#validations-errors {
  color: #ff565f;
  font-weight: 400;
  margin-top: 10px;
}

#validations-errors {
  font-size: 15px;
  margin-bottom: 15px;
  background-color: #ffd2d2;
  padding: 5px 15px;
}

#validations-errors:empty {
  display: none;
}

.form-content .form-button .ibtn {
  padding: 12px 36px;
}

.form-content input {
  font-size: medium;
  margin-bottom: 0px !important;
}

.form-control {
  height: 50px !important;
  border-radius: 50px !important;
}

.form-content .HCC-Validate__Input {
  margin-bottom: 15px;
}

.form-content .other-links a {
  font-size: 15px;
}

.form-content .other-links a .login-icons {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  padding: 7px;
  margin-right: 10px;
}

.form-content .other-links a .login-icons.google-icon {
  background-color: #dd4b39;
}

.form-content .other-links a .login-icons.shopify-icon {
  background-color: #96bf48;
}

.form-content .platform-input-wrapper {
  display: flex;
}

.form-content .platform-input-wrapper .platform-input-button {
  width: 250px;
  height: 75px;
  border-radius: 26px;
  display: flex;
  background-color: white;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
  border: 1px solid #d4d4d4;
}

.form-content .platform-input-wrapper .platform-input-button:hover {
  box-shadow: 0 0 2px 1px grey;
  transform: translate(0, -2px);
  transition: 0.3s;
}

.form-content .platform-input-wrapper .platform-input-button .platform-logo {
  width: 37px;
  background-color: white;
  margin-right: 15px;
  background-size: contain;
  background-position: center;
}

.form-content .platform-input-wrapper .platform-input-button .platform-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.wizard-progress-stepper {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
}

.wizard-progress-stepper span {
  display: block;
  height: 12px;
  width: 12px;
  margin: 5px;
  border-radius: 50%;
  background: #5cbaff;
  cursor: pointer;
}

.wizard-progress-stepper span.is-active {
  background: rgba(0, 0, 0, 0.4);
  background: #fff;
}

.btn-google {
  border-radius: 6px;
  margin-bottom: 15px;
  height: 60px;
  color: white !important;
  margin-left: -5px;
}

.form-content .other-links a {
  color: #8d8d8d;
  font-size: 18px;
}

.section-title {
  text-decoration: underline;
  text-decoration-color: white;
}

.back-link {
  display: flex;
  align-items: center;
}

.back-link img {
  width: 15px;
  margin-right: 7px;
}

.back-link a {
  font-weight: 700;
}
.form-content .form-sent,
.form-content .form-password-reset {
  position: absolute;
  text-align: center;
  z-index: 1;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
}

.form-content .form-sent.show-it {
  opacity: 1;
  pointer-events: all;
  z-index: 2;
  -webkit-transform: scale(1) translateX(0);
  -moz-transform: scale(1) translateX(0);
  -ms-transform: scale(1) translateX(0);
  transform: scale(1) translateX(0);
}

.form-content .form-sent.show-it .tick-holder .tick-icon {
  -webkit-animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  -moz-animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  -ms-animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
}

.form-content .form-sent.show-it .tick-holder .tick-icon:before {
  -webkit-animation: tick-anime1 0.2s linear 0.2s forwards;
  -moz-animation: tick-anime1 0.2s linear 0.2s forwards;
  -ms-animation: tick-anime1 0.2s linear 0.2s forwards;
  animation: tick-anime1 0.2s linear 0.2s forwards;
}

.form-content .form-sent.show-it .tick-holder .tick-icon:after {
  -webkit-animation: tick-anime2 0.4s ease 0.4s forwards;
  -moz-animation: tick-anime2 0.4s ease 0.4s forwards;
  -ms-animation: tick-anime2 0.4s ease 0.4s forwards;
  animation: tick-anime2 0.4s ease 0.4s forwards;
}

.form-content .form-sent .tick-holder {
  text-align: center;
  margin-bottom: 12px;
}

.form-content .form-sent .tick-holder .tick-icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transform: rotate(35deg) scale(2);
  -moz-transform: rotate(35deg) scale(2);
  -ms-transform: rotate(35deg) scale(2);
  transform: rotate(35deg) scale(2);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}

.form-content .form-sent .tick-holder .tick-icon:before {
  content: '';
  position: absolute;
  background-color: #fff;
  width: 10px;
  height: 2px;
  top: 28px;
  left: 14px;
  border-radius: 2px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.form-content .form-sent .tick-holder .tick-icon:after {
  content: '';
  position: absolute;
  background-color: #fff;
  width: 2px;
  height: 20px;
  top: 9px;
  left: 22px;
  border-radius: 2px;
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}

.form-content .form-sent h3 {
  text-align: center;
  color: #fff;
}

.form-content .form-sent p {
  text-align: center;
  color: #fff;
  font-size: 15px;
  opacity: 0.8;
  margin-bottom: 20px;
}

.form-content .form-sent .info-holder {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px;
  margin-top: 60px;
}

.form-content .form-sent .info-holder span {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  opacity: 0.6;
}

.form-content .form-sent .info-holder a {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  opacity: 0.9;
}

.form-content .hide-it {
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(0.7) translateX(-200px);
  -moz-transform: scale(0.7) translateX(-200px);
  -ms-transform: scale(0.7) translateX(-200px);
  transform: scale(0.7) translateX(-200px);
}
#content {
  overflow: hidden;
}
main aside {
  background: url('../../resources/auth/Illus.svg') no-repeat 50% 50%;
  background-color: rgba(232, 93, 4, 1);
}

main .container .form__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30%;
  justify-content: flex-end;
}

.form__header img.logo {
  height: 108px;
  object-fit: cover;
}

.HCC-Login__Button,
.HCC-Forgot-Password__Button,
.HCC-Signup__Button,
.HCC-Reset-Password__Button {
  width: 240px;
  height: 54px;
  border-radius: 30px !important;
  background-color: rgba(232, 93, 4, 1);
  border-color: rgba(232, 93, 4, 1);
  margin: 10px 0;
}

#HCC-login-page .form__header p,
#HCC-signup-page .form__header p {
  color: rgba(106, 119, 134, 1);
  margin: 1rem 0;
}

#HCC-login-page .forgot-password {
  text-align: right;
}

.password-field {
  position: relative;
}
.password-field > p {
  color: rgba(60, 65, 71, 1);
  padding-left: 10px;
  font-size: 16px;
}
.password-field .far {
  position: absolute;
  right: 10px;
  top: 18px;
}

.form__footer .or-text {
  font-weight: 900;
  font-size: 24px;
  margin: 10px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}

.forgot-password a {
  color: rgba(232, 93, 4, 1);
  margin-right: 10px;
}

#google-login-btn,
i.far {
  border-radius: 50px;
  cursor: pointer;
}

.signup-login-page-link {
  color: #000000;
}

.form__footer {
  margin-top: 20px;
}

#HCC-forgot-password-page .form-fields > div:first-child,
#HCC-reset-password-page .form-fields > div:first-child {
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 20px;
}

.success-box {
  display: flex;
  justify-content: center;
  height: 274px;
}

.success-box .box {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  width: 396px;
  margin-top: -50px;
  background: #f5f4f4;
  border-radius: 10px;
}

.success-box .action {
  width: 100%;
  height: 80px;
  margin-top: 30px;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-around;
}

.success-box button {
  width: 144px;
  height: 38px;
  padding: 0;
}
.success-box > svg > circle {
  fill: #e85d04;
  stroke: #e85d04;
}
.success-box svg {
  z-index: 2;
}

.success-box .box .successfully {
  font-size: 24px;
  font-weight: bold;
  margin-top: 68px;
}

.success-box .description {
  margin-top: 3rem;
  padding-left: 10px;
}

.terms-conditions label:after,
#HCC-forgot-password-page .form-fields .text-muted:before {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px;
}

.terms-conditions input {
  top: 4px;
}

.HCC-Validate__Input input:focus {
  border-left: 3px solid #6611db;
}
.HCC-Login__Input::placeholder {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.PreLoading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  z-index: 99999;
}

@keyframes Polaris-Spinner--loading {
  100% {
    transform: rotate(1turn);
  }
}

.PreLoading .PreLoading-Loader {
  -webkit-filter: brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%)
    hue-rotate(130deg) brightness(92%) contrast(87%);
  filter: brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg)
    brightness(92%) contrast(87%);
  height: 2.5rem !important;
  width: 2.5rem !important;
  animation: Polaris-Spinner--loading 0.5s linear infinite;
  color: transparent;
}

.PreLoading p {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue,
    sans-serif;
  font-size: 1rem;
  color: #424242;
}
.togglePassword {
  color: rgba(138, 141, 145, 1);
}
.input-errors-wrapper {
  display: flex;
}
.HCC-Login__Input--error {
  border: 1px solid red !important;
}
.login-wrapper {
  height: 100%;
}
