.HCC-AutoCompleteAddNew__TextField,
.HCC-AutoCompleteSelect__TextField {
  .Polaris-TextField__Prefix {
    //margin-right: -0.8rem;
    max-width: 100%;
    min-width: 100%;
    //border-bottom: 1px solid #c9cccf;
    cursor: auto;
    margin: 0;
  }
  textarea {
    padding-left: 1rem !important;
  }
  .HCC-AutoCompleteAddNew__Prefix,
  .HCC-AutoCompleteSelect__Prefix {
    //margin-left: -1.2rem;
    padding: 0.6rem;
    max-width: 99%;
    > .Polaris-Stack {
      flex-wrap: wrap;
    }
    .Polaris-Tag span {
      white-space: normal;
    }
  }
}

.HCC-AutoCompleteAddNew__EmptyState {
  margin: -0.8rem 0;
}

.HCC-AutoCompleteSelect__EmptyState {
  text-align: center;
  padding: 1rem;
}

.HCC-AutoCompleteSelect__GridOption {
  .Polaris-Listbox-TextOption {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  margin-top: 5px;
  height: 250px;
  > div {
    > div {
      overflow-x: hidden !important;
    }
  }
  &.size-1 {
    height: 51px;
  }
  &.size-2 {
    height: 85px;
  }
  &.size-3 {
    height: 120px;
  }
  &.size-4 {
    height: 150px;
  }
  &.size-5 {
    height: 190px;
  }
  &.size-6 {
    height: 230px;
  }
}
