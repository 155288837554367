.HCC-Dashboard_CardTitle {
  display: flex;
  border-left: 2px solid rgba(232, 93, 4, 1);
  margin-right: 1rem;
  height: 21px;
  p {
    margin-left: 1rem;
    font-weight: 500;
    font-size: 18px;
    color: rgba(92, 95, 98, 1);
  }
}
.HCC-Dashboard_Backdrop {
  background-color: rgba(248, 248, 248, 1);
  border-radius: 10px;
}
.HCC-Dashboard_Backdrop__Element :hover {
  background-color: rgba(248, 248, 248, 1);
  border-radius: 10px;
}
.HCC-Dashboard_WalletReport_Wrapper {
  display: flex;
  justify-content: center;
}
.HCC-Dashboard_WalletReport {
  padding: 2rem;
  width: 90%;
}
.HCC-DashBoard__ProductReportCol {
  width: 100%;
  display: flex;
  justify-content: space-between;
  strong {
    width: 33%;
  }
}
.HCC-DashBoard__ProductReportCol__Element {
  width: 100%;
  display: flex;
  padding: 1rem 0 1rem 1.5rem;
  margin-bottom: 1rem;

  span {
    font-size: 15px;
    &:nth-child(1) {
      width: 35%;
    }
    &:nth-child(2) {
      width: 32%;
    }
    &:nth-child(3) {
      width: 32%;
    }
  }
}
