.HCC-CampaignList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          //min-width: 10rem;

          &:nth-child(2) {
            min-width: 6rem;
            max-width: 6rem;
          }
          &:nth-last-child(1),
          &:nth-last-child(2) {
            min-width: 7rem;
            max-width: 7rem;
          }
        }
        .Polaris-Stack__Item > p,
        a {
          max-width: 10rem;
        }
      }
    }
  }
}

.HCC-CampaignForm__ProductCell {
  cursor: pointer;
  > .Polaris-Card {
    position: relative;
    height: 99%;
    > .Polaris-Icon {
      //display: none;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    //&:hover {
    //  > .Polaris-Icon {
    //    display: flex;
    //  }
    //}
  }
  .Polaris-Thumbnail {
    width: 100%;
    max-height: 20rem;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
  }
  .HCC-CampaignForm__ProductBody {
    padding: 1rem;
    .Polaris-Stack {
      > .Polaris-Stack__Item {
        > .Polaris-TextStyle--variationStrong {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
// .Polaris-Stack {
//   flex-wrap: nowrap;
// }
.Polaris-TextStyle--variationStrong {
  overflow-wrap: break-word;
}
.HCC-CampaignForm__ProductSelection {
  height: 100%;
  padding: 2rem;
  background-color: #fafbfb;
  overflow-y: auto;
  .Polaris-Layout {
    height: 100%;
    .Polaris-Layout__Section {
      height: 100%;
    }
  }
  .HCC-CampaignForm__ProductGrid {
    height: calc(100vh - 29.5rem);
    margin-top: 2rem;
    padding-top: 1rem;
    background-color: #fff;
    border-radius: 0.8rem;
    box-shadow: var(--p-card-shadow);
    outline: 0.1rem solid transparent;
  }
}

.HCC-CampaignForm__ProductSelected {
  height: 100%;
  .Polaris-Card {
    height: 100%;
    .Polaris-Scrollable {
      height: calc(100vh - 21rem);
      padding: 1.6rem;
    }
  }
}

.HCC-CampaignForm__Layout {
  //height: 100%;
  //overflow-y: auto;
  padding: 2rem;
  background-color: #fafbfb;
  //.Polaris-Layout {
  //  height: 100%;
  //  .Polaris-Layout__Section {
  //    height: 100%;
  //  }
  //}
  .HCC-CampaignForm__VariantSelector {
    margin-top: 1.6rem;
    .Polaris-Card {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .HCC-CampaignForm__VariantList {
    height: calc(100vh - 30rem);
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    &:not(.fixed-size) {
      min-height: 30rem;
    }
    &.size-0 {
      height: auto;
      > * {
        padding: 4rem 0 6rem;
        text-align: center;
      }
    }
    > div {
      > div {
        overflow-x: hidden !important;
        -ms-overflow-x: hidden !important;
      }
    }
  }
}

.HCC-CampaignMapping__Layout {
  padding: 2rem;
  background-color: #fafbfb;
  height: 100%;
}

.HCC-CampaignForm__VariantCell {
  padding: 1rem;
  display: flex;
  align-items: center;
  &:not(.lastRow) {
    border-bottom: 0.1rem solid #e1e3e5;
  }
  &.checkbox {
    padding-left: 1.6rem;
  }
  &.massActions {
    .Polaris-ButtonGroup--fullWidth {
      .Polaris-ButtonGroup__Item {
        flex: 1 0 auto;
      }
    }
  }
}

.HCC-CampaignForm__FileList {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        align-items: center;

        .Polaris-FormLayout__Item {
          &:nth-child(1) {
            min-width: 8rem;
            max-width: 8rem;
          }
          &:nth-child(3) {
            .Polaris-TextField {
              max-width: 8rem;
            }
          }
          .HCC-ImageUploader__Container {
            .Polaris-DropZone {
              width: 8rem;
              height: 8rem;
            }
            .Polaris-Thumbnail {
              height: 8rem;
              min-width: 8rem;
              width: auto;
            }
          }
        }
      }
    }
  }
}

.HCC-CampaignDesign__Toggle {
  cursor: pointer;
  border-bottom: 1px solid #dfe3e8;
  margin: 0.5rem 0 1rem;
  padding-bottom: 0.5rem;
}

.HCC-CampaignDesign__View {
  position: relative;

  .Polaris-Badge {
    position: absolute;
    right: -0.5rem;
    top: -1rem;
    background-color: #d72c0d;
    color: #fff;
  }
}
