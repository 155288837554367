.HCC-DisplayMessage {
  width: max-content;
  margin-left: 2rem;
  text-align: center;
  span {
    padding: 1rem;
    font-weight: bolder;
    font-size: 16px;
    color: #6d7175;
  }
}
.HCC-DisplayMessage__isSuccess {
  background-color: #e5f7f2;
}
.HCC-DisplayMessage__isError {
  background-color: #fbeae5;
}
