.HCC-ResetPassword__TitleWrapper {
  p {
    margin-top: 1rem !important;
  }
  padding: 10px 15px 10px 10px;
  margin-bottom: 15px;
}
.footer-button {
  .Polaris-Button {
    margin-top: 1rem !important;
    color: black !important;
  }
  .Polaris-Icon__Svg:hover {
    fill: black !important;
  }
  p {
    padding-top: 3rem;
  }
}
.HCC-LoginForm__SpinWrapper {
  display: flex;
  justify-content: space-evenly;
}
.loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.success-box {
  .Polaris-Button__Text {
    color: black !important;
  }
}
