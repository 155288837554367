.HCC-Timeline_Header {
  margin: 3rem 0 0 2rem;
}

.HCC-Timeline_Pagination {
  display: flex;
  justify-content: center;
}

.HCC-Timeline_Body {
  position: relative;
  padding-left: 1rem;
  margin-left: 1.5rem;

  &:after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: #dfdfdf;
    top: 0;
    bottom: 0;
    margin-left: -3px;
    margin-top: 35px;
  }

  .HCC-Timeline_Item {
    &:last-child {
      margin-bottom: 0;
    }

    // background: url('./User.svg');
    &:before {
      content: '';
      height: 0;
      position: absolute;
      top: 22px;
      width: 0;
      z-index: 1;
      left: -9px;
      border: 1rem solid transparent;
      border-left: 0;
      border-right-color: white;
    }

    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: -40.5px;
      background-color: rgba(232, 93, 4, 1);
      border: 3px solid white;
      top: 12px;
      border-radius: 50%;
      z-index: 1;
    }

    position: relative;
    background-color: white;
    border-radius: var(--p-border-radius-wide, 3px);
    padding: 1rem;
    margin-left: 3rem;

    .HCC-Timeline_Content {
      display: inline-block;
      width: 100%;

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }

      .HCC-Timeline_Row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .HCC-Timeline_Body_Svg {
    position: absolute;
    left: -7px;
    z-index: 9999;
    top: 35px;
    background: white;
  }
  .HCC-Timeline_Comment {
    margin-left: 3rem;
    border: 1px solid rgba(232, 93, 4, 1);
    border-radius: 8px;
    min-height: 109px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .HCC-Timeline_Comment--textarea {
      border: none;
      outline: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      min-height: 45px;
      width: 100%;
      font-family: SF Pro Display, sans-serif;
      font-size: 14px;
      font-weight: 500;
      resize: vertical;
      margin-top: 4px;
      padding-left: 15px;
    }
    .HCC-Timeline_ActionWrapper {
      padding: 5px 15px 5px 15px;
      display: flex;
      justify-content: space-between;
      .Polaris-Button:not(.Polaris-Button--disabled) {
        background-color: rgba(232, 93, 4, 1);
        .Polaris-Button__Text {
          color: white !important;
        }
      }
    }
  }
  .HCC-Timeline_Comment__Title {
    font-weight: 500;
    margin: 0 0 0.5rem 3rem;
  }
}
