@import 'pages/home';
@import 'pages/user';
@import 'pages/userRole';
@import 'pages/notification';
@import 'pages/product';
@import 'pages/campaign';
@import 'pages/billing';
@import 'pages/store';
@import 'pages/otherFee';
@import 'pages/order';
@import 'pages/scrollbar';
@import 'pages/setting';
@import 'pages/category';
@import 'pages/importExportHistory';
.HCC-ApiLog__ViewForm {
  .Polaris-TextStyle--variationCode {
    white-space: pre-wrap;
    font-size: 1.3rem;
    cursor: text;
    padding: 1rem 1.6rem;
    margin-right: 2rem;
    min-width: 100%;

    &:after {
      display: none;
    }
  }
}


