.HCC-AdvancedResourceList__Items {
  .HCC-AdvancedResourceList__BodyRow {
    > .Polaris-FormLayout {
      > .Polaris-FormLayout--grouped {
        > .Polaris-FormLayout__Items {
          align-items: center;
          word-break: break-word;
        }
      }
    }
  }
  &.hasCheckbox {
    &.HCC-FetchResourceList__Items {
      .Polaris-ResourceList {
        .HCC-AdvancedResourceList__BodyRow {
          > .Polaris-FormLayout {
            > .Polaris-FormLayout--grouped {
              > .Polaris-FormLayout__Items {
                > .Polaris-FormLayout__Item {
                  &:nth-child(1) {
                    margin-top: 1.3rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .Polaris-ResourceList {
      .HCC-AdvancedResourceList__BodyRow {
        > .Polaris-FormLayout {
          > .Polaris-FormLayout--grouped {
            > .Polaris-FormLayout__Items {
              > .Polaris-FormLayout__Item {
                &:nth-child(1) {
                  min-width: 1.5rem;
                  max-width: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  &.hasExpand {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(1) {
            min-width: 2rem;
            max-width: 2rem;
            margin-left: 0.8rem;
            margin-right: 0.4rem;
          }
        }
      }
    }
    &.hasCheckbox {
      .Polaris-ResourceList {
        .Polaris-FormLayout__Items {
          .Polaris-FormLayout__Item {
            &:nth-child(2) {
              min-width: 1.5rem;
              max-width: 1.5rem;
              margin-top: 1.3rem;
            }
          }
        }
      }
    }
  }
  //&.isEmpty {
  //  margin-bottom: -6rem;
  //}
  .Polaris-ResourceList {
    .Polaris-ResourceItem__ListItem {
      .Polaris-ResourceItem {
        cursor: default;
      }
      &:nth-child(1) {
        .Polaris-ResourceItem {
          &:hover {
            background-color: unset;
          }
        }
      }
      &[data-href='HCC-AdvancedResourceList__ChildRow'] {
        background-color: #f6f6f7;
      }
      &:last-of-type {
        border-radius: 0;
        .Polaris-ResourceItem__ItemWrapper {
          border-radius: 0;
        }
      }
    }
    .Polaris-FormLayout--grouped {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          min-width: 10rem;
          flex-basis: 10rem;
        }
      }
    }
  }
}

.HCC-AdvancedResourceList__MassAction {
  display: flex;
  align-items: center;
  &.HCC-FetchResourceList__MassAction {
    margin-top: -3px;
    &.hasExpand {
      margin-left: 2.7rem;
    }
  }
}

.HCC-AdvancedResourceList__Pagination {
  nav {
    .Polaris-ButtonGroup {
      justify-content: center;
    }
  }
}

.HCC-AdvancedResourceList__ItemPerPage {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    margin-right: 1rem;
  }
}

.HCC-AdvancedResourceList__TotalCount {
  display: flex;
  align-items: center;
}

.HCC-AdvancedResourceList__FooterRow {
  display: flex;
  padding: 2rem;
  > * {
    flex: 1;
  }
}
.HCC-Popup__HeaderPageActions .Polaris-ResourceItem__Container {
  display: block;
}

.HCC-AdvancedResourceList__ChildCustomTable {
  margin: -1.2rem 1.5rem;
  .Polaris-IndexTable__Table:hover {
    background-color: #fff;
  }
  .Polaris-IndexTable__TableRow {
    cursor: auto;
  }
  .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected
    .Polaris-IndexTable__TableCell--first {
    background-color: rgba(242, 242, 242, 1);
  }
  .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected
    .Polaris-IndexTable__TableCell {
    background-color: rgba(242, 242, 242, 1) !important;
  }

  .Polaris-IndexTable-Checkbox--expanded {
    padding-left: 0;
  }
  .Polaris-IndexTable__ScrollBarContainer {
    display: none;
    padding: 0;
  }
  .Polaris-IndexTable__TableCell {
    padding: 1rem 0;
    padding-left: 1rem;
    white-space: normal;
    background-color: rgba(242, 242, 242, 1);

    &:nth-child(2) {
      // min-width: 16rem;
      max-width: 18rem;
      overflow-wrap: break-word;
    }
    &:nth-child(3) {
      // min-width: 14.8rem;
      max-width: 14.8rem;
      overflow-wrap: break-word;
    }
    &:nth-child(4) {
      // min-width: 18.5rem;
      max-width: 18.5rem;
    }
    &:nth-child(5) {
      // min-width: 5.5rem;
      max-width: 5.5rem;
    }
    &:nth-child(6) {
      // min-width: 17.2rem;
      max-width: 17.2rem;
    }
    &:nth-child(7) {
      // min-width: 11.3rem;
      max-width: 11.3rem;
    }
    &:nth-child(8) {
      // min-width: 13.7rem;
      max-width: 13.7rem;
    }
  }
  .Polaris-IndexTable__TableHeading {
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-width: 4rem;
    &:not(:nth-child(1)) {
      padding-left: 10px;
      padding-right: 0;
      background-color: rgba(255, 217, 192, 1);
    }
  }
}
