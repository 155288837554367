//Import export history
.HCC--ImportExportHistory__Body{
  .Polaris-FormLayout__Items {
    .Polaris-FormLayout__Item {
      &:nth-child(1) {
        min-width: 10rem;
        max-width: 25%;
      }

      //&:nth-child(2) {
      //  min-width: 10rem;
      //  max-width: 10%;
      //}
      //
      //&:nth-child(3) {
      //  min-width: 5rem;
      //  max-width: 5rem;
      //}
      //
      //&:nth-child(5) {
      //  min-width: 5rem;
      //  max-width: 18rem;
      //}
      //
      //&:nth-child(6) {
      //  min-width: 5rem;
      //  max-width: 16rem;
      //}

      &:nth-last-child(1) {
        min-width: 10rem;
        max-width: 10%;
      }
    }
  }
  .HCC-ImportExportHistory__RoleColumn {
    padding: 0.5rem;
    width: fit-content;
    border-radius: 5px;
    border: 1px solid;
    display: flex;
  }
  .HCC-ImportExportHistory__Type{
    padding: 0.5rem;
    width: fit-content;
    border-radius: 5px;
    //border: 1px solid;
  }
}