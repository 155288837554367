.HCC-NotificationList__Item {
  .Polaris-FormLayout {
    .Polaris-FormLayout__Items {
      .Polaris-FormLayout__Item {
        &:nth-child(1) {
          max-width: 22rem;
        }
        &:nth-child(2) {
          min-width: 3rem;
          max-width: 3rem;
          max-height: 22px;
        }
      }
    }
  }
}

//.Polaris-TextField--multiline {
//  > .Polaris-TextField__Input {
//    overflow: hidden;
//  }
//}

.HCC-NotificationEdit__Editor {
  font-family: Fira code, Fira Mono, monospace;
  counter-reset: line;
  border: 1px solid #c9cccf;
  border-radius: 0.5rem;
  #HCC-NotificationEdit__CodeArea {
    outline: none;
    padding-left: 5rem !important;
    &::selection {
      background-color: #accef7;
    }
  }
  pre {
    padding-left: 5rem !important;
  }
  .editorLineNumber {
    left: 0;
    position: absolute;
    text-align: right;
    font-weight: 100;
    color: #cccccc;
    width: 3rem;
  }
}
