@charset "UTF-8"; /*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
#HCC-login-page {
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: left;
    background-color: #fff;
  }
  [tabindex='-1']:focus:not(:focus-visible) {
    outline: 0 !important;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  abbr[data-original-title],
  abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dt {
    font-weight: 600;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: rgba(232, 93, 4, 1);
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    color: #0052ea;
    text-decoration: none;
  }
  a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
      monospace;
    font-size: 1em;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  figure {
    margin: 0 0 1rem;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #8c98a4;
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button {
    border-radius: 0;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [role='button'] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    -webkit-appearance: button;
  }
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw);
    }
  }
  progress {
    vertical-align: baseline;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.4;
    color: #1e2022;
  }
  .h1,
  h1 {
    font-size: 2.5rem;
  }
  @media (max-width: 1200px) {
    .h1,
    h1 {
      font-size: calc(1.375rem + 1.5vw);
    }
  }
  .h2,
  h2 {
    font-size: 1.875rem;
  }
  @media (max-width: 1200px) {
    .h2,
    h2 {
      font-size: calc(1.3125rem + 0.75vw);
    }
  }
  .h3,
  h3 {
    font-size: 1.3125rem;
  }
  @media (max-width: 1200px) {
    .h3,
    h3 {
      font-size: calc(1.25625rem + 0.075vw);
    }
  }
  .h4,
  h4 {
    font-size: 1.125rem;
  }
  .h5,
  h5 {
    font-size: 1rem;
  }
  .h6,
  h6 {
    font-size: 0.875rem;
  }
  .lead {
    font-size: 1.25rem;
    font-weight: 400;
  }
  .display-1 {
    font-size: 6rem;
    font-weight: 600;
    line-height: 1.2;
  }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw);
    }
  }
  .display-2 {
    font-size: 5.5rem;
    font-weight: 600;
    line-height: 1.2;
  }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw);
    }
  }
  .display-3 {
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 1.2;
  }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw);
    }
  }
  .display-4 {
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 1.2;
  }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw);
    }
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0.0625rem solid #e7eaf3;
  }
  .small,
  small {
    font-size: 80%;
    font-weight: 400;
  }
  .mark,
  mark {
    padding: 0.2em;
    background-color: rgba(55, 125, 255, 0.1);
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #8c98a4;
  }
  .blockquote-footer::before {
    content: '\2014\00A0';
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 0.0625rem solid #e7eaf3;
    border-radius: 0.3125rem;
    max-width: 100%;
    height: auto;
  }
  .figure {
    display: inline-block;
  }
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  .figure-caption {
    font-size: 90%;
    color: #8c98a4;
  }
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #1e2022;
    background-color: #e7eaf3;
    border-radius: 0.25rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600;
  }
  pre {
    display: block;
    font-size: 87.5%;
    color: #1e2022;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-first {
    order: -1;
  }
  .order-last {
    order: 13;
  }
  .order-0 {
    order: 0;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .order-5 {
    order: 5;
  }
  .order-6 {
    order: 6;
  }
  .order-7 {
    order: 7;
  }
  .order-8 {
    order: 8;
  }
  .order-9 {
    order: 9;
  }
  .order-10 {
    order: 10;
  }
  .order-11 {
    order: 11;
  }
  .order-12 {
    order: 12;
  }
  .offset-1 {
    margin-left: 8.33333%;
  }
  .offset-2 {
    margin-left: 16.66667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333%;
  }
  .offset-5 {
    margin-left: 41.66667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.33333%;
  }
  .offset-8 {
    margin-left: 66.66667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.33333%;
  }
  .offset-11 {
    margin-left: 91.66667%;
  }
  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      order: -1;
    }
    .order-sm-last {
      order: 13;
    }
    .order-sm-0 {
      order: 0;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-12 {
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333%;
    }
    .offset-sm-2 {
      margin-left: 16.66667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333%;
    }
    .offset-sm-5 {
      margin-left: 41.66667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333%;
    }
    .offset-sm-8 {
      margin-left: 66.66667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333%;
    }
    .offset-sm-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      order: -1;
    }
    .order-md-last {
      order: 13;
    }
    .order-md-0 {
      order: 0;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-12 {
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333%;
    }
    .offset-md-2 {
      margin-left: 16.66667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333%;
    }
    .offset-md-5 {
      margin-left: 41.66667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333%;
    }
    .offset-md-8 {
      margin-left: 66.66667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333%;
    }
    .offset-md-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      order: -1;
    }
    .order-lg-last {
      order: 13;
    }
    .order-lg-0 {
      order: 0;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-12 {
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333%;
    }
    .offset-lg-2 {
      margin-left: 16.66667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333%;
    }
    .offset-lg-5 {
      margin-left: 41.66667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333%;
    }
    .offset-lg-8 {
      margin-left: 66.66667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333%;
    }
    .offset-lg-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      order: -1;
    }
    .order-xl-last {
      order: 13;
    }
    .order-xl-0 {
      order: 0;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-12 {
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333%;
    }
    .offset-xl-2 {
      margin-left: 16.66667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333%;
    }
    .offset-xl-5 {
      margin-left: 41.66667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333%;
    }
    .offset-xl-8 {
      margin-left: 66.66667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333%;
    }
    .offset-xl-11 {
      margin-left: 91.66667%;
    }
  }
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #677788;
  }
  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 0.0625rem solid #e7eaf3;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 0.125rem solid #e7eaf3;
  }
  .table tbody + tbody {
    border-top: 0.125rem solid #e7eaf3;
  }
  .table-sm td,
  .table-sm th {
    padding: 0.3rem;
  }
  .table-bordered {
    border: 0.0625rem solid #e7eaf3;
  }
  .table-bordered td,
  .table-bordered th {
    border: 0.0625rem solid #e7eaf3;
  }
  .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 0.125rem;
  }
  .table-borderless tbody + tbody,
  .table-borderless td,
  .table-borderless th,
  .table-borderless thead th {
    border: 0;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8fafd;
  }
  .table-hover tbody tr:hover {
    color: #677788;
    background-color: rgba(231, 234, 243, 0.4);
  }
  .table-primary,
  .table-primary > td,
  .table-primary > th {
    background-color: #c7dbff;
  }
  .table-primary tbody + tbody,
  .table-primary td,
  .table-primary th,
  .table-primary thead th {
    border-color: #97bbff;
  }
  .table-hover .table-primary:hover {
    background-color: #aecbff;
  }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #aecbff;
  }
  .table-secondary,
  .table-secondary > td,
  .table-secondary > th {
    background-color: #d7dde4;
  }
  .table-secondary tbody + tbody,
  .table-secondary td,
  .table-secondary th,
  .table-secondary thead th {
    border-color: #b5c0cc;
  }
  .table-hover .table-secondary:hover {
    background-color: #c8d0da;
  }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8d0da;
  }
  .table-success,
  .table-success > td,
  .table-success > th {
    background-color: #b8f0e6;
  }
  .table-success tbody + tbody,
  .table-success td,
  .table-success th,
  .table-success thead th {
    border-color: #7ae3d1;
  }
  .table-hover .table-success:hover {
    background-color: #a3ecdf;
  }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a3ecdf;
  }
  .table-info,
  .table-info > td,
  .table-info > th {
    background-color: #bae6ed;
  }
  .table-info tbody + tbody,
  .table-info td,
  .table-info th,
  .table-info thead th {
    border-color: #7fd0dd;
  }
  .table-hover .table-info:hover {
    background-color: #a6dfe8;
  }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a6dfe8;
  }
  .table-warning,
  .table-warning > td,
  .table-warning > th {
    background-color: #fcf0e2;
  }
  .table-warning tbody + tbody,
  .table-warning td,
  .table-warning th,
  .table-warning thead th {
    border-color: #fae3ca;
  }
  .table-hover .table-warning:hover {
    background-color: #fae4cb;
  }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fae4cb;
  }
  .table-danger,
  .table-danger > td,
  .table-danger > th {
    background-color: #facdd9;
  }
  .table-danger tbody + tbody,
  .table-danger td,
  .table-danger th,
  .table-danger thead th {
    border-color: #f6a2b9;
  }
  .table-hover .table-danger:hover {
    background-color: #f8b6c7;
  }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f8b6c7;
  }
  .table-light,
  .table-light > td,
  .table-light > th {
    background-color: #fdfeff;
  }
  .table-light tbody + tbody,
  .table-light td,
  .table-light th,
  .table-light thead th {
    border-color: #fbfcff;
  }
  .table-hover .table-light:hover {
    background-color: #e4f1ff;
  }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e4f1ff;
  }
  .table-dark,
  .table-dark > td,
  .table-dark > th {
    background-color: #c1c6d1;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #8c94aa;
  }
  .table-hover .table-dark:hover {
    background-color: #b2b9c6;
  }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b2b9c6;
  }
  .table-indigo,
  .table-indigo > td,
  .table-indigo > th {
    background-color: #c4bddc;
  }
  .table-indigo tbody + tbody,
  .table-indigo td,
  .table-indigo th,
  .table-indigo thead th {
    border-color: #9285be;
  }
  .table-hover .table-indigo:hover {
    background-color: #b5acd3;
  }
  .table-hover .table-indigo:hover > td,
  .table-hover .table-indigo:hover > th {
    background-color: #b5acd3;
  }
  .table-active,
  .table-active > td,
  .table-active > th {
    background-color: rgba(231, 234, 243, 0.4);
  }
  .table-hover .table-active:hover {
    background-color: rgba(214, 219, 235, 0.4);
  }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(214, 219, 235, 0.4);
  }
  .table .thead-dark th {
    color: #fff;
    background-color: #21325b;
    border-color: #2b4177;
  }
  .table .thead-light th {
    color: #677788;
    background-color: #f8fafd;
    border-color: #e7eaf3;
  }
  .table-dark {
    color: #fff;
    background-color: #21325b;
  }
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #2b4177;
  }
  .table-dark.table-bordered {
    border: 0;
  }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
  }
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 1.625rem);
    padding: 1rem 1.5rem;
    line-height: 1.6;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    padding-right: calc(1.6em + 1.5rem);
    /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #1e2022;
  }
  .form-control:focus {
    color: #1e2022;
    background-color: #fff;
    border-color: rgba(55, 125, 255, 0.4);
    outline: 0;
    box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .form-control::placeholder {
    color: #97a4af;
    opacity: 1;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #f8fafd;
    opacity: 1;
  }
  input[type='date'].form-control,
  input[type='datetime-local'].form-control,
  input[type='month'].form-control,
  input[type='time'].form-control {
    appearance: none;
  }
  select.form-control:focus::-ms-value {
    color: #1e2022;
    background-color: #fff;
  }
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }
  .col-form-label {
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6;
  }
  .col-form-label-lg {
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .col-form-label-sm {
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.75rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.6;
    color: #677788;
    background-color: transparent;
    border: solid transparent;
    border-width: 0.0625rem 0;
  }
  .form-control-plaintext.form-control-lg,
  .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
  }
  .form-control-sm {
    height: calc(1.5em + 1.375rem);
    padding: 0.625rem 1.125rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  .form-control-lg {
    height: calc(1.5em + 2.375rem);
    padding: 1.125rem 1.125rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.75rem;
  }
  select.form-control[multiple],
  select.form-control[size] {
    height: auto;
  }
  textarea.form-control {
    height: auto;
  }
  .form-group {
    margin-bottom: 1.5rem;
  }
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    color: #8c98a4;
  }
  .form-check-label {
    margin-bottom: 0;
  }
  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #00c9a7;
  }
  .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.6;
    color: #fff;
    background-color: #00c9a7;
    border-radius: 0.3125rem;
  }
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip,
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip {
    display: block;
  }
  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #00c9a7;
    padding-right: calc(1.6em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.375rem) center;
    background-size: calc(0.8em + 0.75rem) calc(0.8em + 0.75rem);
  }
  .form-control.is-valid:focus,
  .was-validated .form-control:valid:focus {
    border-color: #00c9a7;
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
  }
  .was-validated textarea.form-control:valid,
  textarea.form-control.is-valid {
    padding-right: calc(1.6em + 1.5rem);
    background-position: top calc(0.4em + 0.375rem) right calc(0.4em + 0.375rem);
  }
  .custom-select.is-valid,
  .was-validated .custom-select:valid {
    border-color: #00c9a7;
    padding-right: calc(0.75em + 3.125rem);
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
        no-repeat right 0.5rem center/1rem 1rem,
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
        #fff no-repeat center right 2rem / calc(0.8em + 0.75rem) calc(0.8em + 0.75rem);
  }
  .custom-select.is-valid:focus,
  .was-validated .custom-select:valid:focus {
    border-color: #00c9a7;
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
  }
  .form-check-input.is-valid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #00c9a7;
  }
  .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip,
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
  }
  .custom-control-input.is-valid ~ .custom-control-label,
  .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #00c9a7;
  }
  .custom-control-input.is-valid ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid ~ .custom-control-label::before {
    border-color: #00c9a7;
  }
  .custom-control-input.is-valid:checked ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    border-color: #00fcd1;
    background-color: #00fcd1;
  }
  .custom-control-input.is-valid:focus ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
  }
  .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #00c9a7;
  }
  .custom-file-input.is-valid ~ .custom-file-label,
  .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #00c9a7;
  }
  .custom-file-input.is-valid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #00c9a7;
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ed4c78;
  }
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.6;
    color: #fff;
    background-color: #ed4c78;
    border-radius: 0.3125rem;
  }
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip,
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip {
    display: block;
  }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #ed4c78;
    padding-right: calc(1.6em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ed4c78' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed4c78' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.375rem) center;
    background-size: calc(0.8em + 0.75rem) calc(0.8em + 0.75rem);
  }
  .form-control.is-invalid:focus,
  .was-validated .form-control:invalid:focus {
    border-color: #ed4c78;
    box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
  }
  .was-validated textarea.form-control:invalid,
  textarea.form-control.is-invalid {
    padding-right: calc(1.6em + 1.5rem);
    background-position: top calc(0.4em + 0.375rem) right calc(0.4em + 0.375rem);
  }
  .custom-select.is-invalid,
  .was-validated .custom-select:invalid {
    border-color: #ed4c78;
    padding-right: calc(0.75em + 3.125rem);
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
        no-repeat right 0.5rem center/1rem 1rem,
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ed4c78' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed4c78' stroke='none'/%3e%3c/svg%3e")
        #fff no-repeat center right 2rem / calc(0.8em + 0.75rem) calc(0.8em + 0.75rem);
  }
  .custom-select.is-invalid:focus,
  .was-validated .custom-select:invalid:focus {
    border-color: #ed4c78;
    box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
  }
  .form-check-input.is-invalid ~ .form-check-label,
  .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #ed4c78;
  }
  .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip,
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
  }
  .custom-control-input.is-invalid ~ .custom-control-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #ed4c78;
  }
  .custom-control-input.is-invalid ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: #ed4c78;
  }
  .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
    border-color: #f27a9b;
    background-color: #f27a9b;
  }
  .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
  }
  .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ed4c78;
  }
  .custom-file-input.is-invalid ~ .custom-file-label,
  .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #ed4c78;
  }
  .custom-file-input.is-invalid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #ed4c78;
    box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
  }
  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .form-inline .form-check {
    width: 100%;
  }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .custom-select,
    .form-inline .input-group {
      width: auto;
    }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  .btn {
    display: inline-block;
    font-weight: 600;
    color: #677788;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0.3125rem;
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .btn:hover {
    color: #677788;
    text-decoration: none;
  }
  .btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.65;
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  .btn-primary {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #1164ff;
    border-color: #045cff;
  }
  .btn-primary.focus,
  .btn-primary:focus {
    color: #fff;
    background-color: #1164ff;
    border-color: #045cff;
    box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5);
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #045cff;
    border-color: #0056f6;
  }
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5);
  }
  .btn-secondary {
    color: #fff;
    background-color: #71869d;
    border-color: #71869d;
  }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5f7389;
    border-color: #596d82;
  }
  .btn-secondary.focus,
  .btn-secondary:focus {
    color: #fff;
    background-color: #5f7389;
    border-color: #596d82;
    box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #fff;
    background-color: #71869d;
    border-color: #71869d;
  }
  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #596d82;
    border-color: #54667a;
  }
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
  }
  .btn-success {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #00a387;
    border-color: #00967d;
  }
  .btn-success.focus,
  .btn-success:focus {
    color: #fff;
    background-color: #00a387;
    border-color: #00967d;
    box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
  }
  .btn-success.disabled,
  .btn-success:disabled {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00967d;
    border-color: #008972;
  }
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
  }
  .btn-info {
    color: #fff;
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .btn-info:hover {
    color: #fff;
    background-color: #078599;
    border-color: #077b8d;
  }
  .btn-info.focus,
  .btn-info:focus {
    color: #fff;
    background-color: #078599;
    border-color: #077b8d;
    box-shadow: 0 0 0 0.2rem rgba(46, 179, 200, 0.5);
  }
  .btn-info.disabled,
  .btn-info:disabled {
    color: #fff;
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .btn-info:not(:disabled):not(.disabled).active,
  .btn-info:not(:disabled):not(.disabled):active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #077b8d;
    border-color: #067081;
  }
  .btn-info:not(:disabled):not(.disabled).active:focus,
  .btn-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(46, 179, 200, 0.5);
  }
  .btn-warning {
    color: #1e2022;
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-warning:hover {
    color: #1e2022;
    background-color: #f2b876;
    border-color: #f0b26b;
  }
  .btn-warning.focus,
  .btn-warning:focus {
    color: #1e2022;
    background-color: #f2b876;
    border-color: #f0b26b;
    box-shadow: 0 0 0 0.2rem rgba(213, 177, 135, 0.5);
  }
  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #1e2022;
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-warning:not(:disabled):not(.disabled).active,
  .btn-warning:not(:disabled):not(.disabled):active,
  .show > .btn-warning.dropdown-toggle {
    color: #1e2022;
    background-color: #f0b26b;
    border-color: #efac5f;
  }
  .btn-warning:not(:disabled):not(.disabled).active:focus,
  .btn-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 177, 135, 0.5);
  }
  .btn-danger {
    color: #fff;
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #ea295e;
    border-color: #e81e55;
  }
  .btn-danger.focus,
  .btn-danger:focus {
    color: #fff;
    background-color: #ea295e;
    border-color: #e81e55;
    box-shadow: 0 0 0 0.2rem rgba(240, 103, 140, 0.5);
  }
  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #fff;
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-danger:not(:disabled):not(.disabled).active,
  .btn-danger:not(:disabled):not(.disabled):active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e81e55;
    border-color: #e2174e;
  }
  .btn-danger:not(:disabled):not(.disabled).active:focus,
  .btn-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 103, 140, 0.5);
  }
  .btn-light {
    color: #1e2022;
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .btn-light:hover {
    color: #1e2022;
    background-color: #d1e2ff;
    border-color: #c4daff;
  }
  .btn-light.focus,
  .btn-light:focus {
    color: #1e2022;
    background-color: #d1e2ff;
    border-color: #c4daff;
    box-shadow: 0 0 0 0.2rem rgba(214, 217, 222, 0.5);
  }
  .btn-light.disabled,
  .btn-light:disabled {
    color: #1e2022;
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .btn-light:not(:disabled):not(.disabled).active,
  .btn-light:not(:disabled):not(.disabled):active,
  .show > .btn-light.dropdown-toggle {
    color: #1e2022;
    background-color: #c4daff;
    border-color: #b7d2ff;
  }
  .btn-light:not(:disabled):not(.disabled).active:focus,
  .btn-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(214, 217, 222, 0.5);
  }
  .btn-dark {
    color: #fff;
    background-color: #21325b;
    border-color: #21325b;
  }
  .btn-dark:hover {
    color: #fff;
    background-color: #17233f;
    border-color: #131d36;
  }
  .btn-dark.focus,
  .btn-dark:focus {
    color: #fff;
    background-color: #17233f;
    border-color: #131d36;
    box-shadow: 0 0 0 0.2rem rgba(66, 81, 116, 0.5);
  }
  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #fff;
    background-color: #21325b;
    border-color: #21325b;
  }
  .btn-dark:not(:disabled):not(.disabled).active,
  .btn-dark:not(:disabled):not(.disabled):active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #131d36;
    border-color: #10182c;
  }
  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 81, 116, 0.5);
  }
  .btn-indigo {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .btn-indigo:hover {
    color: #fff;
    background-color: #221061;
    border-color: #1e0e56;
  }
  .btn-indigo.focus,
  .btn-indigo:focus {
    color: #fff;
    background-color: #221061;
    border-color: #1e0e56;
    box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
  }
  .btn-indigo.disabled,
  .btn-indigo:disabled {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .btn-indigo:not(:disabled):not(.disabled).active,
  .btn-indigo:not(:disabled):not(.disabled):active,
  .show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #1e0e56;
    border-color: #1a0c4b;
  }
  .btn-indigo:not(:disabled):not(.disabled).active:focus,
  .btn-indigo:not(:disabled):not(.disabled):active:focus,
  .show > .btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
  }
  .btn-outline-primary {
    color: #377dff;
    border-color: #377dff;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .btn-outline-primary.focus,
  .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
  }
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #377dff;
    background-color: transparent;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
  }
  .btn-outline-secondary {
    color: #71869d;
    border-color: #71869d;
  }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #71869d;
    border-color: #71869d;
  }
  .btn-outline-secondary.focus,
  .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
  }
  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: #71869d;
    background-color: transparent;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #71869d;
    border-color: #71869d;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
  }
  .btn-outline-success {
    color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
  }
  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #00c9a7;
    background-color: transparent;
  }
  .btn-outline-success:not(:disabled):not(.disabled).active,
  .btn-outline-success:not(:disabled):not(.disabled):active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .btn-outline-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
  }
  .btn-outline-info {
    color: #09a5be;
    border-color: #09a5be;
  }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .btn-outline-info.focus,
  .btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(9, 165, 190, 0.5);
  }
  .btn-outline-info.disabled,
  .btn-outline-info:disabled {
    color: #09a5be;
    background-color: transparent;
  }
  .btn-outline-info:not(:disabled):not(.disabled).active,
  .btn-outline-info:not(:disabled):not(.disabled):active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .btn-outline-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(9, 165, 190, 0.5);
  }
  .btn-outline-warning {
    color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-outline-warning:hover {
    color: #1e2022;
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-outline-warning.focus,
  .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
  }
  .btn-outline-warning.disabled,
  .btn-outline-warning:disabled {
    color: #f5ca99;
    background-color: transparent;
  }
  .btn-outline-warning:not(:disabled):not(.disabled).active,
  .btn-outline-warning:not(:disabled):not(.disabled):active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #1e2022;
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
  }
  .btn-outline-danger {
    color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-outline-danger.focus,
  .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
  }
  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #ed4c78;
    background-color: transparent;
  }
  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
  }
  .btn-outline-light {
    color: #f7faff;
    border-color: #f7faff;
  }
  .btn-outline-light:hover {
    color: #1e2022;
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .btn-outline-light.focus,
  .btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
  }
  .btn-outline-light.disabled,
  .btn-outline-light:disabled {
    color: #f7faff;
    background-color: transparent;
  }
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #1e2022;
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .btn-outline-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
  }
  .btn-outline-dark {
    color: #21325b;
    border-color: #21325b;
  }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #21325b;
    border-color: #21325b;
  }
  .btn-outline-dark.focus,
  .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 50, 91, 0.5);
  }
  .btn-outline-dark.disabled,
  .btn-outline-dark:disabled {
    color: #21325b;
    background-color: transparent;
  }
  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #21325b;
    border-color: #21325b;
  }
  .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 50, 91, 0.5);
  }
  .btn-outline-indigo {
    color: #2d1582;
    border-color: #2d1582;
  }
  .btn-outline-indigo:hover {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .btn-outline-indigo.focus,
  .btn-outline-indigo:focus {
    box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
  }
  .btn-outline-indigo.disabled,
  .btn-outline-indigo:disabled {
    color: #2d1582;
    background-color: transparent;
  }
  .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .btn-outline-indigo:not(:disabled):not(.disabled):active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
  .btn-outline-indigo:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
  }
  .btn-link {
    font-weight: 400;
    color: #377dff;
    text-decoration: none;
  }
  .btn-link:hover {
    color: #0052ea;
    text-decoration: none;
  }
  .btn-link.focus,
  .btn-link:focus {
    text-decoration: none;
  }
  .btn-link.disabled,
  .btn-link:disabled {
    color: #8c98a4;
    pointer-events: none;
  }
  .btn-group-lg > .btn,
  .btn-lg {
    padding: 1.125rem 1.125rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.75rem;
  }
  .btn-group-sm > .btn,
  .btn-sm {
    padding: 0.625rem 1.125rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  input[type='button'].btn-block,
  input[type='reset'].btn-block,
  input[type='submit'].btn-block {
    width: 100%;
  }
  .fade {
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: relative;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 8.4375rem;
    padding: 1rem 0;
    margin: 0.5rem 0 0;
    font-size: 1rem;
    color: #677788;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.75rem;
  }
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.5rem;
  }
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.5rem;
  }
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  .dropdown-menu[x-placement^='bottom'],
  .dropdown-menu[x-placement^='left'],
  .dropdown-menu[x-placement^='right'],
  .dropdown-menu[x-placement^='top'] {
    right: auto;
    bottom: auto;
  }
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e7eaf3;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.375rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #1e2022;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #121314;
    text-decoration: none;
    background-color: rgba(189, 197, 209, 0.3);
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #1e2022;
    text-decoration: none;
    background-color: rgba(189, 197, 209, 0.3);
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #8c98a4;
    pointer-events: none;
    background-color: transparent;
  }
  .dropdown-menu.show {
    display: block;
  }
  .dropdown-header {
    display: block;
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #8c98a4;
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: 0.375rem 1.5rem;
    color: #1e2022;
  }
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }
  .btn-group-vertical > .btn,
  .btn-group > .btn {
    position: relative;
    flex: 1 1 auto;
  }
  .btn-group-vertical > .btn:hover,
  .btn-group > .btn:hover {
    z-index: 1;
  }
  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn:focus,
  .btn-group > .btn.active,
  .btn-group > .btn:active,
  .btn-group > .btn:focus {
    z-index: 1;
  }
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .btn-toolbar .input-group {
    width: auto;
  }
  .btn-group > .btn-group:not(:first-child),
  .btn-group > .btn:not(:first-child) {
    margin-left: -0.0625rem;
  }
  .btn-group > .btn-group:not(:last-child) > .btn,
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-group > .btn-group:not(:first-child) > .btn,
  .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }
  .btn-group-sm > .btn + .dropdown-toggle-split,
  .btn-sm + .dropdown-toggle-split {
    padding-right: 0.84375rem;
    padding-left: 0.84375rem;
  }
  .btn-group-lg > .btn + .dropdown-toggle-split,
  .btn-lg + .dropdown-toggle-split {
    padding-right: 0.84375rem;
    padding-left: 0.84375rem;
  }
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }
  .btn-group-vertical > .btn-group:not(:first-child),
  .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -0.0625rem;
  }
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }
  .btn-group-toggle > .btn input[type='checkbox'],
  .btn-group-toggle > .btn input[type='radio'],
  .btn-group-toggle > .btn-group > .btn input[type='checkbox'],
  .btn-group-toggle > .btn-group > .btn input[type='radio'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .custom-file,
  .input-group > .custom-select,
  .input-group > .form-control,
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
  .input-group > .custom-file + .custom-file,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .form-control,
  .input-group > .form-control + .custom-file,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .form-control,
  .input-group > .form-control-plaintext + .custom-file,
  .input-group > .form-control-plaintext + .custom-select,
  .input-group > .form-control-plaintext + .form-control {
    margin-left: -0.0625rem;
  }
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
  .input-group > .custom-select:focus,
  .input-group > .form-control:focus {
    z-index: 3;
  }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
  .input-group > .custom-select:not(:last-child),
  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-select:not(:first-child),
  .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }
  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-append,
  .input-group-prepend {
    display: flex;
  }
  .input-group-append .btn,
  .input-group-prepend .btn {
    position: relative;
    z-index: 2;
  }
  .input-group-append .btn:focus,
  .input-group-prepend .btn:focus {
    z-index: 3;
  }
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -0.0625rem;
  }
  .input-group-prepend {
    margin-right: -0.0625rem;
  }
  .input-group-append {
    margin-left: -0.0625rem;
  }
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #97a4af;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 0.0625rem solid #e7eaf3;
    border-radius: 0.3125rem;
  }
  .input-group-text input[type='checkbox'],
  .input-group-text input[type='radio'] {
    margin-top: 0;
  }
  .input-group-lg > .custom-select,
  .input-group-lg > .form-control:not(textarea) {
    height: calc(1.5em + 2.375rem);
  }
  .input-group-lg > .custom-select,
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-append > .btn,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-prepend > .input-group-text {
    padding: 1.125rem 1.125rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.75rem;
  }
  .input-group-sm > .custom-select,
  .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 1.375rem);
  }
  .input-group-sm > .custom-select,
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-append > .btn,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-prepend > .input-group-text {
    padding: 0.625rem 1.125rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  .input-group-lg > .custom-select,
  .input-group-sm > .custom-select {
    padding-right: 2rem;
  }
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.6rem;
    padding-left: 1.5rem;
    print-color-adjust: exact;
  }
  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
  }
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.3rem;
    opacity: 0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #377dff;
    background-color: #377dff;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: rgba(55, 125, 255, 0.4);
  }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #eaf1ff;
    border-color: #eaf1ff;
  }
  .custom-control-input:disabled ~ .custom-control-label,
  .custom-control-input[disabled] ~ .custom-control-label {
    color: #8c98a4;
  }
  .custom-control-input:disabled ~ .custom-control-label::before,
  .custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #f8fafd;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  .custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #d6dbeb solid 0.0625rem;
  }
  .custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background: no-repeat 50%/50% 50%;
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #377dff;
    background-color: #377dff;
  }
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
  }
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(55, 125, 255, 0.5);
  }
  .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(55, 125, 255, 0.5);
  }
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(55, 125, 255, 0.5);
  }
  .custom-switch {
    padding-left: 2.25rem;
  }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
  }
  .custom-switch .custom-control-label::after {
    top: 0.425rem;
    left: -2.125rem;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #d6dbeb;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
      transition: none;
    }
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
  }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(55, 125, 255, 0.5);
  }
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.625rem);
    padding: 0.75rem 2rem 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1e2022;
    vertical-align: middle;
    background: #fff
      url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right 0.5rem center/1rem 1rem;
    border: 0.0625rem solid #e7eaf3;
    border-radius: 0.3125rem;
    appearance: none;
  }
  .custom-select:focus {
    border-color: rgba(55, 125, 255, 0.4);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
  }
  .custom-select:focus::-ms-value {
    color: #1e2022;
    background-color: #fff;
  }
  .custom-select[multiple],
  .custom-select[size]:not([size='1']) {
    height: auto;
    padding-right: 1rem;
    background-image: none;
  }
  .custom-select:disabled {
    color: #8c98a4;
    background-color: #f8fafd;
  }
  .custom-select::-ms-expand {
    display: none;
  }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #1e2022;
  }
  .custom-select-sm {
    height: calc(1.5em + 1.375rem);
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 1.125rem;
    font-size: 0.875rem;
  }
  .custom-select-lg {
    height: calc(1.5em + 2.375rem);
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-left: 1.125rem;
    font-size: 1.25rem;
  }
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.625rem);
    margin-bottom: 0;
  }
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.6em + 1.625rem);
    margin: 0;
    opacity: 0;
  }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: rgba(55, 125, 255, 0.4);
    box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .custom-file-input:disabled ~ .custom-file-label,
  .custom-file-input[disabled] ~ .custom-file-label {
    background-color: #f8fafd;
  }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Browse';
  }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.6em + 1.625rem);
    padding: 0.75rem 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #8c98a4;
    background-color: #fff;
    border: 0.0625rem solid #e7eaf3;
    border-radius: 0.3125rem;
  }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 1.5rem);
    padding: 0.75rem 1rem;
    line-height: 1.6;
    color: #8c98a4;
    content: 'Browse';
    background-color: transparent;
    border-left: inherit;
    border-radius: 0 0.3125rem 0.3125rem 0;
  }
  .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none;
  }
  .custom-range:focus {
    outline: 0;
  }
  .custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .custom-range::-moz-focus-outer {
    border: 0;
  }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
      transition: none;
    }
  }
  .custom-range::-webkit-slider-thumb:active {
    background-color: #eaf1ff;
  }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem;
  }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
      transition: none;
    }
  }
  .custom-range::-moz-range-thumb:active {
    background-color: #eaf1ff;
  }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem;
  }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
      transition: none;
    }
  }
  .custom-range::-ms-thumb:active {
    background-color: #eaf1ff;
  }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }
  .custom-range::-ms-fill-lower {
    background-color: #e7eaf3;
    border-radius: 1rem;
  }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e7eaf3;
    border-radius: 1rem;
  }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #97a4af;
  }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #97a4af;
  }
  .custom-range:disabled::-moz-range-track {
    cursor: default;
  }
  .custom-range:disabled::-ms-thumb {
    background-color: #97a4af;
  }
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none;
    }
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  .nav-link:focus,
  .nav-link:hover {
    text-decoration: none;
  }
  .nav-link.disabled {
    color: #8c98a4;
    pointer-events: none;
    cursor: default;
  }
  .nav-tabs {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
  .nav-tabs .nav-item {
    margin-bottom: -0.0625rem;
  }
  .nav-tabs .nav-link {
    border: 0.0625rem solid transparent;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }
  .nav-tabs .nav-link.disabled {
    color: #8c98a4;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #377dff;
    background-color: #fff;
    border-color: transparent transparent #377dff;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -0.0625rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-pills .nav-link {
    border-radius: 0.3125rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #377dff;
  }
  .nav-fill .nav-item,
  .nav-fill > .nav-link {
    flex: 1 1 auto;
    text-align: center;
  }
  .nav-justified .nav-item,
  .nav-justified > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0;
  }
  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-lg,
  .navbar .container-md,
  .navbar .container-sm,
  .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-brand:focus,
  .navbar-brand:hover {
    text-decoration: none;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  .navbar-toggler {
    padding: 0.75rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0.0625rem solid transparent;
    border-radius: 0.3125rem;
  }
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-nav .nav-link {
    color: #677788;
  }
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: #0052ea;
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-toggler {
    color: #677788;
    border-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23677788' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-light .navbar-text {
    color: #677788;
  }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-text a:focus,
  .navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  .navbar-dark .navbar-brand:focus,
  .navbar-dark .navbar-brand:hover {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
  }
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  .navbar-dark .navbar-text a:focus,
  .navbar-dark .navbar-text a:hover {
    color: #fff;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    border-radius: 0.75rem;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;
  }
  .card-title {
    margin-bottom: 1.5rem;
  }
  .card-subtitle {
    margin-top: -0.75rem;
    margin-bottom: 0;
  }
  .card-text:last-child {
    margin-bottom: 0;
  }
  .card-link:hover {
    text-decoration: none;
  }
  .card-link + .card-link {
    margin-left: 1.5rem;
  }
  .card-header {
    padding: 1.5rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
  }
  .card-header:first-child {
    border-radius: 0.75rem 0.75rem 0 0;
  }
  .card-footer {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
  }
  .card-footer:last-child {
    border-radius: 0 0 0.75rem 0.75rem;
  }
  .card-header-tabs {
    margin-right: -0.75rem;
    margin-bottom: -1.5rem;
    margin-left: -0.75rem;
    border-bottom: 0;
  }
  .card-header-pills {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: 0.75rem;
  }
  .card-img,
  .card-img-bottom,
  .card-img-top {
    flex-shrink: 0;
    width: 100%;
  }
  .card-img,
  .card-img-top {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .card-deck .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .card-group > .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-header,
    .card-group > .card:not(:last-child) .card-img-top {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-footer,
    .card-group > .card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-header,
    .card-group > .card:not(:first-child) .card-img-top {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-footer,
    .card-group > .card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0;
    }
  }
  .card-columns .card {
    margin-bottom: 1.5rem;
  }
  @media (min-width: 576px) {
    .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  .accordion {
    overflow-anchor: none;
  }
  .accordion > .card {
    overflow: hidden;
  }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -0.0625rem;
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: transparent;
    border-radius: 0.3125rem;
  }
  .breadcrumb-item {
    display: flex;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #8c98a4;
    content: '/';
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  .breadcrumb-item.active {
    color: #1e2022;
  }
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.3125rem;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #677788;
    background-color: #fff;
    border: 0 solid #e7eaf3;
  }
  .page-link:hover {
    z-index: 2;
    color: #377dff;
    text-decoration: none;
    background-color: rgba(55, 125, 255, 0.1);
    border-color: rgba(55, 125, 255, 0.1);
  }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
  }
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .page-item.disabled .page-link {
    color: #8c98a4;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #e7eaf3;
  }
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .badge {
    display: inline-block;
    padding: 0.275rem 0.5em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.3125rem;
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none;
    }
  }
  a.badge:focus,
  a.badge:hover {
    text-decoration: none;
  }
  .badge:empty {
    display: none;
  }
  .btn .badge {
    position: relative;
    top: -1px;
  }
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
  .badge-primary {
    color: #fff;
    background-color: #377dff;
  }
  a.badge-primary:focus,
  a.badge-primary:hover {
    color: #fff;
    background-color: #045cff;
  }
  a.badge-primary.focus,
  a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
  }
  .badge-secondary {
    color: #fff;
    background-color: #71869d;
  }
  a.badge-secondary:focus,
  a.badge-secondary:hover {
    color: #fff;
    background-color: #596d82;
  }
  a.badge-secondary.focus,
  a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
  }
  .badge-success {
    color: #fff;
    background-color: #00c9a7;
  }
  a.badge-success:focus,
  a.badge-success:hover {
    color: #fff;
    background-color: #00967d;
  }
  a.badge-success.focus,
  a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
  }
  .badge-info {
    color: #fff;
    background-color: #09a5be;
  }
  a.badge-info:focus,
  a.badge-info:hover {
    color: #fff;
    background-color: #077b8d;
  }
  a.badge-info.focus,
  a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(9, 165, 190, 0.5);
  }
  .badge-warning {
    color: #1e2022;
    background-color: #f5ca99;
  }
  a.badge-warning:focus,
  a.badge-warning:hover {
    color: #1e2022;
    background-color: #f0b26b;
  }
  a.badge-warning.focus,
  a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
  }
  .badge-danger {
    color: #fff;
    background-color: #ed4c78;
  }
  a.badge-danger:focus,
  a.badge-danger:hover {
    color: #fff;
    background-color: #e81e55;
  }
  a.badge-danger.focus,
  a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
  }
  .badge-light {
    color: #1e2022;
    background-color: #f7faff;
  }
  a.badge-light:focus,
  a.badge-light:hover {
    color: #1e2022;
    background-color: #c4daff;
  }
  a.badge-light.focus,
  a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
  }
  .badge-dark {
    color: #fff;
    background-color: #21325b;
  }
  a.badge-dark:focus,
  a.badge-dark:hover {
    color: #fff;
    background-color: #131d36;
  }
  a.badge-dark.focus,
  a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 50, 91, 0.5);
  }
  .badge-indigo {
    color: #fff;
    background-color: #2d1582;
  }
  a.badge-indigo:focus,
  a.badge-indigo:hover {
    color: #fff;
    background-color: #1e0e56;
  }
  a.badge-indigo.focus,
  a.badge-indigo:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
  }
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #f8fafd;
    border-radius: 0.75rem;
  }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 0.0625rem solid transparent;
    border-radius: 0.3125rem;
  }
  .alert-heading {
    color: inherit;
  }
  .alert-link {
    font-weight: 600;
  }
  .alert-dismissible {
    padding-right: 3.75rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  .alert-primary {
    color: #1d4185;
    background-color: #d7e5ff;
    border-color: #c7dbff;
  }
  .alert-primary hr {
    border-top-color: #aecbff;
  }
  .alert-primary .alert-link {
    color: #142d5b;
  }
  .alert-secondary {
    color: #3b4652;
    background-color: #e3e7eb;
    border-color: #d7dde4;
  }
  .alert-secondary hr {
    border-top-color: #c8d0da;
  }
  .alert-secondary .alert-link {
    color: #262d34;
  }
  .alert-success {
    color: #006957;
    background-color: #ccf4ed;
    border-color: #b8f0e6;
  }
  .alert-success hr {
    border-top-color: #a3ecdf;
  }
  .alert-success .alert-link {
    color: #00362d;
  }
  .alert-info {
    color: #055663;
    background-color: #ceedf2;
    border-color: #bae6ed;
  }
  .alert-info hr {
    border-top-color: #a6dfe8;
  }
  .alert-info .alert-link {
    color: #032c32;
  }
  .alert-warning {
    color: #7f6950;
    background-color: #fdf4eb;
    border-color: #fcf0e2;
  }
  .alert-warning hr {
    border-top-color: #fae4cb;
  }
  .alert-warning .alert-link {
    color: #604f3c;
  }
  .alert-danger {
    color: #7b283e;
    background-color: #fbdbe4;
    border-color: #facdd9;
  }
  .alert-danger hr {
    border-top-color: #f8b6c7;
  }
  .alert-danger .alert-link {
    color: #551b2b;
  }
  .alert-light {
    color: #808285;
    background-color: #fdfeff;
    border-color: #fdfeff;
  }
  .alert-light hr {
    border-top-color: #e4f1ff;
  }
  .alert-light .alert-link {
    color: #67696b;
  }
  .alert-dark {
    color: #111a2f;
    background-color: #d3d6de;
    border-color: #c1c6d1;
  }
  .alert-dark hr {
    border-top-color: #b2b9c6;
  }
  .alert-dark .alert-link {
    color: #03050a;
  }
  .alert-indigo {
    color: #170b44;
    background-color: #d5d0e6;
    border-color: #c4bddc;
  }
  .alert-indigo hr {
    border-top-color: #b5acd3;
  }
  .alert-indigo .alert-link {
    color: #080418;
  }
  @keyframes progress-bar-stripes {
    from {
      background-position: 0.75rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .progress {
    display: flex;
    height: 0.75rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e7eaf3;
    border-radius: 0.3125rem;
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #377dff;
    transition: width 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 0.75rem 0.75rem;
  }
  .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none;
    }
  }
  .media {
    display: flex;
    align-items: flex-start;
  }
  .media-body {
    flex: 1;
  }
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.3125rem;
  }
  .list-group-item-action {
    width: 100%;
    color: #677788;
    text-align: inherit;
  }
  .list-group-item-action:focus,
  .list-group-item-action:hover {
    z-index: 1;
    color: #677788;
    text-decoration: none;
    background-color: #f7faff;
  }
  .list-group-item-action:active {
    color: #677788;
    background-color: #f8fafd;
  }
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 0.0625rem solid #e7eaf3;
  }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #8c98a4;
    pointer-events: none;
    background-color: #fff;
  }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  .list-group-item + .list-group-item.active {
    margin-top: -0.0625rem;
    border-top-width: 0.0625rem;
  }
  .list-group-horizontal {
    flex-direction: row;
  }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -0.0625rem;
      border-left-width: 0.0625rem;
    }
  }
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -0.0625rem;
      border-left-width: 0.0625rem;
    }
  }
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -0.0625rem;
      border-left-width: 0.0625rem;
    }
  }
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -0.0625rem;
      border-left-width: 0.0625rem;
    }
  }
  .list-group-flush {
    border-radius: 0;
  }
  .list-group-flush > .list-group-item {
    border-width: 0 0 0.0625rem;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  .list-group-item-primary {
    color: #1d4185;
    background-color: #c7dbff;
  }
  .list-group-item-primary.list-group-item-action:focus,
  .list-group-item-primary.list-group-item-action:hover {
    color: #1d4185;
    background-color: #aecbff;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1d4185;
    border-color: #1d4185;
  }
  .list-group-item-secondary {
    color: #3b4652;
    background-color: #d7dde4;
  }
  .list-group-item-secondary.list-group-item-action:focus,
  .list-group-item-secondary.list-group-item-action:hover {
    color: #3b4652;
    background-color: #c8d0da;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3b4652;
    border-color: #3b4652;
  }
  .list-group-item-success {
    color: #006957;
    background-color: #b8f0e6;
  }
  .list-group-item-success.list-group-item-action:focus,
  .list-group-item-success.list-group-item-action:hover {
    color: #006957;
    background-color: #a3ecdf;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006957;
    border-color: #006957;
  }
  .list-group-item-info {
    color: #055663;
    background-color: #bae6ed;
  }
  .list-group-item-info.list-group-item-action:focus,
  .list-group-item-info.list-group-item-action:hover {
    color: #055663;
    background-color: #a6dfe8;
  }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055663;
    border-color: #055663;
  }
  .list-group-item-warning {
    color: #7f6950;
    background-color: #fcf0e2;
  }
  .list-group-item-warning.list-group-item-action:focus,
  .list-group-item-warning.list-group-item-action:hover {
    color: #7f6950;
    background-color: #fae4cb;
  }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f6950;
    border-color: #7f6950;
  }
  .list-group-item-danger {
    color: #7b283e;
    background-color: #facdd9;
  }
  .list-group-item-danger.list-group-item-action:focus,
  .list-group-item-danger.list-group-item-action:hover {
    color: #7b283e;
    background-color: #f8b6c7;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7b283e;
    border-color: #7b283e;
  }
  .list-group-item-light {
    color: #808285;
    background-color: #fdfeff;
  }
  .list-group-item-light.list-group-item-action:focus,
  .list-group-item-light.list-group-item-action:hover {
    color: #808285;
    background-color: #e4f1ff;
  }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #808285;
    border-color: #808285;
  }
  .list-group-item-dark {
    color: #111a2f;
    background-color: #c1c6d1;
  }
  .list-group-item-dark.list-group-item-action:focus,
  .list-group-item-dark.list-group-item-action:hover {
    color: #111a2f;
    background-color: #b2b9c6;
  }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #111a2f;
    border-color: #111a2f;
  }
  .list-group-item-indigo {
    color: #170b44;
    background-color: #c4bddc;
  }
  .list-group-item-indigo.list-group-item-action:focus,
  .list-group-item-indigo.list-group-item-action:hover {
    color: #170b44;
    background-color: #b5acd3;
  }
  .list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #170b44;
    border-color: #170b44;
  }
  .close {
    float: right;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;
    color: #1e2022;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .close:hover {
    color: #1e2022;
    text-decoration: none;
  }
  .close:not(:disabled):not(.disabled):focus,
  .close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  a.close.disabled {
    pointer-events: none;
  }
  .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 0.25rem;
  }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  .toast.showing {
    opacity: 1;
  }
  .toast.show {
    display: block;
    opacity: 1;
  }
  .toast.hide {
    display: none;
  }
  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #8c98a4;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  .toast-body {
    padding: 0.75rem;
  }
  .modal-open {
    overflow: hidden;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1005;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    transform: none;
  }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02);
  }
  .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }
  .modal-dialog-scrollable .modal-footer,
  .modal-dialog-scrollable .modal-header {
    flex-shrink: 0;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: '';
  }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }
  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid transparent;
    border-radius: 0.75rem;
    outline: 0;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1004;
    width: 100vw;
    height: 100vh;
    background-color: rgba(33, 50, 91, 0.125);
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-bottom: 0.0625rem solid #e7eaf3;
    border-top-left-radius: 0.6875rem;
    border-top-right-radius: 0.6875rem;
  }
  .modal-header .close {
    padding: 1rem 1.5rem;
    margin: -1rem -1.5rem -1rem auto;
  }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.6;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem;
  }
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 0.0625rem solid #e7eaf3;
    border-bottom-right-radius: 0.6875rem;
    border-bottom-left-radius: 0.6875rem;
  }
  .modal-footer > * {
    margin: 0.25rem;
  }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 550px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content;
    }
    .modal-sm {
      max-width: 400px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  .tooltip {
    position: absolute;
    z-index: 9999;
    display: block;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  .tooltip.show {
    opacity: 1;
  }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  .tooltip .arrow::before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
  }
  .bs-tooltip-auto[x-placement^='top'],
  .bs-tooltip-top {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[x-placement^='top'] .arrow,
  .bs-tooltip-top .arrow {
    bottom: 0;
  }
  .bs-tooltip-auto[x-placement^='top'] .arrow::before,
  .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #21325b;
  }
  .bs-tooltip-auto[x-placement^='right'],
  .bs-tooltip-right {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[x-placement^='right'] .arrow,
  .bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[x-placement^='right'] .arrow::before,
  .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #21325b;
  }
  .bs-tooltip-auto[x-placement^='bottom'],
  .bs-tooltip-bottom {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[x-placement^='bottom'] .arrow,
  .bs-tooltip-bottom .arrow {
    top: 0;
  }
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
  .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #21325b;
  }
  .bs-tooltip-auto[x-placement^='left'],
  .bs-tooltip-left {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[x-placement^='left'] .arrow,
  .bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[x-placement^='left'] .arrow::before,
  .bs-tooltip-left .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #21325b;
  }
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #21325b;
    border-radius: 0.3125rem;
  }
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid transparent;
    border-radius: 0.75rem;
  }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.75rem;
  }
  .popover .arrow::after,
  .popover .arrow::before {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
  }
  .bs-popover-auto[x-placement^='top'],
  .bs-popover-top {
    margin-bottom: 0.5rem;
  }
  .bs-popover-auto[x-placement^='top'] > .arrow,
  .bs-popover-top > .arrow {
    bottom: -0.5625rem;
  }
  .bs-popover-auto[x-placement^='top'] > .arrow::before,
  .bs-popover-top > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.05);
  }
  .bs-popover-auto[x-placement^='top'] > .arrow::after,
  .bs-popover-top > .arrow::after {
    bottom: 0.0625rem;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }
  .bs-popover-auto[x-placement^='right'],
  .bs-popover-right {
    margin-left: 0.5rem;
  }
  .bs-popover-auto[x-placement^='right'] > .arrow,
  .bs-popover-right > .arrow {
    left: -0.5625rem;
    width: 0.5rem;
    height: 1rem;
    margin: 0.75rem 0;
  }
  .bs-popover-auto[x-placement^='right'] > .arrow::before,
  .bs-popover-right > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.05);
  }
  .bs-popover-auto[x-placement^='right'] > .arrow::after,
  .bs-popover-right > .arrow::after {
    left: 0.0625rem;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  .bs-popover-auto[x-placement^='bottom'],
  .bs-popover-bottom {
    margin-top: 0.5rem;
  }
  .bs-popover-auto[x-placement^='bottom'] > .arrow,
  .bs-popover-bottom > .arrow {
    top: -0.5625rem;
  }
  .bs-popover-auto[x-placement^='bottom'] > .arrow::before,
  .bs-popover-bottom > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.05);
  }
  .bs-popover-auto[x-placement^='bottom'] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    top: 0.0625rem;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }
  .bs-popover-auto[x-placement^='bottom'] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: '';
    border-bottom: 0.0625rem solid #f8fafd;
  }
  .bs-popover-auto[x-placement^='left'],
  .bs-popover-left {
    margin-right: 0.5rem;
  }
  .bs-popover-auto[x-placement^='left'] > .arrow,
  .bs-popover-left > .arrow {
    right: -0.5625rem;
    width: 0.5rem;
    height: 1rem;
    margin: 0.75rem 0;
  }
  .bs-popover-auto[x-placement^='left'] > .arrow::before,
  .bs-popover-left > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.05);
  }
  .bs-popover-auto[x-placement^='left'] > .arrow::after,
  .bs-popover-left > .arrow::after {
    right: 0.0625rem;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }
  .popover-header {
    padding: 0.75rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #1e2022;
    background-color: #f8fafd;
    border-bottom: 0.0625rem solid #e4ecf7;
    border-top-left-radius: 0.6875rem;
    border-top-right-radius: 0.6875rem;
  }
  .popover-header:empty {
    display: none;
  }
  .popover-body {
    padding: 1.125rem 1rem;
    color: #677788;
  }
  .carousel {
    position: relative;
  }
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: '';
  }
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: block;
  }
  .active.carousel-item-right,
  .carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%);
  }
  .active.carousel-item-left,
  .carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%);
  }
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right,
  .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none;
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-next,
    .carousel-control-prev {
      transition: none;
    }
  }
  .carousel-control-next:focus,
  .carousel-control-next:hover,
  .carousel-control-prev:focus,
  .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next {
    right: 0;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
      transition: none;
    }
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
  }
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  .align-baseline {
    vertical-align: baseline !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .align-text-top {
    vertical-align: text-top !important;
  }
  .bg-primary {
    background-color: #377dff !important;
  }
  a.bg-primary:focus,
  a.bg-primary:hover,
  button.bg-primary:focus,
  button.bg-primary:hover {
    background-color: #045cff !important;
  }
  .bg-secondary {
    background-color: #71869d !important;
  }
  a.bg-secondary:focus,
  a.bg-secondary:hover,
  button.bg-secondary:focus,
  button.bg-secondary:hover {
    background-color: #596d82 !important;
  }
  .bg-success {
    background-color: #00c9a7 !important;
  }
  a.bg-success:focus,
  a.bg-success:hover,
  button.bg-success:focus,
  button.bg-success:hover {
    background-color: #00967d !important;
  }
  .bg-info {
    background-color: #09a5be !important;
  }
  a.bg-info:focus,
  a.bg-info:hover,
  button.bg-info:focus,
  button.bg-info:hover {
    background-color: #077b8d !important;
  }
  .bg-warning {
    background-color: #f5ca99 !important;
  }
  a.bg-warning:focus,
  a.bg-warning:hover,
  button.bg-warning:focus,
  button.bg-warning:hover {
    background-color: #f0b26b !important;
  }
  .bg-danger {
    background-color: #ed4c78 !important;
  }
  a.bg-danger:focus,
  a.bg-danger:hover,
  button.bg-danger:focus,
  button.bg-danger:hover {
    background-color: #e81e55 !important;
  }
  .bg-light {
    background-color: #f7faff !important;
  }
  a.bg-light:focus,
  a.bg-light:hover,
  button.bg-light:focus,
  button.bg-light:hover {
    background-color: #c4daff !important;
  }
  .bg-dark {
    background-color: #21325b !important;
  }
  a.bg-dark:focus,
  a.bg-dark:hover,
  button.bg-dark:focus,
  button.bg-dark:hover {
    background-color: #131d36 !important;
  }
  .bg-indigo {
    background-color: #2d1582 !important;
  }
  a.bg-indigo:focus,
  a.bg-indigo:hover,
  button.bg-indigo:focus,
  button.bg-indigo:hover {
    background-color: #1e0e56 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }
  .border {
    border: 0.0625rem solid #e7eaf3 !important;
  }
  .border-top {
    border-top: 0.0625rem solid #e7eaf3 !important;
  }
  .border-right {
    border-right: 0.0625rem solid #e7eaf3 !important;
  }
  .border-bottom {
    border-bottom: 0.0625rem solid #e7eaf3 !important;
  }
  .border-left {
    border-left: 0.0625rem solid #e7eaf3 !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .border-top-0 {
    border-top: 0 !important;
  }
  .border-right-0 {
    border-right: 0 !important;
  }
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-left-0 {
    border-left: 0 !important;
  }
  .border-primary {
    border-color: #377dff !important;
  }
  .border-secondary {
    border-color: #71869d !important;
  }
  .border-success {
    border-color: #00c9a7 !important;
  }
  .border-info {
    border-color: #09a5be !important;
  }
  .border-warning {
    border-color: #f5ca99 !important;
  }
  .border-danger {
    border-color: #ed4c78 !important;
  }
  .border-light {
    border-color: #f7faff !important;
  }
  .border-dark {
    border-color: #21325b !important;
  }
  .border-indigo {
    border-color: #2d1582 !important;
  }
  .border-white {
    border-color: #fff !important;
  }
  .rounded-sm {
    border-radius: 0.25rem !important;
  }
  .rounded {
    border-radius: 0.3125rem !important;
  }
  .rounded-top {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
  }
  .rounded-right {
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0.3125rem !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .rounded-left {
    border-top-left-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .rounded-lg {
    border-radius: 0.75rem !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .rounded-pill {
    border-radius: 50rem !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: '';
  }
  .d-none {
    display: none !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-block {
    display: block !important;
  }
  .d-table {
    display: table !important;
  }
  .d-table-row {
    display: table-row !important;
  }
  .d-table-cell {
    display: table-cell !important;
  }
  .d-flex {
    display: flex !important;
  }
  .d-inline-flex {
    display: inline-flex !important;
  }
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: flex !important;
    }
    .d-print-inline-flex {
      display: inline-flex !important;
    }
  }
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  .embed-responsive::before {
    display: block;
    content: '';
  }
  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  .flex-row {
    flex-direction: row !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-fill {
    flex: 1 1 auto !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .align-items-start {
    align-items: flex-start !important;
  }
  .align-items-end {
    align-items: flex-end !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .align-items-baseline {
    align-items: baseline !important;
  }
  .align-items-stretch {
    align-items: stretch !important;
  }
  .align-content-start {
    align-content: flex-start !important;
  }
  .align-content-end {
    align-content: flex-end !important;
  }
  .align-content-center {
    align-content: center !important;
  }
  .align-content-between {
    align-content: space-between !important;
  }
  .align-content-around {
    align-content: space-around !important;
  }
  .align-content-stretch {
    align-content: stretch !important;
  }
  .align-self-auto {
    align-self: auto !important;
  }
  .align-self-start {
    align-self: flex-start !important;
  }
  .align-self-end {
    align-self: flex-end !important;
  }
  .align-self-center {
    align-self: center !important;
  }
  .align-self-baseline {
    align-self: baseline !important;
  }
  .align-self-stretch {
    align-self: stretch !important;
  }
  @media (min-width: 576px) {
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 768px) {
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 992px) {
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 1200px) {
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
  }
  .float-left {
    float: left !important;
  }
  .float-right {
    float: right !important;
  }
  .float-none {
    float: none !important;
  }
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  .user-select-all {
    user-select: all !important;
  }
  .user-select-auto {
    user-select: auto !important;
  }
  .user-select-none {
    user-select: none !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .position-static {
    position: static !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-fixed {
    position: fixed !important;
  }
  .position-sticky {
    position: sticky !important;
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports (position: sticky) {
    .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .shadow-sm {
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1) !important;
  }
  .shadow {
    box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important;
  }
  .shadow-lg {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
  }
  .shadow-none {
    box-shadow: none !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .w-15 {
    width: 15% !important;
  }
  .w-20 {
    width: 20% !important;
  }
  .w-30 {
    width: 30% !important;
  }
  .w-35 {
    width: 35% !important;
  }
  .w-40 {
    width: 40% !important;
  }
  .w-60 {
    width: 60% !important;
  }
  .w-65 {
    width: 65% !important;
  }
  .w-80 {
    width: 80% !important;
  }
  .w-85 {
    width: 85% !important;
  }
  .w-90 {
    width: 90% !important;
  }
  .h-25 {
    height: 25% !important;
  }
  .h-50 {
    height: 50% !important;
  }
  .h-75 {
    height: 75% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .h-15 {
    height: 15% !important;
  }
  .h-20 {
    height: 20% !important;
  }
  .h-30 {
    height: 30% !important;
  }
  .h-35 {
    height: 35% !important;
  }
  .h-40 {
    height: 40% !important;
  }
  .h-60 {
    height: 60% !important;
  }
  .h-65 {
    height: 65% !important;
  }
  .h-80 {
    height: 80% !important;
  }
  .h-85 {
    height: 85% !important;
  }
  .h-90 {
    height: 90% !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .mh-100 {
    max-height: 100% !important;
  }
  .min-vw-100 {
    min-width: 100vw !important;
  }
  .min-vh-100 {
    min-height: 100vh !important;
  }
  .vw-100 {
    width: 100vw !important;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  .m-3 {
    margin: 1rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  .m-4 {
    margin: 1.5rem !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  .m-5 {
    margin: 2rem !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 2rem !important;
  }
  .mr-5,
  .mx-5 {
    margin-right: 2rem !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 2rem !important;
  }
  .ml-5,
  .mx-5 {
    margin-left: 2rem !important;
  }
  .m-6 {
    margin: 2.5rem !important;
  }
  .mt-6,
  .my-6 {
    margin-top: 2.5rem !important;
  }
  .mr-6,
  .mx-6 {
    margin-right: 2.5rem !important;
  }
  .mb-6,
  .my-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-6,
  .mx-6 {
    margin-left: 2.5rem !important;
  }
  .m-7 {
    margin: 3rem !important;
  }
  .mt-7,
  .my-7 {
    margin-top: 3rem !important;
  }
  .mr-7,
  .mx-7 {
    margin-right: 3rem !important;
  }
  .mb-7,
  .my-7 {
    margin-bottom: 3rem !important;
  }
  .ml-7,
  .mx-7 {
    margin-left: 3rem !important;
  }
  .m-8 {
    margin: 3.5rem !important;
  }
  .mt-8,
  .my-8 {
    margin-top: 3.5rem !important;
  }
  .mr-8,
  .mx-8 {
    margin-right: 3.5rem !important;
  }
  .mb-8,
  .my-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-8,
  .mx-8 {
    margin-left: 3.5rem !important;
  }
  .m-9 {
    margin: 4rem !important;
  }
  .mt-9,
  .my-9 {
    margin-top: 4rem !important;
  }
  .mr-9,
  .mx-9 {
    margin-right: 4rem !important;
  }
  .mb-9,
  .my-9 {
    margin-bottom: 4rem !important;
  }
  .ml-9,
  .mx-9 {
    margin-left: 4rem !important;
  }
  .m-10 {
    margin: 4.5rem !important;
  }
  .mt-10,
  .my-10 {
    margin-top: 4.5rem !important;
  }
  .mr-10,
  .mx-10 {
    margin-right: 4.5rem !important;
  }
  .mb-10,
  .my-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-10,
  .mx-10 {
    margin-left: 4.5rem !important;
  }
  .m-11 {
    margin: 5rem !important;
  }
  .mt-11,
  .my-11 {
    margin-top: 5rem !important;
  }
  .mr-11,
  .mx-11 {
    margin-right: 5rem !important;
  }
  .mb-11,
  .my-11 {
    margin-bottom: 5rem !important;
  }
  .ml-11,
  .mx-11 {
    margin-left: 5rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  .p-3 {
    padding: 1rem !important;
  }
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  .p-4 {
    padding: 1.5rem !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  .p-5 {
    padding: 2rem !important;
  }
  .pt-5,
  .py-5 {
    padding-top: 2rem !important;
  }
  .pr-5,
  .px-5 {
    padding-right: 2rem !important;
  }
  .pb-5,
  .py-5 {
    padding-bottom: 2rem !important;
  }
  .pl-5,
  .px-5 {
    padding-left: 2rem !important;
  }
  .p-6 {
    padding: 2.5rem !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 2.5rem !important;
  }
  .pr-6,
  .px-6 {
    padding-right: 2.5rem !important;
  }
  .pb-6,
  .py-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-6,
  .px-6 {
    padding-left: 2.5rem !important;
  }
  .p-7 {
    padding: 3rem !important;
  }
  .pt-7,
  .py-7 {
    padding-top: 3rem !important;
  }
  .pr-7,
  .px-7 {
    padding-right: 3rem !important;
  }
  .pb-7,
  .py-7 {
    padding-bottom: 3rem !important;
  }
  .pl-7,
  .px-7 {
    padding-left: 3rem !important;
  }
  .p-8 {
    padding: 3.5rem !important;
  }
  .pt-8,
  .py-8 {
    padding-top: 3.5rem !important;
  }
  .pr-8,
  .px-8 {
    padding-right: 3.5rem !important;
  }
  .pb-8,
  .py-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-8,
  .px-8 {
    padding-left: 3.5rem !important;
  }
  .p-9 {
    padding: 4rem !important;
  }
  .pt-9,
  .py-9 {
    padding-top: 4rem !important;
  }
  .pr-9,
  .px-9 {
    padding-right: 4rem !important;
  }
  .pb-9,
  .py-9 {
    padding-bottom: 4rem !important;
  }
  .pl-9,
  .px-9 {
    padding-left: 4rem !important;
  }
  .p-10 {
    padding: 4.5rem !important;
  }
  .pt-10,
  .py-10 {
    padding-top: 4.5rem !important;
  }
  .pr-10,
  .px-10 {
    padding-right: 4.5rem !important;
  }
  .pb-10,
  .py-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-10,
  .px-10 {
    padding-left: 4.5rem !important;
  }
  .p-11 {
    padding: 5rem !important;
  }
  .pt-11,
  .py-11 {
    padding-top: 5rem !important;
  }
  .pr-11,
  .px-11 {
    padding-right: 5rem !important;
  }
  .pb-11,
  .py-11 {
    padding-bottom: 5rem !important;
  }
  .pl-11,
  .px-11 {
    padding-left: 5rem !important;
  }
  .m-n1 {
    margin: -0.25rem !important;
  }
  .mt-n1,
  .my-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-n1,
  .mx-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-n1,
  .mx-n1 {
    margin-left: -0.25rem !important;
  }
  .m-n2 {
    margin: -0.5rem !important;
  }
  .mt-n2,
  .my-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-n2,
  .mx-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-n2,
  .mx-n2 {
    margin-left: -0.5rem !important;
  }
  .m-n3 {
    margin: -1rem !important;
  }
  .mt-n3,
  .my-n3 {
    margin-top: -1rem !important;
  }
  .mr-n3,
  .mx-n3 {
    margin-right: -1rem !important;
  }
  .mb-n3,
  .my-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-n3,
  .mx-n3 {
    margin-left: -1rem !important;
  }
  .m-n4 {
    margin: -1.5rem !important;
  }
  .mt-n4,
  .my-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-n4,
  .mx-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-n4,
  .my-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-n4,
  .mx-n4 {
    margin-left: -1.5rem !important;
  }
  .m-n5 {
    margin: -2rem !important;
  }
  .mt-n5,
  .my-n5 {
    margin-top: -2rem !important;
  }
  .mr-n5,
  .mx-n5 {
    margin-right: -2rem !important;
  }
  .mb-n5,
  .my-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-n5,
  .mx-n5 {
    margin-left: -2rem !important;
  }
  .m-n6 {
    margin: -2.5rem !important;
  }
  .mt-n6,
  .my-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-n6,
  .mx-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-n6,
  .my-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-n6,
  .mx-n6 {
    margin-left: -2.5rem !important;
  }
  .m-n7 {
    margin: -3rem !important;
  }
  .mt-n7,
  .my-n7 {
    margin-top: -3rem !important;
  }
  .mr-n7,
  .mx-n7 {
    margin-right: -3rem !important;
  }
  .mb-n7,
  .my-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-n7,
  .mx-n7 {
    margin-left: -3rem !important;
  }
  .m-n8 {
    margin: -3.5rem !important;
  }
  .mt-n8,
  .my-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-n8,
  .mx-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-n8,
  .my-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-n8,
  .mx-n8 {
    margin-left: -3.5rem !important;
  }
  .m-n9 {
    margin: -4rem !important;
  }
  .mt-n9,
  .my-n9 {
    margin-top: -4rem !important;
  }
  .mr-n9,
  .mx-n9 {
    margin-right: -4rem !important;
  }
  .mb-n9,
  .my-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-n9,
  .mx-n9 {
    margin-left: -4rem !important;
  }
  .m-n10 {
    margin: -4.5rem !important;
  }
  .mt-n10,
  .my-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-n10,
  .mx-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-n10,
  .my-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-n10,
  .mx-n10 {
    margin-left: -4.5rem !important;
  }
  .m-n11 {
    margin: -5rem !important;
  }
  .mt-n11,
  .my-n11 {
    margin-top: -5rem !important;
  }
  .mr-n11,
  .mx-n11 {
    margin-right: -5rem !important;
  }
  .mb-n11,
  .my-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-n11,
  .mx-n11 {
    margin-left: -5rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 2rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 2rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 2rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 2rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 2rem !important;
    }
    .m-sm-6 {
      margin: 2.5rem !important;
    }
    .mt-sm-6,
    .my-sm-6 {
      margin-top: 2.5rem !important;
    }
    .mr-sm-6,
    .mx-sm-6 {
      margin-right: 2.5rem !important;
    }
    .mb-sm-6,
    .my-sm-6 {
      margin-bottom: 2.5rem !important;
    }
    .ml-sm-6,
    .mx-sm-6 {
      margin-left: 2.5rem !important;
    }
    .m-sm-7 {
      margin: 3rem !important;
    }
    .mt-sm-7,
    .my-sm-7 {
      margin-top: 3rem !important;
    }
    .mr-sm-7,
    .mx-sm-7 {
      margin-right: 3rem !important;
    }
    .mb-sm-7,
    .my-sm-7 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-7,
    .mx-sm-7 {
      margin-left: 3rem !important;
    }
    .m-sm-8 {
      margin: 3.5rem !important;
    }
    .mt-sm-8,
    .my-sm-8 {
      margin-top: 3.5rem !important;
    }
    .mr-sm-8,
    .mx-sm-8 {
      margin-right: 3.5rem !important;
    }
    .mb-sm-8,
    .my-sm-8 {
      margin-bottom: 3.5rem !important;
    }
    .ml-sm-8,
    .mx-sm-8 {
      margin-left: 3.5rem !important;
    }
    .m-sm-9 {
      margin: 4rem !important;
    }
    .mt-sm-9,
    .my-sm-9 {
      margin-top: 4rem !important;
    }
    .mr-sm-9,
    .mx-sm-9 {
      margin-right: 4rem !important;
    }
    .mb-sm-9,
    .my-sm-9 {
      margin-bottom: 4rem !important;
    }
    .ml-sm-9,
    .mx-sm-9 {
      margin-left: 4rem !important;
    }
    .m-sm-10 {
      margin: 4.5rem !important;
    }
    .mt-sm-10,
    .my-sm-10 {
      margin-top: 4.5rem !important;
    }
    .mr-sm-10,
    .mx-sm-10 {
      margin-right: 4.5rem !important;
    }
    .mb-sm-10,
    .my-sm-10 {
      margin-bottom: 4.5rem !important;
    }
    .ml-sm-10,
    .mx-sm-10 {
      margin-left: 4.5rem !important;
    }
    .m-sm-11 {
      margin: 5rem !important;
    }
    .mt-sm-11,
    .my-sm-11 {
      margin-top: 5rem !important;
    }
    .mr-sm-11,
    .mx-sm-11 {
      margin-right: 5rem !important;
    }
    .mb-sm-11,
    .my-sm-11 {
      margin-bottom: 5rem !important;
    }
    .ml-sm-11,
    .mx-sm-11 {
      margin-left: 5rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 2rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 2rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 2rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 2rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 2rem !important;
    }
    .p-sm-6 {
      padding: 2.5rem !important;
    }
    .pt-sm-6,
    .py-sm-6 {
      padding-top: 2.5rem !important;
    }
    .pr-sm-6,
    .px-sm-6 {
      padding-right: 2.5rem !important;
    }
    .pb-sm-6,
    .py-sm-6 {
      padding-bottom: 2.5rem !important;
    }
    .pl-sm-6,
    .px-sm-6 {
      padding-left: 2.5rem !important;
    }
    .p-sm-7 {
      padding: 3rem !important;
    }
    .pt-sm-7,
    .py-sm-7 {
      padding-top: 3rem !important;
    }
    .pr-sm-7,
    .px-sm-7 {
      padding-right: 3rem !important;
    }
    .pb-sm-7,
    .py-sm-7 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-7,
    .px-sm-7 {
      padding-left: 3rem !important;
    }
    .p-sm-8 {
      padding: 3.5rem !important;
    }
    .pt-sm-8,
    .py-sm-8 {
      padding-top: 3.5rem !important;
    }
    .pr-sm-8,
    .px-sm-8 {
      padding-right: 3.5rem !important;
    }
    .pb-sm-8,
    .py-sm-8 {
      padding-bottom: 3.5rem !important;
    }
    .pl-sm-8,
    .px-sm-8 {
      padding-left: 3.5rem !important;
    }
    .p-sm-9 {
      padding: 4rem !important;
    }
    .pt-sm-9,
    .py-sm-9 {
      padding-top: 4rem !important;
    }
    .pr-sm-9,
    .px-sm-9 {
      padding-right: 4rem !important;
    }
    .pb-sm-9,
    .py-sm-9 {
      padding-bottom: 4rem !important;
    }
    .pl-sm-9,
    .px-sm-9 {
      padding-left: 4rem !important;
    }
    .p-sm-10 {
      padding: 4.5rem !important;
    }
    .pt-sm-10,
    .py-sm-10 {
      padding-top: 4.5rem !important;
    }
    .pr-sm-10,
    .px-sm-10 {
      padding-right: 4.5rem !important;
    }
    .pb-sm-10,
    .py-sm-10 {
      padding-bottom: 4.5rem !important;
    }
    .pl-sm-10,
    .px-sm-10 {
      padding-left: 4.5rem !important;
    }
    .p-sm-11 {
      padding: 5rem !important;
    }
    .pt-sm-11,
    .py-sm-11 {
      padding-top: 5rem !important;
    }
    .pr-sm-11,
    .px-sm-11 {
      padding-right: 5rem !important;
    }
    .pb-sm-11,
    .py-sm-11 {
      padding-bottom: 5rem !important;
    }
    .pl-sm-11,
    .px-sm-11 {
      padding-left: 5rem !important;
    }
    .m-sm-n1 {
      margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
      margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
      margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
      margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
      margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
      margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
      margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
      margin-left: -1rem !important;
    }
    .m-sm-n4 {
      margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
      margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
      margin: -2rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
      margin-top: -2rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
      margin-right: -2rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
      margin-bottom: -2rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
      margin-left: -2rem !important;
    }
    .m-sm-n6 {
      margin: -2.5rem !important;
    }
    .mt-sm-n6,
    .my-sm-n6 {
      margin-top: -2.5rem !important;
    }
    .mr-sm-n6,
    .mx-sm-n6 {
      margin-right: -2.5rem !important;
    }
    .mb-sm-n6,
    .my-sm-n6 {
      margin-bottom: -2.5rem !important;
    }
    .ml-sm-n6,
    .mx-sm-n6 {
      margin-left: -2.5rem !important;
    }
    .m-sm-n7 {
      margin: -3rem !important;
    }
    .mt-sm-n7,
    .my-sm-n7 {
      margin-top: -3rem !important;
    }
    .mr-sm-n7,
    .mx-sm-n7 {
      margin-right: -3rem !important;
    }
    .mb-sm-n7,
    .my-sm-n7 {
      margin-bottom: -3rem !important;
    }
    .ml-sm-n7,
    .mx-sm-n7 {
      margin-left: -3rem !important;
    }
    .m-sm-n8 {
      margin: -3.5rem !important;
    }
    .mt-sm-n8,
    .my-sm-n8 {
      margin-top: -3.5rem !important;
    }
    .mr-sm-n8,
    .mx-sm-n8 {
      margin-right: -3.5rem !important;
    }
    .mb-sm-n8,
    .my-sm-n8 {
      margin-bottom: -3.5rem !important;
    }
    .ml-sm-n8,
    .mx-sm-n8 {
      margin-left: -3.5rem !important;
    }
    .m-sm-n9 {
      margin: -4rem !important;
    }
    .mt-sm-n9,
    .my-sm-n9 {
      margin-top: -4rem !important;
    }
    .mr-sm-n9,
    .mx-sm-n9 {
      margin-right: -4rem !important;
    }
    .mb-sm-n9,
    .my-sm-n9 {
      margin-bottom: -4rem !important;
    }
    .ml-sm-n9,
    .mx-sm-n9 {
      margin-left: -4rem !important;
    }
    .m-sm-n10 {
      margin: -4.5rem !important;
    }
    .mt-sm-n10,
    .my-sm-n10 {
      margin-top: -4.5rem !important;
    }
    .mr-sm-n10,
    .mx-sm-n10 {
      margin-right: -4.5rem !important;
    }
    .mb-sm-n10,
    .my-sm-n10 {
      margin-bottom: -4.5rem !important;
    }
    .ml-sm-n10,
    .mx-sm-n10 {
      margin-left: -4.5rem !important;
    }
    .m-sm-n11 {
      margin: -5rem !important;
    }
    .mt-sm-n11,
    .my-sm-n11 {
      margin-top: -5rem !important;
    }
    .mr-sm-n11,
    .mx-sm-n11 {
      margin-right: -5rem !important;
    }
    .mb-sm-n11,
    .my-sm-n11 {
      margin-bottom: -5rem !important;
    }
    .ml-sm-n11,
    .mx-sm-n11 {
      margin-left: -5rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem !important;
    }
    .m-md-5 {
      margin: 2rem !important;
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 2rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 2rem !important;
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 2rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 2rem !important;
    }
    .m-md-6 {
      margin: 2.5rem !important;
    }
    .mt-md-6,
    .my-md-6 {
      margin-top: 2.5rem !important;
    }
    .mr-md-6,
    .mx-md-6 {
      margin-right: 2.5rem !important;
    }
    .mb-md-6,
    .my-md-6 {
      margin-bottom: 2.5rem !important;
    }
    .ml-md-6,
    .mx-md-6 {
      margin-left: 2.5rem !important;
    }
    .m-md-7 {
      margin: 3rem !important;
    }
    .mt-md-7,
    .my-md-7 {
      margin-top: 3rem !important;
    }
    .mr-md-7,
    .mx-md-7 {
      margin-right: 3rem !important;
    }
    .mb-md-7,
    .my-md-7 {
      margin-bottom: 3rem !important;
    }
    .ml-md-7,
    .mx-md-7 {
      margin-left: 3rem !important;
    }
    .m-md-8 {
      margin: 3.5rem !important;
    }
    .mt-md-8,
    .my-md-8 {
      margin-top: 3.5rem !important;
    }
    .mr-md-8,
    .mx-md-8 {
      margin-right: 3.5rem !important;
    }
    .mb-md-8,
    .my-md-8 {
      margin-bottom: 3.5rem !important;
    }
    .ml-md-8,
    .mx-md-8 {
      margin-left: 3.5rem !important;
    }
    .m-md-9 {
      margin: 4rem !important;
    }
    .mt-md-9,
    .my-md-9 {
      margin-top: 4rem !important;
    }
    .mr-md-9,
    .mx-md-9 {
      margin-right: 4rem !important;
    }
    .mb-md-9,
    .my-md-9 {
      margin-bottom: 4rem !important;
    }
    .ml-md-9,
    .mx-md-9 {
      margin-left: 4rem !important;
    }
    .m-md-10 {
      margin: 4.5rem !important;
    }
    .mt-md-10,
    .my-md-10 {
      margin-top: 4.5rem !important;
    }
    .mr-md-10,
    .mx-md-10 {
      margin-right: 4.5rem !important;
    }
    .mb-md-10,
    .my-md-10 {
      margin-bottom: 4.5rem !important;
    }
    .ml-md-10,
    .mx-md-10 {
      margin-left: 4.5rem !important;
    }
    .m-md-11 {
      margin: 5rem !important;
    }
    .mt-md-11,
    .my-md-11 {
      margin-top: 5rem !important;
    }
    .mr-md-11,
    .mx-md-11 {
      margin-right: 5rem !important;
    }
    .mb-md-11,
    .my-md-11 {
      margin-bottom: 5rem !important;
    }
    .ml-md-11,
    .mx-md-11 {
      margin-left: 5rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 2rem !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 2rem !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 2rem !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 2rem !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 2rem !important;
    }
    .p-md-6 {
      padding: 2.5rem !important;
    }
    .pt-md-6,
    .py-md-6 {
      padding-top: 2.5rem !important;
    }
    .pr-md-6,
    .px-md-6 {
      padding-right: 2.5rem !important;
    }
    .pb-md-6,
    .py-md-6 {
      padding-bottom: 2.5rem !important;
    }
    .pl-md-6,
    .px-md-6 {
      padding-left: 2.5rem !important;
    }
    .p-md-7 {
      padding: 3rem !important;
    }
    .pt-md-7,
    .py-md-7 {
      padding-top: 3rem !important;
    }
    .pr-md-7,
    .px-md-7 {
      padding-right: 3rem !important;
    }
    .pb-md-7,
    .py-md-7 {
      padding-bottom: 3rem !important;
    }
    .pl-md-7,
    .px-md-7 {
      padding-left: 3rem !important;
    }
    .p-md-8 {
      padding: 3.5rem !important;
    }
    .pt-md-8,
    .py-md-8 {
      padding-top: 3.5rem !important;
    }
    .pr-md-8,
    .px-md-8 {
      padding-right: 3.5rem !important;
    }
    .pb-md-8,
    .py-md-8 {
      padding-bottom: 3.5rem !important;
    }
    .pl-md-8,
    .px-md-8 {
      padding-left: 3.5rem !important;
    }
    .p-md-9 {
      padding: 4rem !important;
    }
    .pt-md-9,
    .py-md-9 {
      padding-top: 4rem !important;
    }
    .pr-md-9,
    .px-md-9 {
      padding-right: 4rem !important;
    }
    .pb-md-9,
    .py-md-9 {
      padding-bottom: 4rem !important;
    }
    .pl-md-9,
    .px-md-9 {
      padding-left: 4rem !important;
    }
    .p-md-10 {
      padding: 4.5rem !important;
    }
    .pt-md-10,
    .py-md-10 {
      padding-top: 4.5rem !important;
    }
    .pr-md-10,
    .px-md-10 {
      padding-right: 4.5rem !important;
    }
    .pb-md-10,
    .py-md-10 {
      padding-bottom: 4.5rem !important;
    }
    .pl-md-10,
    .px-md-10 {
      padding-left: 4.5rem !important;
    }
    .p-md-11 {
      padding: 5rem !important;
    }
    .pt-md-11,
    .py-md-11 {
      padding-top: 5rem !important;
    }
    .pr-md-11,
    .px-md-11 {
      padding-right: 5rem !important;
    }
    .pb-md-11,
    .py-md-11 {
      padding-bottom: 5rem !important;
    }
    .pl-md-11,
    .px-md-11 {
      padding-left: 5rem !important;
    }
    .m-md-n1 {
      margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
      margin-left: -0.25rem !important;
    }
    .m-md-n2 {
      margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
      margin-left: -0.5rem !important;
    }
    .m-md-n3 {
      margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
      margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
      margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
      margin-left: -1rem !important;
    }
    .m-md-n4 {
      margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
      margin-left: -1.5rem !important;
    }
    .m-md-n5 {
      margin: -2rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
      margin-top: -2rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
      margin-right: -2rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
      margin-bottom: -2rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
      margin-left: -2rem !important;
    }
    .m-md-n6 {
      margin: -2.5rem !important;
    }
    .mt-md-n6,
    .my-md-n6 {
      margin-top: -2.5rem !important;
    }
    .mr-md-n6,
    .mx-md-n6 {
      margin-right: -2.5rem !important;
    }
    .mb-md-n6,
    .my-md-n6 {
      margin-bottom: -2.5rem !important;
    }
    .ml-md-n6,
    .mx-md-n6 {
      margin-left: -2.5rem !important;
    }
    .m-md-n7 {
      margin: -3rem !important;
    }
    .mt-md-n7,
    .my-md-n7 {
      margin-top: -3rem !important;
    }
    .mr-md-n7,
    .mx-md-n7 {
      margin-right: -3rem !important;
    }
    .mb-md-n7,
    .my-md-n7 {
      margin-bottom: -3rem !important;
    }
    .ml-md-n7,
    .mx-md-n7 {
      margin-left: -3rem !important;
    }
    .m-md-n8 {
      margin: -3.5rem !important;
    }
    .mt-md-n8,
    .my-md-n8 {
      margin-top: -3.5rem !important;
    }
    .mr-md-n8,
    .mx-md-n8 {
      margin-right: -3.5rem !important;
    }
    .mb-md-n8,
    .my-md-n8 {
      margin-bottom: -3.5rem !important;
    }
    .ml-md-n8,
    .mx-md-n8 {
      margin-left: -3.5rem !important;
    }
    .m-md-n9 {
      margin: -4rem !important;
    }
    .mt-md-n9,
    .my-md-n9 {
      margin-top: -4rem !important;
    }
    .mr-md-n9,
    .mx-md-n9 {
      margin-right: -4rem !important;
    }
    .mb-md-n9,
    .my-md-n9 {
      margin-bottom: -4rem !important;
    }
    .ml-md-n9,
    .mx-md-n9 {
      margin-left: -4rem !important;
    }
    .m-md-n10 {
      margin: -4.5rem !important;
    }
    .mt-md-n10,
    .my-md-n10 {
      margin-top: -4.5rem !important;
    }
    .mr-md-n10,
    .mx-md-n10 {
      margin-right: -4.5rem !important;
    }
    .mb-md-n10,
    .my-md-n10 {
      margin-bottom: -4.5rem !important;
    }
    .ml-md-n10,
    .mx-md-n10 {
      margin-left: -4.5rem !important;
    }
    .m-md-n11 {
      margin: -5rem !important;
    }
    .mt-md-n11,
    .my-md-n11 {
      margin-top: -5rem !important;
    }
    .mr-md-n11,
    .mx-md-n11 {
      margin-right: -5rem !important;
    }
    .mb-md-n11,
    .my-md-n11 {
      margin-bottom: -5rem !important;
    }
    .ml-md-n11,
    .mx-md-n11 {
      margin-left: -5rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 2rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 2rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 2rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 2rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 2rem !important;
    }
    .m-lg-6 {
      margin: 2.5rem !important;
    }
    .mt-lg-6,
    .my-lg-6 {
      margin-top: 2.5rem !important;
    }
    .mr-lg-6,
    .mx-lg-6 {
      margin-right: 2.5rem !important;
    }
    .mb-lg-6,
    .my-lg-6 {
      margin-bottom: 2.5rem !important;
    }
    .ml-lg-6,
    .mx-lg-6 {
      margin-left: 2.5rem !important;
    }
    .m-lg-7 {
      margin: 3rem !important;
    }
    .mt-lg-7,
    .my-lg-7 {
      margin-top: 3rem !important;
    }
    .mr-lg-7,
    .mx-lg-7 {
      margin-right: 3rem !important;
    }
    .mb-lg-7,
    .my-lg-7 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-7,
    .mx-lg-7 {
      margin-left: 3rem !important;
    }
    .m-lg-8 {
      margin: 3.5rem !important;
    }
    .mt-lg-8,
    .my-lg-8 {
      margin-top: 3.5rem !important;
    }
    .mr-lg-8,
    .mx-lg-8 {
      margin-right: 3.5rem !important;
    }
    .mb-lg-8,
    .my-lg-8 {
      margin-bottom: 3.5rem !important;
    }
    .ml-lg-8,
    .mx-lg-8 {
      margin-left: 3.5rem !important;
    }
    .m-lg-9 {
      margin: 4rem !important;
    }
    .mt-lg-9,
    .my-lg-9 {
      margin-top: 4rem !important;
    }
    .mr-lg-9,
    .mx-lg-9 {
      margin-right: 4rem !important;
    }
    .mb-lg-9,
    .my-lg-9 {
      margin-bottom: 4rem !important;
    }
    .ml-lg-9,
    .mx-lg-9 {
      margin-left: 4rem !important;
    }
    .m-lg-10 {
      margin: 4.5rem !important;
    }
    .mt-lg-10,
    .my-lg-10 {
      margin-top: 4.5rem !important;
    }
    .mr-lg-10,
    .mx-lg-10 {
      margin-right: 4.5rem !important;
    }
    .mb-lg-10,
    .my-lg-10 {
      margin-bottom: 4.5rem !important;
    }
    .ml-lg-10,
    .mx-lg-10 {
      margin-left: 4.5rem !important;
    }
    .m-lg-11 {
      margin: 5rem !important;
    }
    .mt-lg-11,
    .my-lg-11 {
      margin-top: 5rem !important;
    }
    .mr-lg-11,
    .mx-lg-11 {
      margin-right: 5rem !important;
    }
    .mb-lg-11,
    .my-lg-11 {
      margin-bottom: 5rem !important;
    }
    .ml-lg-11,
    .mx-lg-11 {
      margin-left: 5rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 2rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 2rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 2rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 2rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 2rem !important;
    }
    .p-lg-6 {
      padding: 2.5rem !important;
    }
    .pt-lg-6,
    .py-lg-6 {
      padding-top: 2.5rem !important;
    }
    .pr-lg-6,
    .px-lg-6 {
      padding-right: 2.5rem !important;
    }
    .pb-lg-6,
    .py-lg-6 {
      padding-bottom: 2.5rem !important;
    }
    .pl-lg-6,
    .px-lg-6 {
      padding-left: 2.5rem !important;
    }
    .p-lg-7 {
      padding: 3rem !important;
    }
    .pt-lg-7,
    .py-lg-7 {
      padding-top: 3rem !important;
    }
    .pr-lg-7,
    .px-lg-7 {
      padding-right: 3rem !important;
    }
    .pb-lg-7,
    .py-lg-7 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-7,
    .px-lg-7 {
      padding-left: 3rem !important;
    }
    .p-lg-8 {
      padding: 3.5rem !important;
    }
    .pt-lg-8,
    .py-lg-8 {
      padding-top: 3.5rem !important;
    }
    .pr-lg-8,
    .px-lg-8 {
      padding-right: 3.5rem !important;
    }
    .pb-lg-8,
    .py-lg-8 {
      padding-bottom: 3.5rem !important;
    }
    .pl-lg-8,
    .px-lg-8 {
      padding-left: 3.5rem !important;
    }
    .p-lg-9 {
      padding: 4rem !important;
    }
    .pt-lg-9,
    .py-lg-9 {
      padding-top: 4rem !important;
    }
    .pr-lg-9,
    .px-lg-9 {
      padding-right: 4rem !important;
    }
    .pb-lg-9,
    .py-lg-9 {
      padding-bottom: 4rem !important;
    }
    .pl-lg-9,
    .px-lg-9 {
      padding-left: 4rem !important;
    }
    .p-lg-10 {
      padding: 4.5rem !important;
    }
    .pt-lg-10,
    .py-lg-10 {
      padding-top: 4.5rem !important;
    }
    .pr-lg-10,
    .px-lg-10 {
      padding-right: 4.5rem !important;
    }
    .pb-lg-10,
    .py-lg-10 {
      padding-bottom: 4.5rem !important;
    }
    .pl-lg-10,
    .px-lg-10 {
      padding-left: 4.5rem !important;
    }
    .p-lg-11 {
      padding: 5rem !important;
    }
    .pt-lg-11,
    .py-lg-11 {
      padding-top: 5rem !important;
    }
    .pr-lg-11,
    .px-lg-11 {
      padding-right: 5rem !important;
    }
    .pb-lg-11,
    .py-lg-11 {
      padding-bottom: 5rem !important;
    }
    .pl-lg-11,
    .px-lg-11 {
      padding-left: 5rem !important;
    }
    .m-lg-n1 {
      margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
      margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
      margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
      margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
      margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
      margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
      margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
      margin-left: -1rem !important;
    }
    .m-lg-n4 {
      margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
      margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
      margin: -2rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
      margin-top: -2rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
      margin-right: -2rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
      margin-bottom: -2rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
      margin-left: -2rem !important;
    }
    .m-lg-n6 {
      margin: -2.5rem !important;
    }
    .mt-lg-n6,
    .my-lg-n6 {
      margin-top: -2.5rem !important;
    }
    .mr-lg-n6,
    .mx-lg-n6 {
      margin-right: -2.5rem !important;
    }
    .mb-lg-n6,
    .my-lg-n6 {
      margin-bottom: -2.5rem !important;
    }
    .ml-lg-n6,
    .mx-lg-n6 {
      margin-left: -2.5rem !important;
    }
    .m-lg-n7 {
      margin: -3rem !important;
    }
    .mt-lg-n7,
    .my-lg-n7 {
      margin-top: -3rem !important;
    }
    .mr-lg-n7,
    .mx-lg-n7 {
      margin-right: -3rem !important;
    }
    .mb-lg-n7,
    .my-lg-n7 {
      margin-bottom: -3rem !important;
    }
    .ml-lg-n7,
    .mx-lg-n7 {
      margin-left: -3rem !important;
    }
    .m-lg-n8 {
      margin: -3.5rem !important;
    }
    .mt-lg-n8,
    .my-lg-n8 {
      margin-top: -3.5rem !important;
    }
    .mr-lg-n8,
    .mx-lg-n8 {
      margin-right: -3.5rem !important;
    }
    .mb-lg-n8,
    .my-lg-n8 {
      margin-bottom: -3.5rem !important;
    }
    .ml-lg-n8,
    .mx-lg-n8 {
      margin-left: -3.5rem !important;
    }
    .m-lg-n9 {
      margin: -4rem !important;
    }
    .mt-lg-n9,
    .my-lg-n9 {
      margin-top: -4rem !important;
    }
    .mr-lg-n9,
    .mx-lg-n9 {
      margin-right: -4rem !important;
    }
    .mb-lg-n9,
    .my-lg-n9 {
      margin-bottom: -4rem !important;
    }
    .ml-lg-n9,
    .mx-lg-n9 {
      margin-left: -4rem !important;
    }
    .m-lg-n10 {
      margin: -4.5rem !important;
    }
    .mt-lg-n10,
    .my-lg-n10 {
      margin-top: -4.5rem !important;
    }
    .mr-lg-n10,
    .mx-lg-n10 {
      margin-right: -4.5rem !important;
    }
    .mb-lg-n10,
    .my-lg-n10 {
      margin-bottom: -4.5rem !important;
    }
    .ml-lg-n10,
    .mx-lg-n10 {
      margin-left: -4.5rem !important;
    }
    .m-lg-n11 {
      margin: -5rem !important;
    }
    .mt-lg-n11,
    .my-lg-n11 {
      margin-top: -5rem !important;
    }
    .mr-lg-n11,
    .mx-lg-n11 {
      margin-right: -5rem !important;
    }
    .mb-lg-n11,
    .my-lg-n11 {
      margin-bottom: -5rem !important;
    }
    .ml-lg-n11,
    .mx-lg-n11 {
      margin-left: -5rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 2rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 2rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 2rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 2rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 2rem !important;
    }
    .m-xl-6 {
      margin: 2.5rem !important;
    }
    .mt-xl-6,
    .my-xl-6 {
      margin-top: 2.5rem !important;
    }
    .mr-xl-6,
    .mx-xl-6 {
      margin-right: 2.5rem !important;
    }
    .mb-xl-6,
    .my-xl-6 {
      margin-bottom: 2.5rem !important;
    }
    .ml-xl-6,
    .mx-xl-6 {
      margin-left: 2.5rem !important;
    }
    .m-xl-7 {
      margin: 3rem !important;
    }
    .mt-xl-7,
    .my-xl-7 {
      margin-top: 3rem !important;
    }
    .mr-xl-7,
    .mx-xl-7 {
      margin-right: 3rem !important;
    }
    .mb-xl-7,
    .my-xl-7 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-7,
    .mx-xl-7 {
      margin-left: 3rem !important;
    }
    .m-xl-8 {
      margin: 3.5rem !important;
    }
    .mt-xl-8,
    .my-xl-8 {
      margin-top: 3.5rem !important;
    }
    .mr-xl-8,
    .mx-xl-8 {
      margin-right: 3.5rem !important;
    }
    .mb-xl-8,
    .my-xl-8 {
      margin-bottom: 3.5rem !important;
    }
    .ml-xl-8,
    .mx-xl-8 {
      margin-left: 3.5rem !important;
    }
    .m-xl-9 {
      margin: 4rem !important;
    }
    .mt-xl-9,
    .my-xl-9 {
      margin-top: 4rem !important;
    }
    .mr-xl-9,
    .mx-xl-9 {
      margin-right: 4rem !important;
    }
    .mb-xl-9,
    .my-xl-9 {
      margin-bottom: 4rem !important;
    }
    .ml-xl-9,
    .mx-xl-9 {
      margin-left: 4rem !important;
    }
    .m-xl-10 {
      margin: 4.5rem !important;
    }
    .mt-xl-10,
    .my-xl-10 {
      margin-top: 4.5rem !important;
    }
    .mr-xl-10,
    .mx-xl-10 {
      margin-right: 4.5rem !important;
    }
    .mb-xl-10,
    .my-xl-10 {
      margin-bottom: 4.5rem !important;
    }
    .ml-xl-10,
    .mx-xl-10 {
      margin-left: 4.5rem !important;
    }
    .m-xl-11 {
      margin: 5rem !important;
    }
    .mt-xl-11,
    .my-xl-11 {
      margin-top: 5rem !important;
    }
    .mr-xl-11,
    .mx-xl-11 {
      margin-right: 5rem !important;
    }
    .mb-xl-11,
    .my-xl-11 {
      margin-bottom: 5rem !important;
    }
    .ml-xl-11,
    .mx-xl-11 {
      margin-left: 5rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 2rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 2rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 2rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 2rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 2rem !important;
    }
    .p-xl-6 {
      padding: 2.5rem !important;
    }
    .pt-xl-6,
    .py-xl-6 {
      padding-top: 2.5rem !important;
    }
    .pr-xl-6,
    .px-xl-6 {
      padding-right: 2.5rem !important;
    }
    .pb-xl-6,
    .py-xl-6 {
      padding-bottom: 2.5rem !important;
    }
    .pl-xl-6,
    .px-xl-6 {
      padding-left: 2.5rem !important;
    }
    .p-xl-7 {
      padding: 3rem !important;
    }
    .pt-xl-7,
    .py-xl-7 {
      padding-top: 3rem !important;
    }
    .pr-xl-7,
    .px-xl-7 {
      padding-right: 3rem !important;
    }
    .pb-xl-7,
    .py-xl-7 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-7,
    .px-xl-7 {
      padding-left: 3rem !important;
    }
    .p-xl-8 {
      padding: 3.5rem !important;
    }
    .pt-xl-8,
    .py-xl-8 {
      padding-top: 3.5rem !important;
    }
    .pr-xl-8,
    .px-xl-8 {
      padding-right: 3.5rem !important;
    }
    .pb-xl-8,
    .py-xl-8 {
      padding-bottom: 3.5rem !important;
    }
    .pl-xl-8,
    .px-xl-8 {
      padding-left: 3.5rem !important;
    }
    .p-xl-9 {
      padding: 4rem !important;
    }
    .pt-xl-9,
    .py-xl-9 {
      padding-top: 4rem !important;
    }
    .pr-xl-9,
    .px-xl-9 {
      padding-right: 4rem !important;
    }
    .pb-xl-9,
    .py-xl-9 {
      padding-bottom: 4rem !important;
    }
    .pl-xl-9,
    .px-xl-9 {
      padding-left: 4rem !important;
    }
    .p-xl-10 {
      padding: 4.5rem !important;
    }
    .pt-xl-10,
    .py-xl-10 {
      padding-top: 4.5rem !important;
    }
    .pr-xl-10,
    .px-xl-10 {
      padding-right: 4.5rem !important;
    }
    .pb-xl-10,
    .py-xl-10 {
      padding-bottom: 4.5rem !important;
    }
    .pl-xl-10,
    .px-xl-10 {
      padding-left: 4.5rem !important;
    }
    .p-xl-11 {
      padding: 5rem !important;
    }
    .pt-xl-11,
    .py-xl-11 {
      padding-top: 5rem !important;
    }
    .pr-xl-11,
    .px-xl-11 {
      padding-right: 5rem !important;
    }
    .pb-xl-11,
    .py-xl-11 {
      padding-bottom: 5rem !important;
    }
    .pl-xl-11,
    .px-xl-11 {
      padding-left: 5rem !important;
    }
    .m-xl-n1 {
      margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
      margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
      margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
      margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
      margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
      margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
      margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
      margin-left: -1rem !important;
    }
    .m-xl-n4 {
      margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
      margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
      margin: -2rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
      margin-top: -2rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
      margin-right: -2rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
      margin-bottom: -2rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
      margin-left: -2rem !important;
    }
    .m-xl-n6 {
      margin: -2.5rem !important;
    }
    .mt-xl-n6,
    .my-xl-n6 {
      margin-top: -2.5rem !important;
    }
    .mr-xl-n6,
    .mx-xl-n6 {
      margin-right: -2.5rem !important;
    }
    .mb-xl-n6,
    .my-xl-n6 {
      margin-bottom: -2.5rem !important;
    }
    .ml-xl-n6,
    .mx-xl-n6 {
      margin-left: -2.5rem !important;
    }
    .m-xl-n7 {
      margin: -3rem !important;
    }
    .mt-xl-n7,
    .my-xl-n7 {
      margin-top: -3rem !important;
    }
    .mr-xl-n7,
    .mx-xl-n7 {
      margin-right: -3rem !important;
    }
    .mb-xl-n7,
    .my-xl-n7 {
      margin-bottom: -3rem !important;
    }
    .ml-xl-n7,
    .mx-xl-n7 {
      margin-left: -3rem !important;
    }
    .m-xl-n8 {
      margin: -3.5rem !important;
    }
    .mt-xl-n8,
    .my-xl-n8 {
      margin-top: -3.5rem !important;
    }
    .mr-xl-n8,
    .mx-xl-n8 {
      margin-right: -3.5rem !important;
    }
    .mb-xl-n8,
    .my-xl-n8 {
      margin-bottom: -3.5rem !important;
    }
    .ml-xl-n8,
    .mx-xl-n8 {
      margin-left: -3.5rem !important;
    }
    .m-xl-n9 {
      margin: -4rem !important;
    }
    .mt-xl-n9,
    .my-xl-n9 {
      margin-top: -4rem !important;
    }
    .mr-xl-n9,
    .mx-xl-n9 {
      margin-right: -4rem !important;
    }
    .mb-xl-n9,
    .my-xl-n9 {
      margin-bottom: -4rem !important;
    }
    .ml-xl-n9,
    .mx-xl-n9 {
      margin-left: -4rem !important;
    }
    .m-xl-n10 {
      margin: -4.5rem !important;
    }
    .mt-xl-n10,
    .my-xl-n10 {
      margin-top: -4.5rem !important;
    }
    .mr-xl-n10,
    .mx-xl-n10 {
      margin-right: -4.5rem !important;
    }
    .mb-xl-n10,
    .my-xl-n10 {
      margin-bottom: -4.5rem !important;
    }
    .ml-xl-n10,
    .mx-xl-n10 {
      margin-left: -4.5rem !important;
    }
    .m-xl-n11 {
      margin: -5rem !important;
    }
    .mt-xl-n11,
    .my-xl-n11 {
      margin-top: -5rem !important;
    }
    .mr-xl-n11,
    .mx-xl-n11 {
      margin-right: -5rem !important;
    }
    .mb-xl-n11,
    .my-xl-n11 {
      margin-bottom: -5rem !important;
    }
    .ml-xl-n11,
    .mx-xl-n11 {
      margin-left: -5rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: rgba(0, 0, 0, 0);
  }
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
      monospace !important;
  }
  .text-justify {
    text-align: justify !important;
  }
  .text-wrap {
    white-space: normal !important;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .font-weight-light {
    font-weight: 300 !important;
  }
  .font-weight-lighter {
    font-weight: lighter !important;
  }
  .font-weight-normal {
    font-weight: 400 !important;
  }
  .font-weight-bold {
    font-weight: 600 !important;
  }
  .font-weight-bolder {
    font-weight: bolder !important;
  }
  .font-italic {
    font-style: italic !important;
  }
  .text-white {
    color: #fff !important;
  }
  .text-primary {
    color: #377dff !important;
  }
  a.text-primary:focus,
  a.text-primary:hover {
    color: #0052ea !important;
  }
  .text-secondary {
    color: #71869d !important;
  }
  a.text-secondary:focus,
  a.text-secondary:hover {
    color: #4f6072 !important;
  }
  .text-success {
    color: #00c9a7 !important;
  }
  a.text-success:focus,
  a.text-success:hover {
    color: #007d67 !important;
  }
  .text-info {
    color: #09a5be !important;
  }
  a.text-info:focus,
  a.text-info:hover {
    color: #066675 !important;
  }
  .text-warning {
    color: #f5ca99 !important;
  }
  a.text-warning:focus,
  a.text-warning:hover {
    color: #eea653 !important;
  }
  .text-danger {
    color: #ed4c78 !important;
  }
  a.text-danger:focus,
  a.text-danger:hover {
    color: #d7164a !important;
  }
  .text-light {
    color: #f7faff !important;
  }
  a.text-light:focus,
  a.text-light:hover {
    color: #abcaff !important;
  }
  .text-dark {
    color: #21325b !important;
  }
  a.text-dark:focus,
  a.text-dark:hover {
    color: #0d1323 !important;
  }
  .text-indigo {
    color: #2d1582 !important;
  }
  a.text-indigo:focus,
  a.text-indigo:hover {
    color: #160a40 !important;
  }
  .text-body {
    color: #677788 !important;
  }
  .text-muted {
    color: #8c98a4 !important;
  }
  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .text-decoration-none {
    text-decoration: none !important;
  }
  .text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
  }
  .text-reset {
    color: inherit !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  @media print {
    *,
    ::after,
    ::before {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: ' (' attr(title) ')';
    }
    pre {
      white-space: pre-wrap !important;
    }
    blockquote,
    pre {
      border: 0.0625rem solid #97a4af;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    img,
    tr {
      page-break-inside: avoid;
    }
    h2,
    h3,
    p {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 0.0625rem solid #000;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #fff !important;
    }
    .table-bordered td,
    .table-bordered th {
      border: 1px solid #e7eaf3 !important;
    }
    .table-dark {
      color: inherit;
    }
    .table-dark tbody + tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
      border-color: #e7eaf3;
    }
    .table .thead-dark th {
      color: inherit;
      border-color: #e7eaf3;
    }
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .w-15 {
    width: 15% !important;
  }
  .w-20 {
    width: 20% !important;
  }
  .w-30 {
    width: 30% !important;
  }
  .w-35 {
    width: 35% !important;
  }
  .w-40 {
    width: 40% !important;
  }
  .w-60 {
    width: 60% !important;
  }
  .w-65 {
    width: 65% !important;
  }
  .w-80 {
    width: 80% !important;
  }
  .w-85 {
    width: 85% !important;
  }
  .w-90 {
    width: 90% !important;
  }
  @media (min-width: 576px) {
    .w-sm-25 {
      width: 25% !important;
    }
    .w-sm-50 {
      width: 50% !important;
    }
    .w-sm-75 {
      width: 75% !important;
    }
    .w-sm-100 {
      width: 100% !important;
    }
    .w-sm-auto {
      width: auto !important;
    }
    .w-sm-15 {
      width: 15% !important;
    }
    .w-sm-20 {
      width: 20% !important;
    }
    .w-sm-30 {
      width: 30% !important;
    }
    .w-sm-35 {
      width: 35% !important;
    }
    .w-sm-40 {
      width: 40% !important;
    }
    .w-sm-60 {
      width: 60% !important;
    }
    .w-sm-65 {
      width: 65% !important;
    }
    .w-sm-80 {
      width: 80% !important;
    }
    .w-sm-85 {
      width: 85% !important;
    }
    .w-sm-90 {
      width: 90% !important;
    }
  }
  @media (min-width: 768px) {
    .w-md-25 {
      width: 25% !important;
    }
    .w-md-50 {
      width: 50% !important;
    }
    .w-md-75 {
      width: 75% !important;
    }
    .w-md-100 {
      width: 100% !important;
    }
    .w-md-auto {
      width: auto !important;
    }
    .w-md-15 {
      width: 15% !important;
    }
    .w-md-20 {
      width: 20% !important;
    }
    .w-md-30 {
      width: 30% !important;
    }
    .w-md-35 {
      width: 35% !important;
    }
    .w-md-40 {
      width: 40% !important;
    }
    .w-md-60 {
      width: 60% !important;
    }
    .w-md-65 {
      width: 65% !important;
    }
    .w-md-80 {
      width: 80% !important;
    }
    .w-md-85 {
      width: 85% !important;
    }
    .w-md-90 {
      width: 90% !important;
    }
  }
  @media (min-width: 992px) {
    .w-lg-25 {
      width: 25% !important;
    }
    .w-lg-50 {
      width: 50% !important;
    }
    .w-lg-75 {
      width: 75% !important;
    }
    .w-lg-100 {
      width: 100% !important;
    }
    .w-lg-auto {
      width: auto !important;
    }
    .w-lg-15 {
      width: 15% !important;
    }
    .w-lg-20 {
      width: 20% !important;
    }
    .w-lg-30 {
      width: 30% !important;
    }
    .w-lg-35 {
      width: 35% !important;
    }
    .w-lg-40 {
      width: 40% !important;
    }
    .w-lg-60 {
      width: 60% !important;
    }
    .w-lg-65 {
      width: 65% !important;
    }
    .w-lg-80 {
      width: 80% !important;
    }
    .w-lg-85 {
      width: 85% !important;
    }
    .w-lg-90 {
      width: 90% !important;
    }
  }
  @media (min-width: 1200px) {
    .w-xl-25 {
      width: 25% !important;
    }
    .w-xl-50 {
      width: 50% !important;
    }
    .w-xl-75 {
      width: 75% !important;
    }
    .w-xl-100 {
      width: 100% !important;
    }
    .w-xl-auto {
      width: auto !important;
    }
    .w-xl-15 {
      width: 15% !important;
    }
    .w-xl-20 {
      width: 20% !important;
    }
    .w-xl-30 {
      width: 30% !important;
    }
    .w-xl-35 {
      width: 35% !important;
    }
    .w-xl-40 {
      width: 40% !important;
    }
    .w-xl-60 {
      width: 60% !important;
    }
    .w-xl-65 {
      width: 65% !important;
    }
    .w-xl-80 {
      width: 80% !important;
    }
    .w-xl-85 {
      width: 85% !important;
    }
    .w-xl-90 {
      width: 90% !important;
    }
  }
  .space-0,
  .space-top-0 {
    padding-top: 0 !important;
  }
  .space-0,
  .space-bottom-0 {
    padding-bottom: 0 !important;
  }
  .space-1,
  .space-top-1 {
    padding-top: 2rem !important;
  }
  .space-1,
  .space-bottom-1 {
    padding-bottom: 2rem !important;
  }
  .space-2,
  .space-top-2 {
    padding-top: 4rem !important;
  }
  .space-2,
  .space-bottom-2 {
    padding-bottom: 4rem !important;
  }
  .space-3,
  .space-top-3 {
    padding-top: 8rem !important;
  }
  .space-3,
  .space-bottom-3 {
    padding-bottom: 8rem !important;
  }
  .space-4,
  .space-top-4 {
    padding-top: 12.5rem !important;
  }
  .space-4,
  .space-bottom-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-5,
  .space-top-5 {
    padding-top: 15rem !important;
  }
  .space-5,
  .space-bottom-5 {
    padding-bottom: 15rem !important;
  }
  @media (min-width: 576px) {
    .space-sm-0,
    .space-top-sm-0 {
      padding-top: 0 !important;
    }
    .space-bottom-sm-0,
    .space-sm-0 {
      padding-bottom: 0 !important;
    }
    .space-sm-1,
    .space-top-sm-1 {
      padding-top: 2rem !important;
    }
    .space-bottom-sm-1,
    .space-sm-1 {
      padding-bottom: 2rem !important;
    }
    .space-sm-2,
    .space-top-sm-2 {
      padding-top: 4rem !important;
    }
    .space-bottom-sm-2,
    .space-sm-2 {
      padding-bottom: 4rem !important;
    }
    .space-sm-3,
    .space-top-sm-3 {
      padding-top: 8rem !important;
    }
    .space-bottom-sm-3,
    .space-sm-3 {
      padding-bottom: 8rem !important;
    }
    .space-sm-4,
    .space-top-sm-4 {
      padding-top: 12.5rem !important;
    }
    .space-bottom-sm-4,
    .space-sm-4 {
      padding-bottom: 12.5rem !important;
    }
    .space-sm-5,
    .space-top-sm-5 {
      padding-top: 15rem !important;
    }
    .space-bottom-sm-5,
    .space-sm-5 {
      padding-bottom: 15rem !important;
    }
  }
  @media (min-width: 768px) {
    .space-md-0,
    .space-top-md-0 {
      padding-top: 0 !important;
    }
    .space-bottom-md-0,
    .space-md-0 {
      padding-bottom: 0 !important;
    }
    .space-md-1,
    .space-top-md-1 {
      padding-top: 2rem !important;
    }
    .space-bottom-md-1,
    .space-md-1 {
      padding-bottom: 2rem !important;
    }
    .space-md-2,
    .space-top-md-2 {
      padding-top: 4rem !important;
    }
    .space-bottom-md-2,
    .space-md-2 {
      padding-bottom: 4rem !important;
    }
    .space-md-3,
    .space-top-md-3 {
      padding-top: 8rem !important;
    }
    .space-bottom-md-3,
    .space-md-3 {
      padding-bottom: 8rem !important;
    }
    .space-md-4,
    .space-top-md-4 {
      padding-top: 12.5rem !important;
    }
    .space-bottom-md-4,
    .space-md-4 {
      padding-bottom: 12.5rem !important;
    }
    .space-md-5,
    .space-top-md-5 {
      padding-top: 15rem !important;
    }
    .space-bottom-md-5,
    .space-md-5 {
      padding-bottom: 15rem !important;
    }
  }
  @media (min-width: 992px) {
    .space-lg-0,
    .space-top-lg-0 {
      padding-top: 0 !important;
    }
    .space-bottom-lg-0,
    .space-lg-0 {
      padding-bottom: 0 !important;
    }
    .space-lg-1,
    .space-top-lg-1 {
      padding-top: 2rem !important;
    }
    .space-bottom-lg-1,
    .space-lg-1 {
      padding-bottom: 2rem !important;
    }
    .space-lg-2,
    .space-top-lg-2 {
      padding-top: 4rem !important;
    }
    .space-bottom-lg-2,
    .space-lg-2 {
      padding-bottom: 4rem !important;
    }
    .space-lg-3,
    .space-top-lg-3 {
      padding-top: 8rem !important;
    }
    .space-bottom-lg-3,
    .space-lg-3 {
      padding-bottom: 8rem !important;
    }
    .space-lg-4,
    .space-top-lg-4 {
      padding-top: 12.5rem !important;
    }
    .space-bottom-lg-4,
    .space-lg-4 {
      padding-bottom: 12.5rem !important;
    }
    .space-lg-5,
    .space-top-lg-5 {
      padding-top: 15rem !important;
    }
    .space-bottom-lg-5,
    .space-lg-5 {
      padding-bottom: 15rem !important;
    }
  }
  @media (min-width: 1200px) {
    .space-top-xl-0,
    .space-xl-0 {
      padding-top: 0 !important;
    }
    .space-bottom-xl-0,
    .space-xl-0 {
      padding-bottom: 0 !important;
    }
    .space-top-xl-1,
    .space-xl-1 {
      padding-top: 2rem !important;
    }
    .space-bottom-xl-1,
    .space-xl-1 {
      padding-bottom: 2rem !important;
    }
    .space-top-xl-2,
    .space-xl-2 {
      padding-top: 4rem !important;
    }
    .space-bottom-xl-2,
    .space-xl-2 {
      padding-bottom: 4rem !important;
    }
    .space-top-xl-3,
    .space-xl-3 {
      padding-top: 8rem !important;
    }
    .space-bottom-xl-3,
    .space-xl-3 {
      padding-bottom: 8rem !important;
    }
    .space-top-xl-4,
    .space-xl-4 {
      padding-top: 12.5rem !important;
    }
    .space-bottom-xl-4,
    .space-xl-4 {
      padding-bottom: 12.5rem !important;
    }
    .space-top-xl-5,
    .space-xl-5 {
      padding-top: 15rem !important;
    }
    .space-bottom-xl-5,
    .space-xl-5 {
      padding-bottom: 15rem !important;
    }
  }
  main {
    position: relative;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #1e2022;
  }
  figure {
    margin-bottom: 0;
  }
  svg {
    margin-bottom: -8px;
    vertical-align: baseline;
  }
  sup {
    right: -0.5rem;
  }
  ::-moz-selection {
    color: #fff;
    background-color: #377dff;
  }
  ::selection {
    color: #fff;
    background-color: #377dff;
  }
  .bg-primary ::-moz-selection {
    color: #377dff;
    background-color: #fff;
  }
  .bg-primary ::selection {
    color: #377dff;
    background-color: #fff;
  }
  :focus,
  a:focus,
  button:focus {
    outline: 0;
  }
  .form-control:focus {
    box-shadow: 0 0 0 0 transparent;
  }
  .btn.focus,
  .btn:focus,
  .btn:not(:disabled):not(.disabled).active:focus,
  .btn:not(:disabled):not(.disabled):active:focus,
  .btn:not([disabled]):not(.disabled).active,
  .btn:not([disabled]):not(.disabled):active {
    box-shadow: 0 0 0 0 transparent;
  }
  .btn:not(:disabled):not(.disabled).active:focus,
  .btn:not(:disabled):not(.disabled):active:focus,
  .show > .btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
  }
  .custom-select:focus,
  .page-link:focus {
    box-shadow: 0 0 0 0 transparent;
  }
  .custom-control-input:active ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 transparent;
  }
  .custom-file-input:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0 transparent;
  }
  .bootstrap-select .dropdown-toggle:focus {
    outline: 0 !important;
  }
  @media print {
    .btn,
    footer,
    header {
      display: none;
    }
  }
  .skippy {
    display: none;
  }
  .list-article > li:not(:last-child) {
    padding-bottom: 1rem;
  }
  .list-article .list-article > li:first-child {
    padding-top: 1rem;
  }
  .list-article .list-article > li:last-child {
    padding-bottom: 0;
  }
  .list-sm-article > li:not(:last-child) {
    padding-bottom: 0.5rem;
  }
  .list-sm-article .list-sm-article > li:first-child {
    padding-top: 0.5rem;
  }
  .list-sm-article .list-sm-article > li:last-child {
    padding-bottom: 0;
  }
  .list-lg-article > li:not(:last-child) {
    padding-bottom: 2rem;
  }
  .list-lg-article .list-lg-article > li:first-child {
    padding-top: 2rem;
  }
  .list-lg-article .list-lg-article > li:last-child {
    padding-bottom: 0;
  }
  .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .list-group-icon {
    width: 1.25rem;
    text-align: center;
    margin-right: 0.25rem;
  }
  .list-group-no-gutters .list-group-item {
    padding-right: 0;
    padding-left: 0;
  }
  .list-group-item-light {
    background-color: #f7faff;
  }
  .list-group-lg .list-group-icon {
    font-size: 1.25rem;
    width: 2.5rem;
    margin-right: 0.5rem;
  }
  .list-separator {
    margin-bottom: 0;
  }
  .list-separator .list-inline-item {
    position: relative;
    margin-left: 0;
    margin-right: -0.25rem;
  }
  .list-separator .list-inline-item:not(:last-child) {
    padding-right: 2rem;
  }
  .list-separator .list-inline-item:not(:last-child)::after {
    position: absolute;
    top: calc(50% - 0.15625rem);
    right: 0.84375rem;
    width: 0.3125rem;
    height: 0.3125rem;
    background-color: #bdc5d1;
    border-radius: 0.3125rem;
    content: '';
  }
  .list-separator .list-separator-link {
    color: #677788;
  }
  .list-separator .list-separator-link:hover {
    color: #0052ea;
  }
  .link {
    font-size: 0.875rem;
    display: inline-flex;
  }
  .link-icon {
    position: relative;
    line-height: 1;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: 1.375rem;
    height: 1.375rem;
    font-size: 1rem;
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
    border-radius: 50%;
    transition: 0.3s;
  }
  .link:hover .link-icon {
    transform: rotate(90deg);
  }
  .link-collapse[aria-expanded='false'] .link-collapse-default {
    display: inline-block;
  }
  .link-collapse[aria-expanded='false'] .link-collapse-active {
    display: none;
  }
  .link-collapse[aria-expanded='true'] .link-collapse-default {
    display: none;
  }
  .link-collapse[aria-expanded='true'] .link-collapse-active {
    display: inline-block;
  }
  .link-underline {
    color: #8c98a4;
    border-bottom: 0.0625rem dashed #97a4af;
  }
  .link-underline:hover {
    border-color: #0052ea;
  }
  .link-underline-toggle-default {
    display: inline-block;
  }
  .link-underline-toggle-toggled {
    display: none;
  }
  .link-underline.toggled .link-underline-toggle-default {
    display: none;
  }
  .link-underline.toggled .link-underline-toggle-toggled {
    display: inline-block;
  }
  .link-underline-light {
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 0.0625rem dashed rgba(255, 255, 255, 0.7);
  }
  .link-underline-light:hover {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.7);
  }
  .header {
    position: relative;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
  }
  .header-section {
    position: relative;
    z-index: 1;
    background-color: #fff;
  }
  .header-section-divider {
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
  }
  .header-box-shadow .header-section {
    box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
  }
  .header-box-shadow-on-scroll.scrolled .header-section {
    box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
  }
  .navbar-brand,
  .navbar-brand > img {
    width: 7.5rem;
  }
  .navbar-toggler {
    background-color: #f8fafd;
  }
  .navbar-toggler .navbar-toggler-default {
    display: flex;
  }
  .navbar-toggler .navbar-toggler-toggled {
    display: none;
  }
  .navbar-toggler[aria-expanded='true'] .navbar-toggler-default {
    display: none;
  }
  .navbar-toggler[aria-expanded='true'] .navbar-toggler-toggled {
    display: flex;
  }
  .header .navbar-nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-nav-item:focus .nav-link,
  .navbar-nav-item:hover .nav-link {
    color: #377dff;
  }
  .header .nav-link.active,
  .header .navbar-nav-item.active .nav-link {
    color: #377dff;
  }
  .header .nav-link {
    color: #677788;
  }
  .header .nav-link-toggle {
    display: flex;
    align-items: center;
  }
  .header .nav-link-toggle::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    content: '';
    margin-left: 0.5rem;
    transition: 0.3s ease;
  }
  .header .navbar-nav .dropdown-menu {
    margin-top: -0.125rem;
  }
  .header .navbar-nav .dropdown-menu.hs-mega-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .header .navbar-nav .dropdown-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .header .dropdown-item-toggle {
    display: flex;
    align-items: center;
  }
  .header .dropdown-item-toggle::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    content: '';
    margin-left: auto;
    transition: 0.3s ease;
  }
  .header .hs-mega-menu .dropdown-item {
    padding-right: 0;
    padding-left: 0;
  }
  [class*='navbar-expand'] .dropdown-item {
    background-color: transparent;
  }
  [class*='navbar-expand'] .dropdown-item.active:not(:focus):not(:active),
  [class*='navbar-expand'] .dropdown-item:hover {
    color: #377dff;
    background-color: transparent;
  }
  .navbar-promo {
    background-color: #f8fafd;
  }
  .navbar-promo-inner {
    color: inherit;
    padding: 2rem 2rem;
  }
  .navbar-promo-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .navbar-promo-item ~ .navbar-promo-item {
    border-top: 0.0625rem solid #e7eaf3;
  }
  .navbar-promo-link {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .navbar-promo-link.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  .navbar-promo-link:hover .navbar-promo-title {
    color: #377dff;
  }
  .navbar-promo-card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
  }
  .navbar-promo-card-deck {
    display: block;
  }
  .navbar-promo-card-deck:not(:last-child) .navbar-promo-item {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
  .navbar-promo-card-deck .navbar-promo-item {
    border-top: none;
  }
  .navbar-promo-card-body {
    flex: 1 1 auto;
  }
  .navbar-promo-icon {
    width: 100%;
    max-width: 2.75rem;
    margin-right: 1rem;
  }
  .navbar-promo-title {
    display: block;
    color: #1e2022;
    font-size: 1rem;
    font-weight: 600;
  }
  .navbar-promo-text {
    display: block;
    font-size: 0.875rem;
    color: #677788;
  }
  .navbar-promo-footer {
    background-color: #f8fafd;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-promo-footer-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-promo-footer-text {
    display: block;
    font-size: 0.8125rem;
  }
  .navbar-promo-footer-ver-divider {
    position: relative;
  }
  .navbar-promo-footer-ver-divider::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-right: 0.0625rem solid #e7eaf3;
    content: '';
  }
  .navbar-banner {
    position: relative;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    border-bottom-left-radius: 0.3125rem;
  }
  .navbar-banner::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(30, 32, 34, 0.5);
    border-bottom-left-radius: 0.3125rem;
    content: '';
  }
  .navbar-banner-content {
    z-index: 1;
    width: 100%;
    text-align: center;
    padding: 2rem 2rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .navbar-product-banner {
    background-color: #f8fafd;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    padding-right: 1.5rem;
  }
  .navbar-product-banner-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .header-invisible {
    display: none;
  }
  .header-moved-up {
    transform: translate3d(0, -100%, 0);
  }
  .header-moved-up.header-hide-topbar {
    transform: translate3d(0, -36px, 0);
  }
  .header-faded {
    opacity: 0;
    visibility: hidden;
  }
  .header-section-hidden {
    position: relative;
  }
  .header[data-hs-header-options*='fixMoment'] {
    transition: 0.3s ease;
  }
  .header.header-untransitioned {
    transition: none;
  }
  .header.js-header-fix-moment {
    position: fixed;
    top: 0;
    bottom: auto;
  }
  .header.js-header-fix-moment .header-hide-content {
    display: none;
  }
  .header-fix-top {
    position: fixed;
  }
  .header.header-fix-top[data-hs-header-options*='effectCompensation'] {
    transition: none;
  }
  @media (min-width: 992px) {
    .header .navbar-body,
    .header .navbar-nav {
      margin-left: auto;
    }
    .left-aligned-navbar .navbar-body,
    .left-aligned-navbar .navbar-nav {
      width: 100%;
      margin-left: 0;
      margin-right: auto;
    }
    .left-aligned-navbar .navbar-brand {
      margin-left: 0;
      margin-right: 3rem;
    }
    .left-aligned-navbar .navbar-nav-last-item {
      margin-left: auto;
    }
    .center-aligned-navbar .navbar-body,
    .center-aligned-navbar .navbar-nav {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 768px) {
    .center-aligned-navbar.header-abs-top-sm .navbar-body,
    .center-aligned-navbar.header-abs-top-sm .navbar-nav {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .navbar-nav-scroll {
    max-width: 100%;
    height: 4.625rem;
    overflow: hidden;
  }
  .navbar-nav-scroll .navbar-nav {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .navbar-nav-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .navbar-expand .navbar-nav-wrap {
    justify-content: space-between;
  }
  .navbar-expand .navbar-nav-wrap-content {
    order: 3;
  }
  .navbar-expand .navbar-nav-wrap-toggler {
    order: 3;
    text-align: right;
  }
  .navbar-expand .navbar-nav-wrap-collapse {
    order: 2;
  }
  @media (min-width: 576px) {
    .navbar-expand-sm .navbar-nav-wrap {
      justify-content: space-between;
    }
    .navbar-expand-sm .navbar-nav-wrap-content {
      order: 3;
    }
    .navbar-expand-sm .navbar-nav-wrap-toggler {
      order: 3;
      text-align: right;
    }
    .navbar-expand-sm .navbar-nav-wrap-collapse {
      order: 2;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md .navbar-nav-wrap {
      justify-content: space-between;
    }
    .navbar-expand-md .navbar-nav-wrap-content {
      order: 3;
    }
    .navbar-expand-md .navbar-nav-wrap-toggler {
      order: 3;
      text-align: right;
    }
    .navbar-expand-md .navbar-nav-wrap-collapse {
      order: 2;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav-wrap {
      justify-content: space-between;
    }
    .navbar-expand-lg .navbar-nav-wrap-content {
      order: 3;
    }
    .navbar-expand-lg .navbar-nav-wrap-toggler {
      order: 3;
      text-align: right;
    }
    .navbar-expand-lg .navbar-nav-wrap-collapse {
      order: 2;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav-wrap {
      justify-content: space-between;
    }
    .navbar-expand-xl .navbar-nav-wrap-content {
      order: 3;
    }
    .navbar-expand-xl .navbar-nav-wrap-toggler {
      order: 3;
      text-align: right;
    }
    .navbar-expand-xl .navbar-nav-wrap-collapse {
      order: 2;
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-nav-wrap {
      justify-content: flex-end;
    }
    .navbar-expand-sm .navbar-nav-wrap-brand {
      margin-right: auto;
    }
    .navbar-expand-sm .navbar-nav-wrap-content {
      text-align: center;
      margin-right: 0.5rem;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-nav-wrap {
      justify-content: flex-end;
    }
    .navbar-expand-md .navbar-nav-wrap-brand {
      margin-right: auto;
    }
    .navbar-expand-md .navbar-nav-wrap-content {
      text-align: center;
      margin-right: 0.5rem;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-nav-wrap {
      justify-content: flex-end;
    }
    .navbar-expand-lg .navbar-nav-wrap-brand {
      margin-right: auto;
    }
    .navbar-expand-lg .navbar-nav-wrap-content {
      text-align: center;
      margin-right: 0.5rem;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-nav-wrap {
      justify-content: flex-end;
    }
    .navbar-expand-xl .navbar-nav-wrap-brand {
      margin-right: auto;
    }
    .navbar-expand-xl .navbar-nav-wrap-content {
      text-align: center;
      margin-right: 0.5rem;
    }
  }
  .navbar-expand .navbar-nav-wrap {
    justify-content: flex-end;
  }
  .navbar-expand .navbar-nav-wrap-brand {
    margin-right: auto;
  }
  .navbar-expand .navbar-nav-wrap-content {
    text-align: center;
    margin-right: 0.5rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .navbar-expand-md .navbar-nav-wrap-brand {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
  }
  .fullscreen-container,
  .fullscreen-overlay {
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  }
  .fullscreen-container {
    position: relative;
    z-index: 1000;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
  }
  .fullscreen-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    background-image: linear-gradient(
      to right,
      rgba(33, 50, 91, 0.9) 0,
      rgba(33, 50, 91, 0.9) 100%
    );
    background-repeat: repeat-x;
    transition-delay: 0.5s;
  }
  .fullscreen-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .fullscreen-body::-webkit-scrollbar {
    width: 0.5rem;
  }
  .fullscreen-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .fullscreen-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .fullscreen.fullscreen-showed {
    visibility: visible;
  }
  .fullscreen.fullscreen-showed .fullscreen-container,
  .fullscreen.fullscreen-showed .fullscreen-overlay {
    opacity: 1;
    visibility: visible;
  }
  .fullscreen.fullscreen-showed .fullscreen-overlay {
    transition-delay: 0s;
  }
  .fullscreen.fullscreen-showed .fullscreen-container {
    transition-delay: 0.5s;
  }
  .header .fullscreen-toggler.navbar-toggler {
    display: inline-flex;
    z-index: 1001;
  }
  .header .fullscreen-toggler.navbar-toggler .navbar-toggler-default {
    display: flex;
  }
  .header .fullscreen-toggler.navbar-toggler .navbar-toggler-toggled {
    display: none;
  }
  .header .fullscreen-toggler.navbar-toggler[aria-pressed='true'] .navbar-toggler-default {
    display: none;
  }
  .header .fullscreen-toggler.navbar-toggler[aria-pressed='true'] .navbar-toggler-toggled {
    display: flex;
  }
  .fullscreen-nav {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
  }
  .fullscreen-nav-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .fullscreen-nav-link {
    display: inline-block;
    font-size: 1.375rem;
    color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  @media (min-width: 992px) {
    .fullscreen-nav-link {
      font-size: 1.75rem;
    }
  }
  .fullscreen-nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .fullscreen-submenu {
    position: relative;
    transition: 0.3s ease-in-out;
  }
  .fullscreen-submenu .fullscreen-submenu .fullscreen-submenu-list {
    margin-left: 1rem;
  }
  .fullscreen-submenu .fullscreen-nav-list {
    border-left: 0.125rem solid rgba(255, 255, 255, 0.6);
    padding-left: 0;
  }
  .fullscreen-submenu-nav-link {
    display: inline-block;
    color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .fullscreen-submenu-nav-link.disabled {
    color: rgba(255, 255, 255, 0.7);
    pointer-events: none;
  }
  .fullscreen-submenu-nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .fullscreen-submenu-list {
    list-style: none;
    border-left: 0.125rem solid rgba(255, 255, 255, 0.6);
    padding-left: 0;
    margin-bottom: 0;
  }
  .fullscreen .fullscreen-submenu-list-link {
    display: inline-block;
    color: rgba(255, 255, 255, 0.7);
  }
  .fullscreen .fullscreen-submenu-list-link:hover {
    color: #fff;
  }
  .header-bg-transparent:not(.js-header-fix-moment)
    .search-push-top[style*='display: block']
    + .header-section {
    background-color: #fff;
  }
  .header-bg-transparent:not(.js-header-fix-moment) .header-section {
    background-color: transparent;
  }
  .header-bg-transparent .navbar-brand-collapsed,
  .header-bg-transparent .navbar-brand-on-scroll {
    display: none;
  }
  .header-bg-transparent.js-header-fix-moment .navbar-brand-default {
    display: none;
  }
  .header-bg-transparent.js-header-fix-moment .navbar-brand-on-scroll {
    display: inline-block;
  }
  @media (min-width: 576px) and (min-width: 576px) {
    .header-bg-transparent-sm:not(.js-header-fix-moment)
      .search-push-top[style*='display: block']
      + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-sm:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  @media (min-width: 576px) {
    .header-bg-transparent-sm .navbar-brand-collapsed,
    .header-bg-transparent-sm .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-sm.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-sm.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  @media (min-width: 768px) and (min-width: 768px) {
    .header-bg-transparent-md:not(.js-header-fix-moment)
      .search-push-top[style*='display: block']
      + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-md:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  @media (min-width: 768px) {
    .header-bg-transparent-md .navbar-brand-collapsed,
    .header-bg-transparent-md .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-md.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-md.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  @media (min-width: 992px) and (min-width: 992px) {
    .header-bg-transparent-lg:not(.js-header-fix-moment)
      .search-push-top[style*='display: block']
      + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-lg:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  @media (min-width: 992px) {
    .header-bg-transparent-lg .navbar-brand-collapsed,
    .header-bg-transparent-lg .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-lg.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-lg.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  @media (min-width: 1200px) and (min-width: 1200px) {
    .header-bg-transparent-xl:not(.js-header-fix-moment)
      .search-push-top[style*='display: block']
      + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-xl:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  @media (min-width: 1200px) {
    .header-bg-transparent-xl .navbar-brand-collapsed,
    .header-bg-transparent-xl .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-xl.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-xl.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  @media (max-width: 575.98px) {
    .header-bg-transparent-sm .navbar-brand-default,
    .header-bg-transparent-sm .navbar-brand-on-scroll {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .header-bg-transparent-md .navbar-brand-default,
    .header-bg-transparent-md .navbar-brand-on-scroll {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .header-bg-transparent-lg .navbar-brand-default,
    .header-bg-transparent-lg .navbar-brand-on-scroll {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .header-bg-transparent-xl .navbar-brand-default,
    .header-bg-transparent-xl .navbar-brand-on-scroll {
      display: none;
    }
  }
  .header-bg-transparent .navbar-brand-default,
  .header-bg-transparent .navbar-brand-on-scroll {
    display: none;
  }
  .header-bg-transparent .navbar-brand-default {
    display: flex;
  }
  @media (max-width: 991.98px) {
    .header-bg-transparent .navbar-nav,
    .header-bg-transparent-lg .navbar-nav,
    .header-bg-transparent-md .navbar-nav,
    .header-bg-transparent-sm .navbar-nav {
      background-color: #fff;
      padding: 0.875rem 1.5rem;
    }
  }
  .header-white-bg-on-scroll.scrolled .header-section {
    background-color: #fff;
  }
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment)
    .navbar-nav-item:hover
    .nav-link {
    color: #fff;
  }
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .nav-link.active,
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
    color: #fff;
  }
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary,
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover,
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover {
    color: #fff;
  }
  .header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .nav-link-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.7)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  @media (min-width: 576px) {
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment)
      .navbar-nav-item:hover
      .nav-link {
      color: #fff;
    }
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .nav-link.active,
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary,
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover,
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .nav-link-toggle::after {
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.7)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
        no-repeat right center/1rem 1rem;
    }
  }
  @media (min-width: 768px) {
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment)
      .navbar-nav-item:hover
      .nav-link {
      color: #fff;
    }
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .nav-link.active,
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary,
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover,
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .nav-link-toggle::after {
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.7)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
        no-repeat right center/1rem 1rem;
    }
  }
  @media (min-width: 992px) {
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment)
      .navbar-nav-item:hover
      .nav-link {
      color: #fff;
    }
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .nav-link.active,
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary,
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover,
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .nav-link-toggle::after {
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.7)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
        no-repeat right center/1rem 1rem;
    }
  }
  @media (min-width: 1200px) {
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment)
      .navbar-nav-item:hover
      .nav-link {
      color: #fff;
    }
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .nav-link.active,
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary,
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link {
      color: rgba(255, 255, 255, 0.7);
    }
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover,
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover {
      color: #fff;
    }
    .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .nav-link-toggle::after {
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.7)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
        no-repeat right center/1rem 1rem;
    }
  }
  .navbar-expand {
    flex-wrap: wrap;
  }
  .navbar-expand .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand .navbar-nav {
    align-items: center;
  }
  .navbar-expand .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand .mega-menu-position-right-fix.dropdown-menu,
  .navbar-expand .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand .navbar-promo-card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand .navbar-promo-card-deck {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand
    .navbar-promo-card-deck
    .navbar-promo-item:not(:last-child)
    .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: '';
  }
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-wrap: wrap;
    }
    .navbar-expand-sm .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-sm .navbar-nav {
      align-items: center;
    }
    .navbar-expand-sm .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-sm .mega-menu-position-right-fix.dropdown-menu,
    .navbar-expand-sm .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-sm .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-sm .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-sm .navbar-promo-card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
    }
    .navbar-expand-sm .navbar-promo-card-deck {
      display: flex;
      flex-flow: row wrap;
    }
    .navbar-expand-sm
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-sm
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: '';
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-wrap: wrap;
    }
    .navbar-expand-md .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-md .navbar-nav {
      align-items: center;
    }
    .navbar-expand-md .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-md .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-md .mega-menu-position-right-fix.dropdown-menu,
    .navbar-expand-md .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-md .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-md .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-md .navbar-promo-card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
    }
    .navbar-expand-md .navbar-promo-card-deck {
      display: flex;
      flex-flow: row wrap;
    }
    .navbar-expand-md
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-md
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: '';
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-wrap: wrap;
    }
    .navbar-expand-lg .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-lg .navbar-nav {
      align-items: center;
    }
    .navbar-expand-lg .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-lg .mega-menu-position-right-fix.dropdown-menu,
    .navbar-expand-lg .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-lg .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-lg .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-lg .navbar-promo-card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
    }
    .navbar-expand-lg .navbar-promo-card-deck {
      display: flex;
      flex-flow: row wrap;
    }
    .navbar-expand-lg
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-lg
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: '';
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-wrap: wrap;
    }
    .navbar-expand-xl .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-xl .navbar-nav {
      align-items: center;
    }
    .navbar-expand-xl .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-xl .mega-menu-position-right-fix.dropdown-menu,
    .navbar-expand-xl .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-xl .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-xl .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-xl .navbar-promo-card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
    }
    .navbar-expand-xl .navbar-promo-card-deck {
      display: flex;
      flex-flow: row wrap;
    }
    .navbar-expand-xl
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-xl
      .navbar-promo-card-deck
      .navbar-promo-item:not(:last-child)
      .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: '';
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-sm .navbar-brand,
    .navbar-expand-sm .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-sm .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-sm .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-sm .nav-link {
      display: flex;
      align-items: center;
    }
    .navbar-expand-sm .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-sm .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-sm .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-sm .hs-sub-menu-opened .nav-link-toggle::after {
      transform: rotate(-180deg);
    }
    .navbar-expand-sm .dropdown-item-toggle::after {
      transform: rotate(90deg);
    }
    .navbar-expand-sm .hs-sub-menu-opened > .dropdown-item-toggle::after {
      transform: rotate(-90deg);
    }
    .navbar-expand-sm .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-sm .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-sm .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-sm .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-sm .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-md .navbar-brand,
    .navbar-expand-md .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-md .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-md .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-md .nav-link {
      display: flex;
      align-items: center;
    }
    .navbar-expand-md .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-md .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-md .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-md .hs-sub-menu-opened .nav-link-toggle::after {
      transform: rotate(-180deg);
    }
    .navbar-expand-md .dropdown-item-toggle::after {
      transform: rotate(90deg);
    }
    .navbar-expand-md .hs-sub-menu-opened > .dropdown-item-toggle::after {
      transform: rotate(-90deg);
    }
    .navbar-expand-md .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-md .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-md .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-md .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-md .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-lg .navbar-brand,
    .navbar-expand-lg .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-lg .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-lg .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-lg .nav-link {
      display: flex;
      align-items: center;
    }
    .navbar-expand-lg .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-lg .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
      transform: rotate(-180deg);
    }
    .navbar-expand-lg .dropdown-item-toggle::after {
      transform: rotate(90deg);
    }
    .navbar-expand-lg .hs-sub-menu-opened > .dropdown-item-toggle::after {
      transform: rotate(-90deg);
    }
    .navbar-expand-lg .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-lg .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-lg .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-lg .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-lg .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-xl .navbar-brand,
    .navbar-expand-xl .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-xl .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-xl .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-xl .nav-link {
      display: flex;
      align-items: center;
    }
    .navbar-expand-xl .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-xl .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
      transform: rotate(-180deg);
    }
    .navbar-expand-xl .dropdown-item-toggle::after {
      transform: rotate(90deg);
    }
    .navbar-expand-xl .hs-sub-menu-opened > .dropdown-item-toggle::after {
      transform: rotate(-90deg);
    }
    .navbar-expand-xl .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-xl .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-xl .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-xl .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-xl .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  .navbar-expand.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand .navbar-brand,
  .navbar-expand .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-expand .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand .hs-sub-menu-opened .nav-link-toggle::after {
    transform: rotate(-180deg);
  }
  .navbar-expand .dropdown-item-toggle::after {
    transform: rotate(90deg);
  }
  .navbar-expand .hs-sub-menu-opened > .dropdown-item-toggle::after {
    transform: rotate(-90deg);
  }
  .navbar-expand .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
  @media (max-width: 767.98px) {
    .navbar-expand-sm .header-nav-last-item {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-banner,
    .navbar-expand-lg .navbar-product-banner,
    .navbar-expand-md .navbar-banner,
    .navbar-expand-md .navbar-product-banner,
    .navbar-expand-sm .navbar-banner,
    .navbar-expand-sm .navbar-product-banner,
    .navbar-expand-xs .navbar-banner,
    .navbar-expand-xs .navbar-product-banner {
      display: none;
    }
    .navbar-expand .navbar-brand {
      padding-top: 0.5rem;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-banner,
    .navbar-expand-xl .navbar-product-banner {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .header .mega-menu-position-right-fix-xl.hs-mega-menu.hs-position-right {
      right: 20%;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .navbar-expand-md .navbar-brand {
      padding-top: 0.5rem;
    }
    .header .mega-menu-position-right-fix-md.hs-mega-menu.hs-position-right {
      right: 20%;
    }
  }
  @media (min-width: 992px) {
    [class*='navbar-expand']:not(.navbar-expand-xl) {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg-collapse-block .navbar-collapse {
      display: block !important;
    }
  }
  .header-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .header-floating.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .header-floating.js-header-fix-moment .header-floating-inner {
    box-shadow: none;
  }
  .header-floating-inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (min-width: 576px) {
    .header-floating-sm {
      position: absolute;
      right: 0;
      left: 0;
      margin-top: 2.5rem;
    }
    .header-floating-sm.js-header-fix-moment {
      position: fixed;
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
      margin-top: 0;
    }
    .header-floating-sm.js-header-fix-moment .header-floating-inner {
      box-shadow: none;
    }
    .header-floating-inner {
      border-radius: 0.3125rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  @media (min-width: 768px) {
    .header-floating-md {
      position: absolute;
      right: 0;
      left: 0;
      margin-top: 2.5rem;
    }
    .header-floating-md.js-header-fix-moment {
      position: fixed;
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
      margin-top: 0;
    }
    .header-floating-md.js-header-fix-moment .header-floating-inner {
      box-shadow: none;
    }
    .header-floating-inner {
      border-radius: 0.3125rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  @media (min-width: 992px) {
    .header-floating-lg {
      position: absolute;
      right: 0;
      left: 0;
      margin-top: 2.5rem;
    }
    .header-floating-lg.js-header-fix-moment {
      position: fixed;
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
      margin-top: 0;
    }
    .header-floating-lg.js-header-fix-moment .header-floating-inner {
      box-shadow: none;
    }
    .header-floating-inner {
      border-radius: 0.3125rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  @media (min-width: 1200px) {
    .header-floating-xl {
      position: absolute;
      right: 0;
      left: 0;
      margin-top: 2.5rem;
    }
    .header-floating-xl.js-header-fix-moment {
      position: fixed;
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
      margin-top: 0;
    }
    .header-floating-xl.js-header-fix-moment .header-floating-inner {
      box-shadow: none;
    }
    .header-floating-inner {
      border-radius: 0.3125rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  @media (max-width: 575.98px) {
    .header-floating-sm {
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
    }
    .header-floating-sm.header-box-shadow .header-section,
    .header-floating-sm.header-box-shadow-on-scroll.scrolled .header-section {
      box-shadow: none;
    }
  }
  @media (max-width: 767.98px) {
    .header-floating-md {
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
    }
    .header-floating-md.header-box-shadow .header-section,
    .header-floating-md.header-box-shadow-on-scroll.scrolled .header-section {
      box-shadow: none;
    }
  }
  @media (max-width: 991.98px) {
    .header-floating-lg {
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
    }
    .header-floating-lg.header-box-shadow .header-section,
    .header-floating-lg.header-box-shadow-on-scroll.scrolled .header-section {
      box-shadow: none;
    }
  }
  @media (max-width: 1199.98px) {
    .header-floating-xl {
      background-color: #fff;
      box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
    }
    .header-floating-xl.header-box-shadow .header-section,
    .header-floating-xl.header-box-shadow-on-scroll.scrolled .header-section {
      box-shadow: none;
    }
  }
  .header-floating {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
  }
  .header-floating.header-box-shadow .header-section,
  .header-floating.header-box-shadow-on-scroll.scrolled .header-section {
    box-shadow: none;
  }
  @media (max-width: 991.98px) {
    .header-floating-inner {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (max-width: 767.98px) {
    .header-floating-md .header-floating-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-floating-md .header-floating-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-floating-md .header-floating-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-floating-md .header-floating-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  @media (max-width: 991.98px) {
    .header-floating .header-floating-inner,
    .header-floating-lg .header-floating-inner,
    .header-floating-sm .header-floating-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-floating .header-floating-inner::-webkit-scrollbar,
    .header-floating-lg .header-floating-inner::-webkit-scrollbar,
    .header-floating-sm .header-floating-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-floating .header-floating-inner::-webkit-scrollbar-thumb,
    .header-floating-lg .header-floating-inner::-webkit-scrollbar-thumb,
    .header-floating-sm .header-floating-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-floating .header-floating-inner:hover::-webkit-scrollbar-thumb,
    .header-floating-lg .header-floating-inner:hover::-webkit-scrollbar-thumb,
    .header-floating-sm .header-floating-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  @media (max-width: 1199.98px) {
    .header-floating-xl .header-floating-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-floating-xl .header-floating-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-floating-xl .header-floating-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-floating-xl .header-floating-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  .header-abs-top {
    position: absolute;
    top: 0;
    bottom: auto;
  }
  @media (min-width: 576px) {
    .header-abs-top-sm {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  @media (min-width: 768px) {
    .header-abs-top-md {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  @media (min-width: 992px) {
    .header-abs-top-lg {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  @media (min-width: 1200px) {
    .header-abs-top-xl {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  @media (max-width: 767.98px) {
    .header-abs-top-md .header-abs-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-abs-top-md .header-abs-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-abs-top-md .header-abs-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-abs-top-md .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  @media (max-width: 991.98px) {
    .header-abs-top .header-abs-top-inner,
    .header-abs-top-lg .header-abs-top-inner,
    .header-abs-top-sm .header-abs-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-abs-top .header-abs-top-inner::-webkit-scrollbar,
    .header-abs-top-lg .header-abs-top-inner::-webkit-scrollbar,
    .header-abs-top-sm .header-abs-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-abs-top .header-abs-top-inner::-webkit-scrollbar-thumb,
    .header-abs-top-lg .header-abs-top-inner::-webkit-scrollbar-thumb,
    .header-abs-top-sm .header-abs-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-abs-top .header-abs-top-inner:hover::-webkit-scrollbar-thumb,
    .header-abs-top-lg .header-abs-top-inner:hover::-webkit-scrollbar-thumb,
    .header-abs-top-sm .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  @media (max-width: 1199.98px) {
    .header-abs-top-xl .header-abs-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-abs-top-xl .header-abs-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-abs-top-xl .header-abs-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-abs-top-xl .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  .header-sticky-top {
    position: fixed;
    bottom: auto;
    top: 0;
  }
  @media (min-width: 576px) {
    .header-sticky-top-sm {
      position: fixed;
      bottom: auto;
      top: 0;
    }
  }
  @media (min-width: 768px) {
    .header-sticky-top-md {
      position: fixed;
      bottom: auto;
      top: 0;
    }
  }
  @media (min-width: 992px) {
    .header-sticky-top-lg {
      position: fixed;
      bottom: auto;
      top: 0;
    }
  }
  @media (min-width: 1200px) {
    .header-sticky-top-xl {
      position: fixed;
      bottom: auto;
      top: 0;
    }
  }
  @media (max-width: 767.98px) {
    .header-sticky-top-md .header-sticky-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-sticky-top-md .header-sticky-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-sticky-top-md .header-sticky-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-sticky-top-md .header-sticky-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  @media (max-width: 991.98px) {
    .header-sticky-top .header-sticky-top-inner,
    .header-sticky-top-lg .header-sticky-top-inner,
    .header-sticky-top-sm .header-sticky-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-sticky-top .header-sticky-top-inner::-webkit-scrollbar,
    .header-sticky-top-lg .header-sticky-top-inner::-webkit-scrollbar,
    .header-sticky-top-sm .header-sticky-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-sticky-top .header-sticky-top-inner::-webkit-scrollbar-thumb,
    .header-sticky-top-lg .header-sticky-top-inner::-webkit-scrollbar-thumb,
    .header-sticky-top-sm .header-sticky-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-sticky-top .header-sticky-top-inner:hover::-webkit-scrollbar-thumb,
    .header-sticky-top-lg .header-sticky-top-inner:hover::-webkit-scrollbar-thumb,
    .header-sticky-top-sm .header-sticky-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  @media (max-width: 1199.98px) {
    .header-sticky-top-xl .header-sticky-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-sticky-top-xl .header-sticky-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-sticky-top-xl .header-sticky-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-sticky-top-xl .header-sticky-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeIn {
    animation-name: fadeIn;
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 15px, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  .fadeInUp {
    animation-name: fadeInUp;
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .fadeOut {
    animation-name: fadeOut;
  }
  @keyframes fadeOutUp {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  }
  .fadeOutUp {
    animation-name: fadeOutUp;
  }
  @keyframes fadeOutDown {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  }
  .fadeOutDown {
    animation-name: fadeOutDown;
  }
  @keyframes slideInUp {
    from {
      transform: translate3d(0, 10px, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  .slideInUp {
    animation-name: slideInUp;
  }
  @keyframes slideInDown {
    from {
      transform: translate3d(0, -10px, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  .slideInDown {
    animation-name: slideInDown;
  }
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInLeft {
    animation-name: fadeInLeft;
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInRight {
    animation-name: fadeInRight;
  }
  @keyframes fadeOutLeft {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }
  .fadeOutLeft {
    animation-name: fadeOutLeft;
  }
  @keyframes fadeOutRight {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  }
  .fadeOutRight {
    animation-name: fadeOutRight;
  }
  .alert-primary {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .alert-primary hr {
    border-top-color: #1e6cff;
  }
  .alert-primary .alert-link {
    color: #fff;
  }
  .alert-secondary {
    color: #fff;
    background-color: #71869d;
    border-color: #71869d;
  }
  .alert-secondary hr {
    border-top-color: #647991;
  }
  .alert-secondary .alert-link {
    color: #fff;
  }
  .alert-success {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .alert-success hr {
    border-top-color: #00b092;
  }
  .alert-success .alert-link {
    color: #fff;
  }
  .alert-info {
    color: #fff;
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .alert-info hr {
    border-top-color: #0890a6;
  }
  .alert-info .alert-link {
    color: #fff;
  }
  .alert-warning {
    color: #1e2022;
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .alert-warning hr {
    border-top-color: #f3be82;
  }
  .alert-warning .alert-link {
    color: #1e2022;
  }
  .alert-danger {
    color: #fff;
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .alert-danger hr {
    border-top-color: #eb3567;
  }
  .alert-danger .alert-link {
    color: #fff;
  }
  .alert-light {
    color: #1e2022;
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .alert-light hr {
    border-top-color: #deeaff;
  }
  .alert-light .alert-link {
    color: #1e2022;
  }
  .alert-dark {
    color: #fff;
    background-color: #21325b;
    border-color: #21325b;
  }
  .alert-dark hr {
    border-top-color: #1a2848;
  }
  .alert-dark .alert-link {
    color: #fff;
  }
  .alert-indigo {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .alert-indigo hr {
    border-top-color: #25116c;
  }
  .alert-indigo .alert-link {
    color: #fff;
  }
  .alert-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
  }
  .alert-soft-primary .alert-link {
    color: #377dff;
  }
  .alert-soft-primary .alert-link:hover {
    color: #1366ff;
  }
  .alert-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, 0.1);
  }
  .alert-soft-secondary .alert-link {
    color: #71869d;
  }
  .alert-soft-secondary .alert-link:hover {
    color: #60748b;
  }
  .alert-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
  }
  .alert-soft-success .alert-link {
    color: #00c9a7;
  }
  .alert-soft-success .alert-link:hover {
    color: #00a589;
  }
  .alert-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, 0.1);
  }
  .alert-soft-info .alert-link {
    color: #09a5be;
  }
  .alert-soft-info .alert-link:hover {
    color: #07879c;
  }
  .alert-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
  }
  .alert-soft-warning .alert-link {
    color: #f5ca99;
  }
  .alert-soft-warning .alert-link:hover {
    color: #f2b978;
  }
  .alert-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, 0.1);
  }
  .alert-soft-danger .alert-link {
    color: #ed4c78;
  }
  .alert-soft-danger .alert-link:hover {
    color: #ea2c60;
  }
  .alert-soft-light {
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0.1);
  }
  .alert-soft-light .alert-link {
    color: #f7faff;
  }
  .alert-soft-light .alert-link:hover {
    color: #d3e4ff;
  }
  .alert-soft-dark {
    color: #21325b;
    background-color: rgba(33, 50, 91, 0.1);
  }
  .alert-soft-dark .alert-link {
    color: #21325b;
  }
  .alert-soft-dark .alert-link:hover {
    color: #172441;
  }
  .alert-soft-indigo {
    color: #2d1582;
    background-color: rgba(45, 21, 130, 0.1);
  }
  .alert-soft-indigo .alert-link {
    color: #2d1582;
  }
  .alert-soft-indigo .alert-link:hover {
    color: #221063;
  }
  .avatar {
    position: relative;
    display: inline-block;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 0.3125rem;
  }
  .avatar-img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.3125rem;
  }
  .avatar-initials {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .avatar-circle {
    border-radius: 50%;
  }
  .avatar-circle .avatar-img {
    border-radius: 50%;
  }
  .avatar-centered {
    display: flex;
    margin-right: auto;
    margin-left: auto;
  }
  .avatar-border-lg {
    border: 0.1875rem solid #fff;
  }
  .avatar-group {
    display: flex;
  }
  .avatar-group .avatar-circle .avatar-initials {
    border-radius: 50%;
  }
  .avatar-group .avatar .avatar-img,
  .avatar-group .avatar .avatar-initials,
  .avatar-group .avatar-sm .avatar-img,
  .avatar-group .avatar-sm .avatar-initials,
  .avatar-group .avatar-xs .avatar-img,
  .avatar-group .avatar-xs .avatar-initials {
    border: 2px solid #fff;
  }
  .avatar-group .avatar-lg .avatar-img,
  .avatar-group .avatar-lg .avatar-initials {
    border: 5px solid #fff;
  }
  .avatar-group .avatar-xl .avatar-img,
  .avatar-group .avatar-xl .avatar-initials {
    border: 7px solid #fff;
  }
  .avatar-group .avatar + .avatar {
    margin-left: -1rem;
  }
  .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -0.6125rem;
  }
  .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -0.875rem;
  }
  .avatar-group .avatar-lg + .avatar-lg {
    margin-left: -1.5rem;
  }
  .avatar-group .avatar-xl + .avatar-xl {
    margin-left: -2rem;
  }
  .avatar-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .avatar.avatar-circle .avatar-status {
    bottom: -0.21875rem;
    right: -0.21875rem;
  }
  .avatar.avatar-circle .avatar-sm-status {
    bottom: 0;
    right: 0;
  }
  .avatar.avatar-circle .avatar-lg-status {
    bottom: -0.325rem;
    right: -0.325rem;
  }
  .avatar-xss {
    width: 1rem;
    height: 1rem;
  }
  .avatar-xss .avatar-initials {
    font-size: 0.75rem;
  }
  .avatar-xs {
    width: 1.75rem;
    height: 1.75rem;
  }
  .avatar-xs .avatar-initials {
    font-size: 0.75rem;
  }
  .avatar-xs.avatar-circle .avatar-status {
    bottom: -0.21875rem;
    right: -0.21875rem;
  }
  .avatar-xs.avatar-circle .avatar-sm-status {
    bottom: -0.11719rem;
    right: -0.11719rem;
  }
  .avatar-xs.avatar-circle .avatar-lg-status {
    bottom: -0.325rem;
    right: -0.325rem;
  }
  .avatar-xs {
    width: 1.75rem;
    height: 1.75rem;
  }
  .avatar-xs .avatar-initials {
    font-size: 0.75rem;
  }
  .avatar-xs.avatar-circle .avatar-status {
    bottom: -0.21875rem;
    right: -0.21875rem;
  }
  .avatar-xs.avatar-circle .avatar-sm-status {
    bottom: -0.11719rem;
    right: -0.11719rem;
  }
  .avatar-xs.avatar-circle .avatar-lg-status {
    bottom: -0.325rem;
    right: -0.325rem;
  }
  .avatar-sm {
    width: 2.6875rem;
    height: 2.6875rem;
  }
  .avatar-sm .avatar-initials {
    font-size: 0.8125rem;
  }
  .avatar-sm.avatar-circle .avatar-status {
    bottom: -0.21875rem;
    right: -0.21875rem;
  }
  .avatar-sm.avatar-circle .avatar-sm-status {
    bottom: -0.09375rem;
    right: -0.09375rem;
  }
  .avatar-sm.avatar-circle .avatar-lg-status {
    bottom: -0.40625rem;
    right: -0.40625rem;
  }
  .avatar-lg {
    width: 4.25rem;
    height: 4.25rem;
  }
  .avatar-lg .avatar-initials {
    font-size: 1.25rem;
  }
  .avatar-lg.avatar-circle .avatar-status {
    bottom: -0.13125rem;
    right: -0.13125rem;
  }
  .avatar-lg.avatar-circle .avatar-sm-status {
    bottom: 0.09375rem;
    right: 0.09375rem;
  }
  .avatar-lg.avatar-circle .avatar-lg-status {
    bottom: -0.20312rem;
    right: -0.20312rem;
  }
  .avatar-xl {
    width: 5.625rem;
    height: 5.625rem;
  }
  .avatar-xl .avatar-initials {
    font-size: 2rem;
  }
  .avatar-xl.avatar-circle .avatar-status {
    bottom: 0.16406rem;
    right: 0.16406rem;
  }
  .avatar-xl.avatar-circle .avatar-sm-status {
    bottom: 0.23438rem;
    right: 0.23438rem;
  }
  .avatar-xl.avatar-circle .avatar-lg-status {
    bottom: 0.13542rem;
    right: 0.13542rem;
  }
  .avatar-xxl {
    width: 7rem;
    height: 7rem;
  }
  .avatar-xxl .avatar-initials {
    font-size: 3rem;
  }
  .avatar-xxl.avatar-circle .avatar-status {
    bottom: 0.4375rem;
    right: 0.4375rem;
  }
  .avatar-xxl.avatar-circle .avatar-sm-status {
    bottom: 0.58333rem;
    right: 0.58333rem;
  }
  .avatar-xxl.avatar-circle .avatar-lg-status {
    bottom: 0.29545rem;
    right: 0.29545rem;
  }
  .avatar-status {
    position: absolute;
    bottom: -0.525rem;
    right: -0.525rem;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    vertical-align: baseline;
    border: 0.125rem solid #fff;
    width: 1.3125rem;
    height: 1.3125rem;
    line-height: 1;
    font-size: 0.625rem;
    border-radius: 50%;
  }
  .avatar-sm-status {
    bottom: -0.2625rem;
    right: -0.2625rem;
    width: 0.9375rem;
    height: 0.9375rem;
    font-size: 0.5rem;
  }
  .avatar-lg-status {
    width: 1.625rem;
    height: 1.625rem;
    font-size: 0.75rem;
  }
  .avatar-primary {
    color: #fff;
    background-color: #377dff;
  }
  .avatar-secondary {
    color: #fff;
    background-color: #71869d;
  }
  .avatar-success {
    color: #fff;
    background-color: #00c9a7;
  }
  .avatar-info {
    color: #fff;
    background-color: #09a5be;
  }
  .avatar-warning {
    color: #1e2022;
    background-color: #f5ca99;
  }
  .avatar-danger {
    color: #fff;
    background-color: #ed4c78;
  }
  .avatar-light {
    color: #1e2022;
    background-color: #f7faff;
  }
  .avatar-dark {
    color: #fff;
    background-color: #21325b;
  }
  .avatar-indigo {
    color: #fff;
    background-color: #2d1582;
  }
  .avatar-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
  }
  .avatar-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, 0.1);
  }
  .avatar-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
  }
  .avatar-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, 0.1);
  }
  .avatar-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
  }
  .avatar-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, 0.1);
  }
  .avatar-soft-light {
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0.1);
  }
  .avatar-soft-dark {
    color: #21325b;
    background-color: rgba(33, 50, 91, 0.1);
  }
  .avatar-soft-indigo {
    color: #2d1582;
    background-color: rgba(45, 21, 130, 0.1);
  }
  .blockquote {
    font-size: 1rem;
    border-left: 0.1875rem solid #e7eaf3;
    padding-left: 0.75rem;
    margin-bottom: 0;
  }
  .blockquote-footer {
    margin-top: 1rem;
  }
  .badge-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
  }
  a.badge-soft-primary:focus,
  a.badge-soft-primary:hover {
    color: #fff;
    background-color: #377dff;
  }
  .badge-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, 0.1);
  }
  a.badge-soft-secondary:focus,
  a.badge-soft-secondary:hover {
    color: #fff;
    background-color: #71869d;
  }
  .badge-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
  }
  a.badge-soft-success:focus,
  a.badge-soft-success:hover {
    color: #fff;
    background-color: #00c9a7;
  }
  .badge-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, 0.1);
  }
  a.badge-soft-info:focus,
  a.badge-soft-info:hover {
    color: #fff;
    background-color: #09a5be;
  }
  .badge-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
  }
  a.badge-soft-warning:focus,
  a.badge-soft-warning:hover {
    color: #1e2022;
    background-color: #f5ca99;
  }
  .badge-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, 0.1);
  }
  a.badge-soft-danger:focus,
  a.badge-soft-danger:hover {
    color: #fff;
    background-color: #ed4c78;
  }
  .badge-soft-light {
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0.1);
  }
  a.badge-soft-light:focus,
  a.badge-soft-light:hover {
    color: #1e2022;
    background-color: #f7faff;
  }
  .badge-soft-dark {
    color: #21325b;
    background-color: rgba(33, 50, 91, 0.1);
  }
  a.badge-soft-dark:focus,
  a.badge-soft-dark:hover {
    color: #fff;
    background-color: #21325b;
  }
  .badge-soft-indigo {
    color: #2d1582;
    background-color: rgba(45, 21, 130, 0.1);
  }
  a.badge-soft-indigo:focus,
  a.badge-soft-indigo:hover {
    color: #fff;
    background-color: #2d1582;
  }
  .badge-soft-warning {
    color: #ec9a3c !important;
  }
  .btn.dropdown-toggle {
    display: inline-flex;
    align-items: center;
  }
  .btn-white {
    font-weight: 400;
    background-color: #fff;
    border-color: #e7eaf3;
  }
  .btn-white.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-white.active,
  .btn-white.focus,
  .btn-white:active,
  .btn-white:focus,
  .btn-white:hover {
    color: #0052ea;
    box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
  }
  .btn-white.active.dropdown-toggle::after,
  .btn-white.focus.dropdown-toggle::after,
  .btn-white:active.dropdown-toggle::after,
  .btn-white:focus.dropdown-toggle::after,
  .btn-white:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-white.disabled,
  .btn-white:disabled {
    color: #677788;
    background-color: rgba(231, 234, 243, 0.5);
  }
  .btn-primary.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-secondary.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-success.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-info.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-warning.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-danger.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-light.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-dark.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-indigo.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-primary.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-primary.active.dropdown-toggle::after,
  .btn-outline-primary:active.dropdown-toggle::after,
  .btn-outline-primary:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-secondary.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-secondary.active.dropdown-toggle::after,
  .btn-outline-secondary:active.dropdown-toggle::after,
  .btn-outline-secondary:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-success.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-success.active.dropdown-toggle::after,
  .btn-outline-success:active.dropdown-toggle::after,
  .btn-outline-success:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-info.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-info.active.dropdown-toggle::after,
  .btn-outline-info:active.dropdown-toggle::after,
  .btn-outline-info:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-warning.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-warning.active.dropdown-toggle::after,
  .btn-outline-warning:active.dropdown-toggle::after,
  .btn-outline-warning:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-danger.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-danger.active.dropdown-toggle::after,
  .btn-outline-danger:active.dropdown-toggle::after,
  .btn-outline-danger:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-light.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-light.active.dropdown-toggle::after,
  .btn-outline-light:active.dropdown-toggle::after,
  .btn-outline-light:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-dark.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-dark.active.dropdown-toggle::after,
  .btn-outline-dark:active.dropdown-toggle::after,
  .btn-outline-dark:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-indigo.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%232d1582' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-indigo.active.dropdown-toggle::after,
  .btn-outline-indigo:active.dropdown-toggle::after,
  .btn-outline-indigo:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-primary[href]:active,
  .btn-primary[href]:focus,
  .btn-primary[href]:hover,
  .btn-primary[type]:active,
  .btn-primary[type]:focus,
  .btn-primary[type]:hover {
    box-shadow: 0 4px 11px rgba(55, 125, 255, 0.35);
  }
  .btn-primary:not([href]),
  .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-primary:not(label.btn),
  .btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: rgba(232, 93, 4, 1);
    border-color: rgba(232, 93, 4, 1);
    font-size: medium;
  }
  .btn-primary:hover {
    background-color: #377dff;
    border-color: #377dff;
  }
  .btn-secondary[href]:active,
  .btn-secondary[href]:focus,
  .btn-secondary[href]:hover,
  .btn-secondary[type]:active,
  .btn-secondary[type]:focus,
  .btn-secondary[type]:hover {
    box-shadow: 0 4px 11px rgba(113, 134, 157, 0.35);
  }
  .btn-secondary:not([href]),
  .btn-secondary:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-secondary:not(label.btn),
  .btn-secondary:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #71869d;
    border-color: #71869d;
  }
  .btn-secondary:hover {
    background-color: #71869d;
    border-color: #71869d;
  }
  .btn-success[href]:active,
  .btn-success[href]:focus,
  .btn-success[href]:hover,
  .btn-success[type]:active,
  .btn-success[type]:focus,
  .btn-success[type]:hover {
    box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
  }
  .btn-success:not([href]),
  .btn-success:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-success:not(label.btn),
  .btn-success:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-success:hover {
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-info[href]:active,
  .btn-info[href]:focus,
  .btn-info[href]:hover,
  .btn-info[type]:active,
  .btn-info[type]:focus,
  .btn-info[type]:hover {
    box-shadow: 0 4px 11px rgba(9, 165, 190, 0.35);
  }
  .btn-info:not([href]),
  .btn-info:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-info:not(label.btn),
  .btn-info:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .btn-info:hover {
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .btn-warning[href]:active,
  .btn-warning[href]:focus,
  .btn-warning[href]:hover,
  .btn-warning[type]:active,
  .btn-warning[type]:focus,
  .btn-warning[type]:hover {
    box-shadow: 0 4px 11px rgba(245, 202, 153, 0.35);
  }
  .btn-warning:not([href]),
  .btn-warning:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-warning:not(label.btn),
  .btn-warning:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-warning:hover {
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-danger[href]:active,
  .btn-danger[href]:focus,
  .btn-danger[href]:hover,
  .btn-danger[type]:active,
  .btn-danger[type]:focus,
  .btn-danger[type]:hover {
    box-shadow: 0 4px 11px rgba(237, 76, 120, 0.35);
  }
  .btn-danger:not([href]),
  .btn-danger:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-danger:not(label.btn),
  .btn-danger:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-danger:hover {
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-light[href]:active,
  .btn-light[href]:focus,
  .btn-light[href]:hover,
  .btn-light[type]:active,
  .btn-light[type]:focus,
  .btn-light[type]:hover {
    box-shadow: 0 4px 11px rgba(247, 250, 255, 0.35);
  }
  .btn-light:not([href]),
  .btn-light:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-light:not(label.btn),
  .btn-light:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .btn-light:hover {
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .btn-dark[href]:active,
  .btn-dark[href]:focus,
  .btn-dark[href]:hover,
  .btn-dark[type]:active,
  .btn-dark[type]:focus,
  .btn-dark[type]:hover {
    box-shadow: 0 4px 11px rgba(33, 50, 91, 0.35);
  }
  .btn-dark:not([href]),
  .btn-dark:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-dark:not(label.btn),
  .btn-dark:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #21325b;
    border-color: #21325b;
  }
  .btn-dark:hover {
    background-color: #21325b;
    border-color: #21325b;
  }
  .btn-indigo[href]:active,
  .btn-indigo[href]:focus,
  .btn-indigo[href]:hover,
  .btn-indigo[type]:active,
  .btn-indigo[type]:focus,
  .btn-indigo[type]:hover {
    box-shadow: 0 4px 11px rgba(45, 21, 130, 0.35);
  }
  .btn-indigo:not([href]),
  .btn-indigo:not([href]):not([href]):not(:disabled):not(.disabled),
  .btn-indigo:not(label.btn),
  .btn-indigo:not(label.btn):not([href]):not(:disabled):not(.disabled) {
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .btn-indigo:hover {
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .btn-custom-toggle-primary {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-primary:hover {
    color: #377dff;
    background-color: transparent;
    border-color: #377dff;
  }
  .btn-custom-toggle-primary:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
  }
  .btn-custom-toggle-secondary {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-secondary:hover {
    color: #71869d;
    background-color: transparent;
    border-color: #71869d;
  }
  .btn-custom-toggle-secondary:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #71869d;
    border-color: #71869d;
  }
  .btn-custom-toggle-success {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-success:hover {
    color: #00c9a7;
    background-color: transparent;
    border-color: #00c9a7;
  }
  .btn-custom-toggle-success:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-success:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7;
  }
  .btn-custom-toggle-info {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-info:hover {
    color: #09a5be;
    background-color: transparent;
    border-color: #09a5be;
  }
  .btn-custom-toggle-info:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-info:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #09a5be;
    border-color: #09a5be;
  }
  .btn-custom-toggle-warning {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-warning:hover {
    color: #f5ca99;
    background-color: transparent;
    border-color: #f5ca99;
  }
  .btn-custom-toggle-warning:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-warning:not(:disabled):not(.disabled):active {
    color: #1e2022;
    background-color: #f5ca99;
    border-color: #f5ca99;
  }
  .btn-custom-toggle-danger {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-danger:hover {
    color: #ed4c78;
    background-color: transparent;
    border-color: #ed4c78;
  }
  .btn-custom-toggle-danger:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #ed4c78;
    border-color: #ed4c78;
  }
  .btn-custom-toggle-light {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-light:hover {
    color: #fff;
    background-color: transparent;
    border-color: #f7faff;
  }
  .btn-custom-toggle-light:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-light:not(:disabled):not(.disabled):active {
    color: #1e2022;
    background-color: #f7faff;
    border-color: #f7faff;
  }
  .btn-custom-toggle-dark {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-dark:hover {
    color: #21325b;
    background-color: transparent;
    border-color: #21325b;
  }
  .btn-custom-toggle-dark:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-dark:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #21325b;
    border-color: #21325b;
  }
  .btn-custom-toggle-indigo {
    color: #97a4af;
    border-color: #e7eaf3;
  }
  .btn-custom-toggle-indigo:hover {
    color: #2d1582;
    background-color: transparent;
    border-color: #2d1582;
  }
  .btn-custom-toggle-indigo:not(:disabled):not(.disabled).active,
  .btn-custom-toggle-indigo:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582;
  }
  .btn-custom-toggle-light {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7);
  }
  .btn-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
    border-color: transparent;
  }
  .btn-soft-primary.active,
  .btn-soft-primary:active,
  .btn-soft-primary:focus,
  .btn-soft-primary:hover {
    color: #fff;
    background-color: #377dff;
    box-shadow: 0 4px 11px rgba(55, 125, 255, 0.1);
  }
  .btn-soft-primary.disabled,
  .btn-soft-primary:disabled {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
    box-shadow: none;
  }
  .btn-soft-primary:not(:disabled):not(.disabled).active,
  .btn-soft-primary:not(:disabled):not(.disabled):active,
  .show > .btn-soft-primary.dropdown-toggle {
    color: #fff;
    background-color: #377dff;
  }
  .btn-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, 0.1);
    border-color: transparent;
  }
  .btn-soft-secondary.active,
  .btn-soft-secondary:active,
  .btn-soft-secondary:focus,
  .btn-soft-secondary:hover {
    color: #fff;
    background-color: #71869d;
    box-shadow: 0 4px 11px rgba(113, 134, 157, 0.1);
  }
  .btn-soft-secondary.disabled,
  .btn-soft-secondary:disabled {
    color: #71869d;
    background-color: rgba(113, 134, 157, 0.1);
    box-shadow: none;
  }
  .btn-soft-secondary:not(:disabled):not(.disabled).active,
  .btn-soft-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-soft-secondary.dropdown-toggle {
    color: #fff;
    background-color: #71869d;
  }
  .btn-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
    border-color: transparent;
  }
  .btn-soft-success.active,
  .btn-soft-success:active,
  .btn-soft-success:focus,
  .btn-soft-success:hover {
    color: #fff;
    background-color: #00c9a7;
    box-shadow: 0 4px 11px rgba(0, 201, 167, 0.1);
  }
  .btn-soft-success.disabled,
  .btn-soft-success:disabled {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
    box-shadow: none;
  }
  .btn-soft-success:not(:disabled):not(.disabled).active,
  .btn-soft-success:not(:disabled):not(.disabled):active,
  .show > .btn-soft-success.dropdown-toggle {
    color: #fff;
    background-color: #00c9a7;
  }
  .btn-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, 0.1);
    border-color: transparent;
  }
  .btn-soft-info.active,
  .btn-soft-info:active,
  .btn-soft-info:focus,
  .btn-soft-info:hover {
    color: #fff;
    background-color: #09a5be;
    box-shadow: 0 4px 11px rgba(9, 165, 190, 0.1);
  }
  .btn-soft-info.disabled,
  .btn-soft-info:disabled {
    color: #09a5be;
    background-color: rgba(9, 165, 190, 0.1);
    box-shadow: none;
  }
  .btn-soft-info:not(:disabled):not(.disabled).active,
  .btn-soft-info:not(:disabled):not(.disabled):active,
  .show > .btn-soft-info.dropdown-toggle {
    color: #fff;
    background-color: #09a5be;
  }
  .btn-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
    border-color: transparent;
  }
  .btn-soft-warning.active,
  .btn-soft-warning:active,
  .btn-soft-warning:focus,
  .btn-soft-warning:hover {
    color: #1e2022;
    background-color: #f5ca99;
    box-shadow: 0 4px 11px rgba(245, 202, 153, 0.1);
  }
  .btn-soft-warning.disabled,
  .btn-soft-warning:disabled {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
    box-shadow: none;
  }
  .btn-soft-warning:not(:disabled):not(.disabled).active,
  .btn-soft-warning:not(:disabled):not(.disabled):active,
  .show > .btn-soft-warning.dropdown-toggle {
    color: #1e2022;
    background-color: #f5ca99;
  }
  .btn-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, 0.1);
    border-color: transparent;
  }
  .btn-soft-danger.active,
  .btn-soft-danger:active,
  .btn-soft-danger:focus,
  .btn-soft-danger:hover {
    color: #fff;
    background-color: #ed4c78;
    box-shadow: 0 4px 11px rgba(237, 76, 120, 0.1);
  }
  .btn-soft-danger.disabled,
  .btn-soft-danger:disabled {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, 0.1);
    box-shadow: none;
  }
  .btn-soft-danger:not(:disabled):not(.disabled).active,
  .btn-soft-danger:not(:disabled):not(.disabled):active,
  .show > .btn-soft-danger.dropdown-toggle {
    color: #fff;
    background-color: #ed4c78;
  }
  .btn-soft-light {
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0.1);
    border-color: transparent;
  }
  .btn-soft-light.active,
  .btn-soft-light:active,
  .btn-soft-light:focus,
  .btn-soft-light:hover {
    color: #1e2022;
    background-color: #f7faff;
    box-shadow: 0 4px 11px rgba(247, 250, 255, 0.1);
  }
  .btn-soft-light.disabled,
  .btn-soft-light:disabled {
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0.1);
    box-shadow: none;
  }
  .btn-soft-light:not(:disabled):not(.disabled).active,
  .btn-soft-light:not(:disabled):not(.disabled):active,
  .show > .btn-soft-light.dropdown-toggle {
    color: #1e2022;
    background-color: #f7faff;
  }
  .btn-soft-dark {
    color: #21325b;
    background-color: rgba(33, 50, 91, 0.1);
    border-color: transparent;
  }
  .btn-soft-dark.active,
  .btn-soft-dark:active,
  .btn-soft-dark:focus,
  .btn-soft-dark:hover {
    color: #fff;
    background-color: #21325b;
    box-shadow: 0 4px 11px rgba(33, 50, 91, 0.1);
  }
  .btn-soft-dark.disabled,
  .btn-soft-dark:disabled {
    color: #21325b;
    background-color: rgba(33, 50, 91, 0.1);
    box-shadow: none;
  }
  .btn-soft-dark:not(:disabled):not(.disabled).active,
  .btn-soft-dark:not(:disabled):not(.disabled):active,
  .show > .btn-soft-dark.dropdown-toggle {
    color: #fff;
    background-color: #21325b;
  }
  .btn-soft-indigo {
    color: #2d1582;
    background-color: rgba(45, 21, 130, 0.1);
    border-color: transparent;
  }
  .btn-soft-indigo.active,
  .btn-soft-indigo:active,
  .btn-soft-indigo:focus,
  .btn-soft-indigo:hover {
    color: #fff;
    background-color: #2d1582;
    box-shadow: 0 4px 11px rgba(45, 21, 130, 0.1);
  }
  .btn-soft-indigo.disabled,
  .btn-soft-indigo:disabled {
    color: #2d1582;
    background-color: rgba(45, 21, 130, 0.1);
    box-shadow: none;
  }
  .btn-soft-indigo:not(:disabled):not(.disabled).active,
  .btn-soft-indigo:not(:disabled):not(.disabled):active,
  .show > .btn-soft-indigo.dropdown-toggle {
    color: #fff;
    background-color: #2d1582;
  }
  .btn-soft-primary.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-primary.active.dropdown-toggle::after,
  .btn-soft-primary.focus.dropdown-toggle::after,
  .btn-soft-primary:active.dropdown-toggle::after,
  .btn-soft-primary:focus.dropdown-toggle::after,
  .btn-soft-primary:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-secondary.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-secondary.active.dropdown-toggle::after,
  .btn-soft-secondary.focus.dropdown-toggle::after,
  .btn-soft-secondary:active.dropdown-toggle::after,
  .btn-soft-secondary:focus.dropdown-toggle::after,
  .btn-soft-secondary:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-success.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-success.active.dropdown-toggle::after,
  .btn-soft-success.focus.dropdown-toggle::after,
  .btn-soft-success:active.dropdown-toggle::after,
  .btn-soft-success:focus.dropdown-toggle::after,
  .btn-soft-success:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-info.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-info.active.dropdown-toggle::after,
  .btn-soft-info.focus.dropdown-toggle::after,
  .btn-soft-info:active.dropdown-toggle::after,
  .btn-soft-info:focus.dropdown-toggle::after,
  .btn-soft-info:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-warning.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-warning.active.dropdown-toggle::after,
  .btn-soft-warning.focus.dropdown-toggle::after,
  .btn-soft-warning:active.dropdown-toggle::after,
  .btn-soft-warning:focus.dropdown-toggle::after,
  .btn-soft-warning:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-danger.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-danger.active.dropdown-toggle::after,
  .btn-soft-danger.focus.dropdown-toggle::after,
  .btn-soft-danger:active.dropdown-toggle::after,
  .btn-soft-danger:focus.dropdown-toggle::after,
  .btn-soft-danger:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-light.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-light.active.dropdown-toggle::after,
  .btn-soft-light.focus.dropdown-toggle::after,
  .btn-soft-light:active.dropdown-toggle::after,
  .btn-soft-light:focus.dropdown-toggle::after,
  .btn-soft-light:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-dark.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-dark.active.dropdown-toggle::after,
  .btn-soft-dark.focus.dropdown-toggle::after,
  .btn-soft-dark:active.dropdown-toggle::after,
  .btn-soft-dark:focus.dropdown-toggle::after,
  .btn-soft-dark:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-soft-indigo.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%232d1582' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .btn-soft-indigo.active.dropdown-toggle::after,
  .btn-soft-indigo.focus.dropdown-toggle::after,
  .btn-soft-indigo:active.dropdown-toggle::after,
  .btn-soft-indigo:focus.dropdown-toggle::after,
  .btn-soft-indigo:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .btn-outline-secondary {
    border-color: #e7eaf3;
  }
  .btn-wide {
    min-width: 10rem;
  }
  .btn-xs {
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.275rem 0.75rem;
  }
  .btn-icon.btn-xs {
    font-size: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
  }
  .btn-group-sm > .btn-icon.btn,
  .btn-icon.btn-sm {
    font-size: 0.8125rem;
    width: 2.6875rem;
    height: 2.6875rem;
  }
  .btn-group-lg > .btn-icon.btn,
  .btn-icon.btn-lg {
    font-size: 1.25rem;
    width: 4.25rem;
    height: 4.25rem;
  }
  .btn-ghost-primary {
    color: #377dff;
    background-color: transparent;
  }
  .btn-ghost-primary.active,
  .btn-ghost-primary:active,
  .btn-ghost-primary:focus,
  .btn-ghost-primary:hover {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
  }
  .btn-ghost-secondary {
    color: #71869d;
    background-color: transparent;
  }
  .btn-ghost-secondary.active,
  .btn-ghost-secondary:active,
  .btn-ghost-secondary:focus,
  .btn-ghost-secondary:hover {
    color: #71869d;
    background-color: rgba(55, 125, 255, 0.1);
  }
  .btn-ghost-success {
    color: #00c9a7;
    background-color: transparent;
  }
  .btn-ghost-success.active,
  .btn-ghost-success:active,
  .btn-ghost-success:focus,
  .btn-ghost-success:hover {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
  }
  .btn-ghost-info {
    color: #09a5be;
    background-color: transparent;
  }
  .btn-ghost-info.active,
  .btn-ghost-info:active,
  .btn-ghost-info:focus,
  .btn-ghost-info:hover {
    color: #09a5be;
    background-color: rgba(9, 165, 190, 0.1);
  }
  .btn-ghost-warning {
    color: #f5ca99;
    background-color: transparent;
  }
  .btn-ghost-warning.active,
  .btn-ghost-warning:active,
  .btn-ghost-warning:focus,
  .btn-ghost-warning:hover {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
  }
  .btn-ghost-danger {
    color: #ed4c78;
    background-color: transparent;
  }
  .btn-ghost-danger.active,
  .btn-ghost-danger:active,
  .btn-ghost-danger:focus,
  .btn-ghost-danger:hover {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, 0.1);
  }
  .btn-ghost-light {
    color: #f7faff;
    background-color: transparent;
  }
  .btn-ghost-light.active,
  .btn-ghost-light:active,
  .btn-ghost-light:focus,
  .btn-ghost-light:hover {
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0.1);
  }
  .btn-ghost-dark {
    color: #21325b;
    background-color: transparent;
  }
  .btn-ghost-dark.active,
  .btn-ghost-dark:active,
  .btn-ghost-dark:focus,
  .btn-ghost-dark:hover {
    color: #21325b;
    background-color: rgba(33, 50, 91, 0.1);
  }
  .btn-ghost-indigo {
    color: #2d1582;
    background-color: transparent;
  }
  .btn-ghost-indigo.active,
  .btn-ghost-indigo:active,
  .btn-ghost-indigo:focus,
  .btn-ghost-indigo:hover {
    color: #2d1582;
    background-color: rgba(45, 21, 130, 0.1);
  }
  .btn-ghost-secondary:active,
  .btn-ghost-secondary:focus,
  .btn-ghost-secondary:hover {
    color: #377dff;
  }
  .btn-group-segment {
    position: relative;
    display: inline-flex;
    justify-content: center;
    background-color: #f8fafd;
    border-radius: 0.3125rem;
    padding: 0.25rem 0.25rem;
  }
  .btn-group-segment .btn {
    color: #677788;
  }
  .btn-group-segment .btn:not(:last-child) {
    margin-right: 0.25rem;
  }
  .btn-group-segment .btn:hover {
    color: #377dff;
  }
  .btn-group-segment .btn.active {
    color: #1e2022;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .btn-group-segment .btn:not(:disabled):not(.disabled).active,
  .btn-group-segment .btn:not(:disabled):not(.disabled):active {
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .btn-group-segment > .btn-group:not(:first-child) > .btn,
  .btn-group-segment > .btn-group:not(:last-child) > .btn,
  .btn-group-segment > .btn:not(:first-child),
  .btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0.3125rem;
  }
  .btn-group-segment.btn-group-pills {
    border-radius: 6.1875rem;
  }
  .btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn,
  .btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
  .btn-group-segment.btn-group-pills > .btn:not(:first-child),
  .btn-group-segment.btn-group-pills > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 6.1875rem;
  }
  .btn-status {
    position: absolute;
    top: -0.4375rem;
    right: -0.4375rem;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    vertical-align: baseline;
    width: 1.3125rem;
    height: 1.3125rem;
    line-height: 1;
    font-size: 0.625rem;
    border-radius: 50%;
  }
  .btn-status-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .btn svg {
    margin-bottom: 0;
  }
  label.btn {
    cursor: pointer !important;
  }
  .btn-pill {
    border-radius: 6.1875rem;
  }
  .btn-link {
    font-weight: 600;
  }
  .btn-icon {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    width: 3.125rem;
    height: 3.125rem;
    padding: 0;
  }
  .btn-toggle-default {
    display: inline-block;
  }
  .btn-toggle-toggled {
    display: none;
  }
  .btn.toggled .btn-toggle-default {
    display: none;
  }
  .btn.toggled .btn-toggle-toggled {
    display: inline-block;
  }
  .brand {
    width: 100%;
    max-width: 7.5rem;
  }
  .breadcrumb .breadcrumb-item {
    color: #1e2022;
    font-size: 0.875rem;
  }
  .breadcrumb .breadcrumb-link {
    color: #8c98a4;
  }
  .breadcrumb .breadcrumb-link:hover {
    color: #0052ea;
  }
  .breadcrumb-light .breadcrumb-item {
    color: #fff;
  }
  .breadcrumb-light .breadcrumb-item + .breadcrumb-item::before {
    color: rgba(255, 255, 255, 0.5);
  }
  .breadcrumb-light .breadcrumb-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .breadcrumb-light .breadcrumb-link:hover {
    color: #fff;
  }
  .breadcrumb-no-gutter {
    padding: 0;
  }
  .card-2-count {
    column-count: 2;
  }
  .card-3-count {
    column-count: 3;
  }
  .card-4-count {
    column-count: 4;
  }
  .card-1-gap {
    column-gap: 0.5rem;
  }
  .card-2-gap {
    column-gap: 2rem;
  }
  .card-3-gap {
    column-gap: 3rem;
  }
  .card-4-gap {
    column-gap: 4rem;
  }
  .card-5-gap {
    column-gap: 5rem;
  }
  .card-columns {
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
  @media (min-width: 576px) {
    .card-sm-2-count {
      column-count: 2;
    }
    .card-sm-3-count {
      column-count: 3;
    }
    .card-sm-4-count {
      column-count: 4;
    }
    .card-sm-1-gap {
      column-gap: 0.5rem;
    }
    .card-sm-2-gap {
      column-gap: 2rem;
    }
    .card-sm-3-gap {
      column-gap: 3rem;
    }
    .card-sm-4-gap {
      column-gap: 4rem;
    }
    .card-sm-5-gap {
      column-gap: 5rem;
    }
    .card-sm-columns {
      orphans: 1;
      widows: 1;
    }
    .card-sm-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .card-md-2-count {
      column-count: 2;
    }
    .card-md-3-count {
      column-count: 3;
    }
    .card-md-4-count {
      column-count: 4;
    }
    .card-md-1-gap {
      column-gap: 0.5rem;
    }
    .card-md-2-gap {
      column-gap: 2rem;
    }
    .card-md-3-gap {
      column-gap: 3rem;
    }
    .card-md-4-gap {
      column-gap: 4rem;
    }
    .card-md-5-gap {
      column-gap: 5rem;
    }
    .card-md-columns {
      orphans: 1;
      widows: 1;
    }
    .card-md-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .card-lg-2-count {
      column-count: 2;
    }
    .card-lg-3-count {
      column-count: 3;
    }
    .card-lg-4-count {
      column-count: 4;
    }
    .card-lg-1-gap {
      column-gap: 0.5rem;
    }
    .card-lg-2-gap {
      column-gap: 2rem;
    }
    .card-lg-3-gap {
      column-gap: 3rem;
    }
    .card-lg-4-gap {
      column-gap: 4rem;
    }
    .card-lg-5-gap {
      column-gap: 5rem;
    }
    .card-lg-columns {
      orphans: 1;
      widows: 1;
    }
    .card-lg-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .card-xl-2-count {
      column-count: 2;
    }
    .card-xl-3-count {
      column-count: 3;
    }
    .card-xl-4-count {
      column-count: 4;
    }
    .card-xl-1-gap {
      column-gap: 0.5rem;
    }
    .card-xl-2-gap {
      column-gap: 2rem;
    }
    .card-xl-3-gap {
      column-gap: 3rem;
    }
    .card-xl-4-gap {
      column-gap: 4rem;
    }
    .card-xl-5-gap {
      column-gap: 5rem;
    }
    .card-xl-columns {
      orphans: 1;
      widows: 1;
    }
    .card-xl-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  .card {
    border-width: 0;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
  }
  .card-title {
    display: block;
    margin-bottom: 0;
  }
  .card-subtitle {
    display: block;
    color: #8c98a4;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03125rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .card-text {
    display: block;
    color: #677788;
  }
  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .card-header-title {
    margin-bottom: 0;
  }
  .card-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media (min-width: 576px) {
    .card-group > .card + .card {
      border-left: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
  }
  .card-bg-light {
    background-color: #f8fafd;
  }
  .card-bg-light:hover {
    background-color: #f4f7fc;
  }
  .card-frame {
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    box-shadow: none;
    transition: 0.3s;
  }
  .card-frame-highlighted,
  .card-frame:hover {
    border-color: rgba(55, 125, 255, 0.7);
  }
  .card-hover-shadow {
    transition: 0.3s;
  }
  .card-hover-shadow:hover {
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.175);
  }
  .card-collapse {
    padding: 0;
    transition: 0.3s ease-in-out;
  }
  .card-collapse:hover {
    background-color: #f8fafd;
  }
  .card-collapse .card-body {
    color: #677788;
  }
  .card-btn {
    color: #1e2022;
    text-align: left;
    white-space: inherit;
    border-radius: 0.75rem;
  }
  .card-btn-toggle {
    display: inline-block;
    color: #377dff;
  }
  .card-btn-toggle-default {
    display: none;
  }
  .card-btn-toggle-active {
    display: block;
  }
  .card-btn.collapsed .card-btn-toggle-default {
    display: block;
  }
  .card-btn.collapsed .card-btn-toggle-active {
    display: none;
  }
  .card-body-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .card-dashed {
    border-width: 0;
    min-height: 10rem;
  }
  .card-dashed:hover {
    border-color: rgba(55, 125, 255, 0.7);
  }
  .card-dashed:hover .card-dashed-body {
    color: #0052ea;
  }
  .card-dashed .card-dashed-body {
    color: #8c98a4;
    border: 0.0625rem dashed rgba(214, 219, 235, 0.7);
    border-radius: 0.75rem;
    transition: 0.3s;
  }
  .card-bordered {
    border-width: 0.0625rem;
  }
  .card-img-bottom,
  .card-img-top {
    min-height: 1px;
  }
  .card .table {
    margin-bottom: 1px;
  }
  .card .table td,
  .card .table th {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .card .table-thead-bordered {
    margin-top: -0.0625rem;
  }
  .card-dropdown {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .card-no-gutters {
    box-shadow: none;
  }
  .card-no-gutters > .card-body,
  .card-no-gutters > .card-header {
    padding-right: 0;
    padding-left: 0;
  }
  .card-no-gutters > .card-footer {
    border-width: 0;
    padding: 0;
  }
  @media (min-width: 576px) {
    .card-sm-gutters-1 {
      margin-right: -0.25rem;
      margin-left: -0.25rem;
    }
    .card-sm-gutters-1 .card {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }
    .card-sm-gutters-2 {
      margin-right: -0.5rem;
      margin-left: -0.5rem;
    }
    .card-sm-gutters-2 .card {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
    .card-sm-gutters-3 {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem;
    }
    .card-sm-gutters-3 .card {
      margin-right: 0.9375rem;
      margin-left: 0.9375rem;
    }
  }
  @media (min-width: 768px) {
    .card-md-gutters-1 {
      margin-right: -0.25rem;
      margin-left: -0.25rem;
    }
    .card-md-gutters-1 .card {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }
    .card-md-gutters-2 {
      margin-right: -0.5rem;
      margin-left: -0.5rem;
    }
    .card-md-gutters-2 .card {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
    .card-md-gutters-3 {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem;
    }
    .card-md-gutters-3 .card {
      margin-right: 0.9375rem;
      margin-left: 0.9375rem;
    }
  }
  @media (min-width: 992px) {
    .card-lg-gutters-1 {
      margin-right: -0.25rem;
      margin-left: -0.25rem;
    }
    .card-lg-gutters-1 .card {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }
    .card-lg-gutters-2 {
      margin-right: -0.5rem;
      margin-left: -0.5rem;
    }
    .card-lg-gutters-2 .card {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
    .card-lg-gutters-3 {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem;
    }
    .card-lg-gutters-3 .card {
      margin-right: 0.9375rem;
      margin-left: 0.9375rem;
    }
  }
  @media (max-width: 767.98px) {
    .card-group-sm-break {
      display: block;
    }
    .card-group-sm-break > .card:not(:last-child) {
      margin-bottom: -1px;
    }
    .card-group-sm-break > .card + .card {
      border-left: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .card-group-sm-break > .card:first-child {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group-sm-break > .card:first-child .card-header,
    .card-group-sm-break > .card:first-child .card-img-top {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
    .card-group-sm-break > .card:first-child .card-footer,
    .card-group-sm-break > .card:first-child .card-img-bottom {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group-sm-break > .card:last-child {
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .card-group-sm-break > .card:last-child .card-header,
    .card-group-sm-break > .card:last-child .card-img-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .card-group-sm-break > .card:last-child .card-footer,
    .card-group-sm-break > .card:last-child .card-img-bottom {
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
    }
  }
  @media (max-width: 991.98px) {
    .card-group-md-break {
      display: block;
    }
    .card-group-md-break > .card:not(:last-child) {
      margin-bottom: -1px;
    }
    .card-group-md-break > .card + .card {
      border-left: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .card-group-md-break > .card:first-child {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group-md-break > .card:first-child .card-header,
    .card-group-md-break > .card:first-child .card-img-top {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
    .card-group-md-break > .card:first-child .card-footer,
    .card-group-md-break > .card:first-child .card-img-bottom {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group-md-break > .card:last-child {
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .card-group-md-break > .card:last-child .card-header,
    .card-group-md-break > .card:last-child .card-img-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .card-group-md-break > .card:last-child .card-footer,
    .card-group-md-break > .card:last-child .card-img-bottom {
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
    }
  }
  @media (max-width: 1199.98px) {
    .card-group-lg-break {
      display: block;
    }
    .card-group-lg-break > .card:not(:last-child) {
      margin-bottom: -1px;
    }
    .card-group-lg-break > .card + .card {
      border-left: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .card-group-lg-break > .card:first-child {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group-lg-break > .card:first-child .card-header,
    .card-group-lg-break > .card:first-child .card-img-top {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
    .card-group-lg-break > .card:first-child .card-footer,
    .card-group-lg-break > .card:first-child .card-img-bottom {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group-lg-break > .card:last-child {
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .card-group-lg-break > .card:last-child .card-header,
    .card-group-lg-break > .card:last-child .card-img-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .card-group-lg-break > .card:last-child .card-footer,
    .card-group-lg-break > .card:last-child .card-img-bottom {
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
    }
  }
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1003;
    width: 23.4375rem;
    height: 100%;
    background-color: #fff;
    box-shadow: -5px 0 12px rgba(103, 119, 136, 0.1);
  }
  .sidebar-body,
  .sidebar-content {
    height: 100%;
  }
  .sidebar-left {
    right: auto;
    left: 0;
    box-shadow: 5px 0 12px rgba(103, 119, 136, 0.1);
  }
  .sidebar-scroller {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .sidebar-container {
    position: relative;
    height: 100%;
    min-height: 100%;
  }
  .sidebar-footer {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sidebar-footer-offset {
    height: 100%;
    box-sizing: border-box;
  }
  .clients {
    width: 100%;
    max-width: 6rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .column-divider {
    position: relative;
  }
  .column-divider::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: '';
  }
  @media (min-width: 576px) {
    .column-divider-sm {
      position: relative;
    }
    .column-divider-sm::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 0.0625rem solid #e7eaf3;
      content: '';
    }
  }
  @media (min-width: 768px) {
    .column-divider-md {
      position: relative;
    }
    .column-divider-md::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 0.0625rem solid #e7eaf3;
      content: '';
    }
  }
  @media (min-width: 992px) {
    .column-divider-lg {
      position: relative;
    }
    .column-divider-lg::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 0.0625rem solid #e7eaf3;
      content: '';
    }
  }
  @media (min-width: 1200px) {
    .column-divider-xl {
      position: relative;
    }
    .column-divider-xl::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 0.0625rem solid #e7eaf3;
      content: '';
    }
  }
  .column-divider-20deg::before {
    transform: rotate(20deg);
    left: -1rem;
  }
  .divider {
    display: flex;
    align-items: center;
  }
  .divider::after,
  .divider::before {
    flex: 1 1 0%;
    border-top: 0.0625rem solid #e7eaf3;
    content: '';
    margin-top: 0.0625rem;
  }
  .divider::before {
    margin-right: 1.5rem;
  }
  .divider::after {
    margin-left: 1.5rem;
  }
  .divider-text {
    color: #8c98a4;
  }
  .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .dropdown-header {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .dropdown-nav-link {
    color: #1e2022;
    font-size: 0.875rem;
  }
  .dropdown-item {
    font-size: 0.875rem;
  }
  .dropdown-item:active {
    color: #1e2022;
    background-color: rgba(189, 197, 209, 0.3);
  }
  .dropdown-item.active:not(:focus):not(:active) {
    color: #1e2022;
    background-color: rgba(189, 197, 209, 0.3);
  }
  .dropdown-item-icon {
    display: inline-block;
    text-align: center;
    color: #677788;
    opacity: 0.7;
    font-size: 0.75rem;
    min-width: 1rem;
    max-width: 1rem;
    margin-right: 0.25rem;
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .dropdown-toggle::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    content: '';
  }
  .dropdown-toggle:not(.dropdown-toggle-empty)::after {
    margin-left: 0.5rem;
  }
  .dropdown-toggle-collapse::after {
    transition: 0.3s;
  }
  .dropdown-toggle-collapse[aria-expanded='true']::after {
    transform: rotate(-180deg);
  }
  .dropdown-card {
    padding-top: 0;
    padding-bottom: 0;
  }
  .dropdown-menu-top {
    top: 0;
  }
  .dropdown-menu-bottom {
    top: auto;
    bottom: 0;
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-top {
      top: 0;
    }
    .dropdown-menu-sm-bottom {
      top: auto;
      bottom: 0;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-top {
      top: 0;
    }
    .dropdown-menu-md-bottom {
      top: auto;
      bottom: 0;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-top {
      top: 0;
    }
    .dropdown-menu-lg-bottom {
      top: auto;
      bottom: 0;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-top {
      top: 0;
    }
    .dropdown-menu-xl-bottom {
      top: auto;
      bottom: 0;
    }
  }
  .dropdown-card {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 2rem;
  }
  @media (min-width: 768px) {
    .dropdown-card {
      min-width: 25rem;
    }
  }
  @media (min-width: 576px) {
    .dropdown-menu-size-md {
      width: 440px;
    }
    .dropdown-menu-size-lg {
      width: 340px;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-size-lg {
      width: 440px;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-size-lg {
      width: 740px;
    }
  }
  .input-group .input-group-append,
  .input-group .input-group-prepend {
    z-index: 4;
  }
  .custom-select:focus,
  .form-control:focus {
    box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .input-group-text {
    border-color: #e7eaf3;
  }
  .input-label {
    display: block;
    color: #1e2022;
    font-size: 0.875rem;
  }
  .input-label-secondary {
    color: #8c98a4;
    font-size: 0.875rem;
    margin-left: 0.25rem;
  }
  .form-link {
    display: inline-block;
    margin-top: 0.75rem;
  }
  .input-group-borderless .form-control,
  .input-group-borderless .input-group-text {
    border: none;
  }
  .input-group-borderless .form-control:focus,
  .input-group-borderless .input-group-text:focus {
    box-shadow: none;
  }
  .input-group-add-field {
    position: relative;
    margin-top: 1rem;
  }
  .input-group-add-field:hover .input-group-add-field-delete {
    opacity: 1;
  }
  .input-group-add-field-delete {
    position: absolute;
    top: 0.75rem;
    right: 0;
    width: 1rem;
    color: #ed4c78;
    opacity: 0;
    margin-right: -1rem;
    padding-left: 0.25rem;
  }
  .input-group-add-field-delete:hover {
    color: #ea2c60;
  }
  @media (max-width: 1199.98px) {
    .input-group-add-field-delete {
      opacity: 1;
    }
  }
  .input-group > .input-group-prepend .custom-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-append .custom-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  @media (max-width: 575.98px) {
    .input-group-sm-down-break {
      display: block;
    }
    .input-group-sm-down-break > .custom-select,
    .input-group-sm-down-break > .form-control {
      width: 100%;
    }
    .input-group-sm-down-break > .custom-select:first-child,
    .input-group-sm-down-break > .form-control:first-child {
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .input-group-sm-down-break > .custom-select:not(:first-child):not(:last-child),
    .input-group-sm-down-break > .form-control:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    .input-group-sm-down-break > .custom-select:last-child,
    .input-group-sm-down-break > .form-control:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
    .input-group-sm-down-break .custom-file + .custom-file,
    .input-group-sm-down-break .custom-file + .custom-select,
    .input-group-sm-down-break .custom-file + .form-control,
    .input-group-sm-down-break .custom-select + .custom-file,
    .input-group-sm-down-break .custom-select + .custom-select,
    .input-group-sm-down-break .custom-select + .form-control,
    .input-group-sm-down-break .form-control + .custom-file,
    .input-group-sm-down-break .form-control + .custom-select,
    .input-group-sm-down-break .form-control + .form-control,
    .input-group-sm-down-break .form-control-plaintext + .custom-file,
    .input-group-sm-down-break .form-control-plaintext + .custom-select,
    .input-group-sm-down-break .form-control-plaintext + .form-control {
      margin-left: 0;
      margin-top: -0.0625rem;
    }
  }
  @media (max-width: 767.98px) {
    .input-group-md-down-break {
      display: block;
    }
    .input-group-md-down-break > .custom-select,
    .input-group-md-down-break > .form-control {
      width: 100%;
    }
    .input-group-md-down-break > .custom-select:first-child,
    .input-group-md-down-break > .form-control:first-child {
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .input-group-md-down-break > .custom-select:not(:first-child):not(:last-child),
    .input-group-md-down-break > .form-control:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    .input-group-md-down-break > .custom-select:last-child,
    .input-group-md-down-break > .form-control:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
    .input-group-md-down-break .custom-file + .custom-file,
    .input-group-md-down-break .custom-file + .custom-select,
    .input-group-md-down-break .custom-file + .form-control,
    .input-group-md-down-break .custom-select + .custom-file,
    .input-group-md-down-break .custom-select + .custom-select,
    .input-group-md-down-break .custom-select + .form-control,
    .input-group-md-down-break .form-control + .custom-file,
    .input-group-md-down-break .form-control + .custom-select,
    .input-group-md-down-break .form-control + .form-control,
    .input-group-md-down-break .form-control-plaintext + .custom-file,
    .input-group-md-down-break .form-control-plaintext + .custom-select,
    .input-group-md-down-break .form-control-plaintext + .form-control {
      margin-left: 0;
      margin-top: -0.0625rem;
    }
  }
  @media (max-width: 991.98px) {
    .input-group-lg-down-break {
      display: block;
    }
    .input-group-lg-down-break > .custom-select,
    .input-group-lg-down-break > .form-control {
      width: 100%;
    }
    .input-group-lg-down-break > .custom-select:first-child,
    .input-group-lg-down-break > .form-control:first-child {
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .input-group-lg-down-break > .custom-select:not(:first-child):not(:last-child),
    .input-group-lg-down-break > .form-control:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    .input-group-lg-down-break > .custom-select:last-child,
    .input-group-lg-down-break > .form-control:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
    .input-group-lg-down-break .custom-file + .custom-file,
    .input-group-lg-down-break .custom-file + .custom-select,
    .input-group-lg-down-break .custom-file + .form-control,
    .input-group-lg-down-break .custom-select + .custom-file,
    .input-group-lg-down-break .custom-select + .custom-select,
    .input-group-lg-down-break .custom-select + .form-control,
    .input-group-lg-down-break .form-control + .custom-file,
    .input-group-lg-down-break .form-control + .custom-select,
    .input-group-lg-down-break .form-control + .form-control,
    .input-group-lg-down-break .form-control-plaintext + .custom-file,
    .input-group-lg-down-break .form-control-plaintext + .custom-select,
    .input-group-lg-down-break .form-control-plaintext + .form-control {
      margin-left: 0;
      margin-top: -0.0625rem;
    }
  }
  @media (max-width: 1199.98px) {
    .input-group-xl-down-break {
      display: block;
    }
    .input-group-xl-down-break > .custom-select,
    .input-group-xl-down-break > .form-control {
      width: 100%;
    }
    .input-group-xl-down-break > .custom-select:first-child,
    .input-group-xl-down-break > .form-control:first-child {
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .input-group-xl-down-break > .custom-select:not(:first-child):not(:last-child),
    .input-group-xl-down-break > .form-control:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    .input-group-xl-down-break > .custom-select:last-child,
    .input-group-xl-down-break > .form-control:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
    .input-group-xl-down-break .custom-file + .custom-file,
    .input-group-xl-down-break .custom-file + .custom-select,
    .input-group-xl-down-break .custom-file + .form-control,
    .input-group-xl-down-break .custom-select + .custom-file,
    .input-group-xl-down-break .custom-select + .custom-select,
    .input-group-xl-down-break .custom-select + .form-control,
    .input-group-xl-down-break .form-control + .custom-file,
    .input-group-xl-down-break .form-control + .custom-select,
    .input-group-xl-down-break .form-control + .form-control,
    .input-group-xl-down-break .form-control-plaintext + .custom-file,
    .input-group-xl-down-break .form-control-plaintext + .custom-select,
    .input-group-xl-down-break .form-control-plaintext + .form-control {
      margin-left: 0;
      margin-top: -0.0625rem;
    }
  }
  .input-group-down-break {
    display: block;
  }
  .input-group-down-break > .custom-select,
  .input-group-down-break > .form-control {
    width: 100%;
  }
  .input-group-down-break > .custom-select:first-child,
  .input-group-down-break > .form-control:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-down-break > .custom-select:not(:first-child):not(:last-child),
  .input-group-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-down-break > .custom-select:last-child,
  .input-group-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-down-break .custom-file + .custom-file,
  .input-group-down-break .custom-file + .custom-select,
  .input-group-down-break .custom-file + .form-control,
  .input-group-down-break .custom-select + .custom-file,
  .input-group-down-break .custom-select + .custom-select,
  .input-group-down-break .custom-select + .form-control,
  .input-group-down-break .form-control + .custom-file,
  .input-group-down-break .form-control + .custom-select,
  .input-group-down-break .form-control + .form-control,
  .input-group-down-break .form-control-plaintext + .custom-file,
  .input-group-down-break .form-control-plaintext + .custom-select,
  .input-group-down-break .form-control-plaintext + .form-control {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
  .input-group-merge {
    position: relative;
  }
  .input-group-merge .input-group-append,
  .input-group-merge .input-group-prepend {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .input-group-merge .input-group-prepend {
    left: 0;
  }
  .input-group-merge .input-group-prepend .input-group-text {
    padding: 0 0 0 1rem;
  }
  .input-group-merge .input-group-prepend .input-group-text:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-merge .input-group-append {
    right: 0;
  }
  .input-group-merge .input-group-append .input-group-text {
    padding: 0 1rem 0 0;
  }
  .input-group-merge .input-group-append .input-group-text:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-merge .input-group-text {
    z-index: 4;
    border: none;
  }
  .input-group-merge .custom-select:not(:first-child),
  .input-group-merge .form-control:not(:first-child) {
    padding-left: 2.5rem;
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-merge .custom-select:not(:last-child),
  .input-group-merge .form-control:not(:last-child) {
    padding-right: 2.5rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
  .input-group-pill {
    border-radius: 6.1875rem;
  }
  .input-group-pill > .form-control:first-child {
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem;
  }
  .input-group-pill > .form-control:last-child {
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem;
  }
  .input-group-pill > .input-group-prepend > .btn {
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem;
  }
  .input-group-pill > .input-group-append > .btn {
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem;
  }
  .input-group-pill.input-group-merge .form-control:not(:last-child) {
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem;
  }
  .input-group-pill.input-group-merge .input-group-prepend > .btn,
  .input-group-pill.input-group-merge .input-group-prepend > .input-group-text {
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem;
  }
  .input-group-pill.input-group-merge .form-control:not(:first-child) {
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem;
  }
  .input-group-pill.input-group-merge .input-group-append > .btn,
  .input-group-pill.input-group-merge .input-group-append > .input-group-text {
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem;
  }
  .input-group-sm.input-group-merge .custom-select:not(:first-child),
  .input-group-sm.input-group-merge .form-control:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .input-group-sm.input-group-merge .custom-select:not(:last-child),
  .input-group-sm.input-group-merge .form-control:not(:last-child) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .input-group-sm.input-group-merge .input-group-prepend .input-group-text:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .input-group-sm.input-group-merge .input-group-append .input-group-text:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .input-group-lg.input-group-merge .custom-select:not(:first-child),
  .input-group-lg.input-group-merge .form-control:not(:first-child) {
    padding-left: 3rem;
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-lg.input-group-merge .custom-select:not(:last-child),
  .input-group-lg.input-group-merge .form-control:not(:last-child) {
    padding-right: 3rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
  .input-group-lg.input-group-merge .input-group-prepend .input-group-text:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-lg.input-group-merge .input-group-append .input-group-text:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
  .input-group-flush .form-control {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  .input-group-flush .form-control.is-invalid,
  .input-group-flush .form-control.is-invalid:focus,
  .input-group-flush .form-control.is-valid,
  .input-group-flush .form-control.is-valid:focus {
    box-shadow: none;
  }
  .input-group-flush.input-group-merge .input-group-append .input-group-text,
  .input-group-flush.input-group-merge .input-group-prepend .input-group-text {
    padding: 0;
  }
  .input-group-flush.input-group-merge .custom-select:not(:first-child),
  .input-group-flush.input-group-merge .form-control:not(:first-child) {
    padding-left: 2rem;
  }
  .input-group-flush.input-group-merge .custom-select:not(:last-child),
  .input-group-flush.input-group-merge .form-control:not(:last-child) {
    padding-right: 2rem;
  }
  .input-group-flush.input-group .form-control:not(:first-child),
  .input-group-flush.input-group .form-control:not(:last-child),
  .input-group-flush.input-group .input-group-append .input-group-text:first-child,
  .input-group-flush.input-group .input-group-append .input-group-text:last-child {
    border-radius: 0;
  }
  .custom-select.is-valid,
  .form-control.is-valid {
    background-size: 1rem 1rem;
  }
  .custom-select.is-valid,
  .custom-select.is-valid:focus,
  .form-control.is-valid,
  .form-control.is-valid:focus {
    box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
  }
  .custom-select.is-invalid,
  .form-control.is-invalid {
    background-size: 1rem 1rem;
  }
  .custom-select.is-invalid,
  .custom-select.is-invalid:focus,
  .form-control.is-invalid,
  .form-control.is-invalid:focus {
    box-shadow: 0 0 10px rgba(237, 76, 120, 0.1);
  }
  .custom-control-label,
  .custom-select {
    cursor: pointer;
  }
  .form-control .custom-control-label {
    width: 100%;
  }
  .custom-checkbox-bookmark {
    padding-left: 0;
  }
  .custom-checkbox-bookmark-label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #bdc5d1;
    font-size: 1rem;
    margin-bottom: 0;
  }
  .custom-checkbox-bookmark-active,
  .custom-checkbox-bookmark-default {
    margin-top: -1px;
  }
  .custom-checkbox-bookmark-active {
    color: #ffc107;
  }
  .custom-checkbox-bookmark-input
    ~ .custom-checkbox-bookmark-label
    .custom-checkbox-bookmark-default {
    display: block;
  }
  .custom-checkbox-bookmark-input
    ~ .custom-checkbox-bookmark-label
    .custom-checkbox-bookmark-active {
    display: none;
  }
  .custom-checkbox-bookmark-input:checked
    ~ .custom-checkbox-bookmark-label
    .custom-checkbox-bookmark-default {
    display: none;
  }
  .custom-checkbox-bookmark-input:checked
    ~ .custom-checkbox-bookmark-label
    .custom-checkbox-bookmark-active {
    display: block;
  }
  .custom-checkbox-bookmark-input:disabled ~ .custom-checkbox-bookmark-label {
    color: #e7eaf3;
  }
  .checkbox-outline {
    padding-left: 0;
    margin-right: 0;
  }
  .checkbox-outline-label {
    border: 0.0625rem solid #e7eaf3;
    cursor: pointer;
  }
  .checkbox-outline-input:checked ~ .checkbox-outline-label {
    border-color: #377dff;
  }
  .checkbox-icon-label::after {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1rem;
    height: 1rem;
    color: #e7eaf3;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23e7eaf3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
    content: '';
  }
  .checkbox-icon-input:checked ~ .checkbox-icon-label::after {
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
      no-repeat right center/1rem 1rem;
  }
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    border-color: rgba(55, 125, 255, 0);
  }
  .toggle-switch {
    display: flex;
    cursor: pointer;
    margin-bottom: 0;
  }
  .toggle-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .toggle-switch-content {
    flex: 1;
    margin-left: 0.5rem;
  }
  .toggle-switch-label {
    position: relative;
    display: block;
    width: 3rem;
    height: 2rem;
    background-color: #e7eaf3;
    background-clip: content-box;
    border: 0.125rem solid transparent;
    border-radius: 6.1875rem;
    transition: 0.3s;
  }
  .toggle-switch-indicator {
    position: absolute;
    left: 0.125rem;
    bottom: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    transform: initial;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
    border-radius: 50%;
    transform: translate3d(0, 50%, 0);
    transition: 0.3s;
  }
  .toggle-switch-input:checked + .toggle-switch-label {
    background-color: #377dff;
  }
  .toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
    transform: translate3d(1.025rem, 50%, 0);
  }
  .toggle-switch-input:disabled + .toggle-switch-label {
    background-color: rgba(231, 234, 243, 0.5);
  }
  .toggle-switch-input:checked:disabled + .toggle-switch-label {
    background-color: rgba(55, 125, 255, 0.5);
  }
  .file-attachment-btn {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
  }
  .file-attachment-btn-label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    cursor: inherit;
    opacity: 0;
  }
  .file-attachment-input {
    position: relative;
    display: block;
    background-color: #f8fafd;
    border: 0.125rem dashed #e7eaf3;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0.3125rem;
    padding: 4rem 4rem;
    margin-bottom: 0;
  }
  .file-attachment-input-label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    cursor: inherit;
    opacity: 0;
  }
  .file-attachment-input:hover {
    background-color: #e7eaf3;
  }
  .go-to {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    width: 2.6875rem;
    height: 2.6875rem;
    background-color: rgba(113, 134, 157, 0.1);
    color: #677788;
    font-size: 0.8125rem;
    opacity: 0.5;
    border-radius: 50%;
    transition: 0.3s ease-out;
  }
  .go-to:focus:hover,
  .go-to:hover {
    color: #fff;
    background-color: #377dff;
    opacity: 1;
  }
  .hero-v1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .hero-v1::before {
    position: absolute;
    top: 50%;
    right: 100%;
    width: 80%;
    height: 80%;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(55, 125, 255, 0.075) 50%,
      rgba(255, 255, 255, 0)
    );
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .icon {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 0.3125rem;
  }
  .icon-circle {
    border-radius: 50%;
  }
  .icon.icon-xs {
    font-size: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
  }
  .icon.icon-sm {
    font-size: 0.8125rem;
    width: 2.6875rem;
    height: 2.6875rem;
  }
  .icon.icon-lg {
    font-size: 1.25rem;
    width: 4.25rem;
    height: 4.25rem;
  }
  .icon-primary {
    color: #fff;
    border-color: #377dff;
    background-color: #377dff;
  }
  .icon-secondary {
    color: #fff;
    border-color: #71869d;
    background-color: #71869d;
  }
  .icon-success {
    color: #fff;
    border-color: #00c9a7;
    background-color: #00c9a7;
  }
  .icon-info {
    color: #fff;
    border-color: #09a5be;
    background-color: #09a5be;
  }
  .icon-warning {
    color: #1e2022;
    border-color: #f5ca99;
    background-color: #f5ca99;
  }
  .icon-danger {
    color: #fff;
    border-color: #ed4c78;
    background-color: #ed4c78;
  }
  .icon-light {
    color: #1e2022;
    border-color: #f7faff;
    background-color: #f7faff;
  }
  .icon-dark {
    color: #fff;
    border-color: #21325b;
    background-color: #21325b;
  }
  .icon-indigo {
    color: #fff;
    border-color: #2d1582;
    background-color: #2d1582;
  }
  .icon-soft-primary {
    color: #377dff;
    background: rgba(55, 125, 255, 0.1);
  }
  .icon-soft-secondary {
    color: #71869d;
    background: rgba(113, 134, 157, 0.1);
  }
  .icon-soft-success {
    color: #00c9a7;
    background: rgba(0, 201, 167, 0.1);
  }
  .icon-soft-info {
    color: #09a5be;
    background: rgba(9, 165, 190, 0.1);
  }
  .icon-soft-warning {
    color: #f5ca99;
    background: rgba(245, 202, 153, 0.1);
  }
  .icon-soft-danger {
    color: #ed4c78;
    background: rgba(237, 76, 120, 0.1);
  }
  .icon-soft-light {
    color: #f7faff;
    background: rgba(247, 250, 255, 0.1);
  }
  .icon-soft-dark {
    color: #21325b;
    background: rgba(33, 50, 91, 0.1);
  }
  .icon-soft-indigo {
    color: #2d1582;
    background: rgba(45, 21, 130, 0.1);
  }
  .legend-indicator {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #bdc5d1;
    border-radius: 50%;
    margin-right: 0.4375rem;
  }
  .media-viewer {
    position: relative;
    display: block;
  }
  .media-viewer:hover .media-viewer-icon {
    opacity: 1;
    transform: scale(1);
  }
  .media-viewer-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .media-viewer-icon {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.75rem;
    color: #fff;
    background-color: #377dff;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0.7);
    transition: 0.3s;
  }
  .media-viewer-icon:focus,
  .media-viewer-icon:hover {
    color: #fff;
  }
  .media-viewer-icon-active {
    opacity: 1;
    transform: scale(1);
  }
  .video-player {
    position: relative;
  }
  .video-player-preview {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 1;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }
  .video-player-played .video-player-preview {
    opacity: 0;
    pointer-events: none;
  }
  .video-player-btn {
    z-index: 3;
    transition: transform 0.3s ease-in-out;
  }
  .video-player-played .video-player-btn {
    animation: videoPlayerButton 0.4s ease-in-out forwards;
    pointer-events: none;
  }
  .video-player-btn:hover .video-player-icon {
    color: #377dff;
    transform: scale(1.1);
  }
  .video-player-icon {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: 4.25rem;
    height: 4.25rem;
    font-size: 0.8125rem;
    border-radius: 50%;
    color: #1e2022;
    background-color: #fff;
    backface-visibility: hidden;
    transform: perspective(1px) translateZ(0);
    transition: 0.3s;
  }
  .video-player-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes videoPlayerButton {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.3);
    }
  }
  .device {
    position: relative;
  }
  .device-pointer {
    width: 65%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .device-macbook-screen {
    position: absolute;
    top: 6.8%;
    left: 12.3%;
    width: 75.1%;
    height: 81.1%;
    object-fit: cover;
  }
  .device-iphone-x {
    border-radius: 2.75rem;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
  }
  .device-iphone-x-frame {
    position: relative;
    z-index: 2;
    max-width: 100%;
    height: auto;
  }
  .device-iphone-x-screen {
    position: absolute;
    top: 2.4%;
    left: 4%;
    width: 92.4%;
    height: 96%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  .device-half-iphone-x {
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
  }
  .device-half-iphone-x-frame {
    position: relative;
    z-index: 2;
    max-width: 100%;
    height: auto;
  }
  .device-half-iphone-x-screen {
    position: absolute;
    top: 2.6%;
    left: 4.1%;
    width: 91.8%;
    height: 97.1%;
    object-fit: cover;
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
  }
  .device-ipad {
    border-radius: 1.25rem;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
  }
  .device-ipad-frame {
    position: relative;
    z-index: 2;
    max-width: 100%;
    height: auto;
  }
  .device-ipad-screen {
    position: absolute;
    top: 2.6%;
    left: 4.2%;
    width: 91.8%;
    height: 94.2%;
    object-fit: cover;
  }
  .device-horizontal-ipad {
    border-radius: 1.5rem;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
  }
  .device-horizontal-ipad-frame {
    position: relative;
    z-index: 2;
    max-width: 100%;
    height: auto;
  }
  .device-horizontal-ipad-screen {
    position: absolute;
    top: 3.8%;
    left: 3.2%;
    width: 93.8%;
    height: 92%;
    object-fit: cover;
  }
  .device-browser {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 85%;
    overflow: hidden;
    box-shadow: 0 0.625rem 2.5rem 0.625rem rgba(140, 152, 164, 0.175);
    border-radius: 0.75rem;
  }
  .device-browser-screen {
    position: absolute;
    top: 6%;
    left: 1.2%;
    width: 97.7%;
    height: 100%;
    object-fit: cover;
  }
  .device-wrapper {
    position: relative;
  }
  .device-wrapper .device-iphone-x {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 22%;
  }
  .modal-header {
    align-items: center;
    border-width: 0;
    padding-bottom: 0;
  }
  .modal-header .close {
    padding: 0.25rem 0.25rem;
    margin: 0 0 0 auto;
  }
  .modal-footer > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-footer-text:last-child {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
  .modal-top-cover {
    position: relative;
    overflow: hidden;
    min-height: 8rem;
    border-top-right-radius: 0.6875rem;
    border-top-left-radius: 0.6875rem;
  }
  .modal-top-cover-avatar {
    position: relative;
    z-index: 2;
    margin-top: -3rem;
  }
  .modal-close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 2;
  }
  .page-header {
    border-bottom: 0.0625rem solid #e7eaf3;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }
  .page-header .breadcrumb {
    margin-bottom: 0.25rem;
  }
  .page-header-title {
    margin-bottom: 0.25rem;
  }
  .page-header-text {
    margin-bottom: 0.25rem;
  }
  .page-header-tabs {
    margin-bottom: -2rem;
    border-bottom-width: 0;
  }
  .hs-nav-scroller-horizontal .page-header-tabs {
    margin-bottom: -2rem;
  }
  .page-header-reset {
    border-bottom-width: 0;
    padding-bottom: 0;
  }
  .page-preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    background-color: #fff;
  }
  .page-preloader-content-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .page-link {
    cursor: pointer;
    text-align: center;
    min-width: 2.25rem;
  }
  .page-item {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .page-item .page-link {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .page-item .page-link {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
  .pagination-sm .page-link {
    border-radius: 0.25rem;
  }
  .pagination-lg .page-link {
    border-radius: 0.75rem;
  }
  .navbar-vertical.show .card {
    box-shadow: none;
  }
  .navbar-vertical .nav {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .navbar-vertical .nav-sub .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-vertical .nav-sub .nav-link .nav-link-badge {
    margin-left: auto;
  }
  .navbar-vertical .nav-tabs {
    border-bottom-width: 0;
  }
  .navbar-vertical .nav-tabs .nav-item {
    margin: 0;
  }
  .navbar-vertical .card .nav-tabs .nav-link {
    padding-right: 0;
    padding-left: 1.4375rem;
    margin-left: -1.5rem;
  }
  .navbar-vertical .nav-tabs .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-vertical .nav-tabs .nav-link,
  .navbar-vertical .nav-tabs .nav-link.active {
    border-bottom-width: 0;
    border-radius: 0;
  }
  .navbar-vertical .nav-tabs .nav-link:hover {
    border-color: transparent;
  }
  .navbar-vertical .nav-tabs .nav-link {
    border-width: 0 0 0 0.1875rem;
    margin-bottom: 0;
  }
  .navbar-vertical .nav-tabs .active .nav-link,
  .navbar-vertical .nav-tabs .active.nav-link {
    border-left-color: #377dff;
  }
  .nav .nav-link {
    color: #677788;
  }
  .nav .nav-link:not(.active):hover {
    color: #377dff;
  }
  .nav:not(.nav-pills) .nav-item.active .nav-link,
  .nav:not(.nav-pills) .nav-link.active {
    color: #377dff;
  }
  .nav-sm .nav-link {
    font-size: 0.875rem;
  }
  .nav-x-0 .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .nav-y-0 .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
  .nav-x-sm .nav-link {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .nav-icon {
    width: 1.25rem;
    font-size: 0.8125rem;
    line-height: 1.4;
    opacity: 0.8;
    text-align: center;
    margin-right: 0.5rem;
  }
  .nav-shadow {
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .nav-classic {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
  .nav-classic .nav-link {
    color: #677788;
    border-width: 0 0 0.1875rem 0;
    border-style: solid;
    border-color: transparent;
    padding: 1rem 1.5rem;
    margin-bottom: -0.125rem;
    border-radius: 0;
    transition: 0.3s;
  }
  .nav-classic .nav-link:hover {
    color: #377dff;
  }
  .nav-classic .nav-link.active {
    color: #377dff;
    border-bottom-color: #377dff;
  }
  .nav-rounded {
    border-radius: 0.3125rem;
  }
  .nav-rounded .nav-item:first-child .nav-link {
    border-bottom-left-radius: 0.3125rem;
  }
  .nav-rounded .nav-item:last-child .nav-link {
    border-bottom-right-radius: 0.3125rem;
  }
  .nav-white .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .nav-white .nav-link.active {
    color: #377dff;
    background-color: #fff;
  }
  .nav-white .nav-link:not(.active):hover {
    color: #fff;
  }
  .nav.nav-border-white .nav-link {
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 0.125rem solid transparent;
  }
  .nav.nav-border-white .nav-link.active {
    color: #fff;
    border-bottom-color: #fff;
  }
  .nav.nav-border-white .nav-link:not(.active):hover {
    color: #fff;
  }
  .nav-box .nav-link {
    color: #1e2022;
    border-radius: 0.3125rem;
  }
  .nav-box .nav-link.active {
    color: #377dff;
    background-color: #fff;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
  }
  .nav-segment {
    position: relative;
    background-color: #f8fafd;
    padding: 0.25rem 0.25rem;
    border-radius: 0.3125rem;
  }
  .nav-segment:not(.nav-fill) {
    display: inline-flex;
  }
  .nav-segment .nav-link {
    color: #677788;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.375rem 0.875rem;
    border-radius: 0.3125rem;
  }
  .nav-segment .nav-link:hover {
    color: #377dff;
  }
  .nav-segment .nav-link.active {
    color: #1e2022;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .nav-segment.nav-pills {
    border-radius: 6.1875rem;
  }
  .nav-segment.nav-pills .nav-link {
    border-radius: 6.1875rem;
  }
  .nav-tabs .nav-item {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav-tabs .nav-link {
    color: #677788;
    border-bottom-width: 0.0625rem;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    padding: 1rem 0;
    margin-bottom: -0.09375rem;
  }
  .nav-tabs .nav-item.active .nav-link,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-bottom-width: 0.1875rem;
  }
  .nav-tabs .nav-item.active .nav-link {
    border-color: transparent transparent #377dff;
  }
  .nav-tabs .nav-item:first-child {
    margin-left: 0;
  }
  .nav-tabs .nav-item:last-child {
    margin-right: 0;
  }
  .nav-list-y-1 .nav-link {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .nav-list-y-2 .nav-link {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .progress-vertical {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    background-color: #f8fafd;
    height: 12.5rem;
  }
  .popover {
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .popover-header {
    font-weight: 600;
  }
  .profile-cover {
    position: relative;
    height: 7.5rem;
    padding: 1rem 1rem;
    border-radius: 0.75rem;
  }
  .profile-cover-content {
    position: relative;
    z-index: 1;
  }
  .profile-cover-img-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 7.5rem;
    background-color: #e7eaf3;
    border-radius: 0.75rem;
  }
  .profile-cover-img {
    width: 100%;
    height: 7.5rem;
    object-fit: cover;
    vertical-align: top;
    border-radius: 0.75rem;
  }
  .profile-cover-avatar {
    display: flex;
    background-color: #fff;
    padding: 0.75rem 0.75rem;
    margin-top: -1.75rem;
  }
  .profile-cover-content {
    padding: 1rem 1rem;
  }
  .profile-cover-btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media (min-width: 992px) {
    .profile-cover {
      height: 10rem;
    }
    .profile-cover-img-wrapper {
      height: 10rem;
    }
    .profile-cover-img {
      height: 10rem;
    }
  }
  .scrollbar {
    overflow: hidden;
    overflow-y: auto;
  }
  .scrollbar::-webkit-scrollbar {
    width: 0.5rem;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .scrollbar:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .scrollbar-horizontal {
    display: flex;
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .scrollbar-horizontal::-webkit-scrollbar {
    height: 0.25rem;
  }
  .scrollbar-horizontal::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .scrollbar-horizontal:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .search-push-top {
    display: none;
    background-color: #f8fafd;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-shadow: inset 0 -10px 20px -10px rgba(151, 164, 175, 0.05);
  }
  .search-push-top-content {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
  .search-push-top-close-btn {
    position: absolute;
    top: -1.5625rem;
    right: 0.4375rem;
  }
  @media (max-width: 575.98px) {
    .search-push-top {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
  .search-push-top-banner {
    background-color: #fff;
    box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1);
    padding-right: 1.5625rem;
    padding-top: 1.875rem;
    overflow: hidden;
  }
  .search-push-top-banner-container {
    position: relative;
    width: 75%;
    min-height: 11.5625rem;
  }
  .search-push-top-banner-img {
    position: absolute;
    bottom: -0.625rem;
    left: -0.625rem;
    transition: all 0.3s ease-in-out;
  }
  .search-push-top-banner:hover .search-push-top-banner-img:first-child {
    bottom: 0;
    left: 0;
  }
  .search-slide-down {
    position: fixed;
    z-index: 1003;
    top: 5rem;
    left: calc(50% - 18rem) !important;
    width: 36rem;
  }
  @media (max-width: 767.98px) {
    .search-slide-down {
      left: 5% !important;
      right: 5% !important;
      width: 90%;
    }
  }
  .search-slide-down-trigger.active .search-slide-down-trigger-icon::before {
    content: '';
  }
  .search-slide-down-bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    display: none;
    width: 100%;
    height: 100%;
  }
  .search-slide-down .search-slide-down-input {
    opacity: 0;
    transform: translateY(-2rem);
    transition: transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
  }
  .search-slide-down .search-slide-down-suggestions {
    opacity: 0;
    transform: translateY(-3rem);
    transition: transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
  }
  .search-slide-down-show .search-slide-down-input,
  .search-slide-down-show .search-slide-down-suggestions {
    opacity: 1;
    transform: none;
  }
  .search-slide-down-show .search-slide-down-suggestions {
    transition-delay: 0.2s;
  }
  .slick {
    position: relative;
  }
  .slick-vertical .slick-slide {
    border-width: 0;
  }
  .slick-transform-off.slick-transform-off .slick-track {
    transform: none !important;
  }
  .slick-thumb-progress {
    width: 110%;
    height: 110%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .slick-center-mode-right {
    margin-right: calc(-1px - ((100vw - 100%) / 2) + 16px) !important;
  }
  .slick-center-mode-right-offset .draggable {
    padding-right: 5rem;
  }
  .slick-equal-height .slick-list {
    height: 100%;
  }
  .slick-equal-height .slick-track {
    display: flex;
    height: 100%;
  }
  .slick-equal-height .slick-track .slick-slide {
    display: flex;
    height: auto;
  }
  .slick-gutters-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .slick-gutters-1 .slick-slide {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .slick-gutters-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .slick-gutters-2 .slick-slide {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .slick-gutters-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .slick-gutters-3 .slick-slide {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .slick-arrow {
    z-index: 1;
    line-height: 0;
    display: inline-block;
    color: #1e2022;
    background-color: #fff;
    width: 2.6875rem;
    height: 2.6875rem;
    font-size: 0.8125rem;
    cursor: pointer;
    transition: 0.3s;
  }
  .slick-arrow:not(.slick-disabled):hover {
    color: #fff;
    background-color: #377dff;
  }
  .slick-arrow::before {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .slick-arrow-left {
    left: 0;
  }
  .slick-arrow-right {
    right: 0;
  }
  .slick-arrow.slick-disabled {
    color: #677788;
  }
  .slick-arrow-primary {
    color: #fff;
    background-color: rgba(55, 125, 255, 0.8);
  }
  .slick-arrow-soft-white {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .slick-arrow-soft-white:hover {
    color: #fff;
    background-color: #377dff;
  }
  @media (min-width: 992px) {
    .slick-arrow-offset.slick-arrow-left {
      left: -2.6875rem;
    }
    .slick-arrow-offset.slick-arrow-right {
      right: -2.6875rem;
    }
  }
  .slick-arrow-centered-y {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .slick-counter {
    position: absolute;
    bottom: 2rem;
    right: 0;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .slick-counter-current {
    color: #fff;
    font-size: 3.875rem;
    line-height: 1;
  }
  .slick-counter-divider {
    color: rgba(255, 255, 255, 0.7);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .slick-counter-total {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.7);
  }
  .slick-dots {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-dots li {
    cursor: pointer;
    margin: 0 0.3125rem;
  }
  .slick-dots .dot-line {
    position: relative;
    display: block;
    width: 2.75rem;
    height: 0.1875rem;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .slick-dots .dot-line-helper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0;
    height: 100%;
    background-color: #377dff;
    transition: none;
  }
  .slick-line-dots-ready .slick-dots li.slick-active .dot-line-helper {
    transition-property: width;
    transition-timing-function: linear;
    width: 100%;
  }
  .slick-pagination {
    padding-left: 0;
    margin-bottom: 0;
  }
  .slick-pagination:not(.slick-pagination-vertical) {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  .slick-pagination li {
    display: flex;
    pointer-events: all;
    margin: 0 0.25rem;
    cursor: pointer;
  }
  .slick-pagination li span {
    display: inline-block;
    width: 0.9375rem;
    height: 0.9375rem;
    box-sizing: border-box;
    background-color: #377dff;
    border: 0.1875rem solid transparent;
    border-radius: 50%;
    transform: scale(0.6);
    transition: 0.3s;
  }
  .slick-pagination li.slick-active span {
    background-color: transparent;
    border-color: #377dff;
    transform: scale(0.9);
  }
  .slick-pagination-white li span {
    background-color: #fff;
  }
  .slick-pagination-white li.slick-active span {
    border-color: #fff;
  }
  .slick-pagination-vertical li {
    display: block;
  }
  .slick-pagination-interactive-title {
    color: #fff;
  }
  .slick-pagination-interactive-text {
    color: rgba(255, 255, 255, 0.7);
  }
  .slick-pagination-interactive .slick-slide {
    cursor: pointer;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    transition: 0.3s;
  }
  .slick-pagination-interactive .slick-slide:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .slick-pagination-interactive .slick-slide:hover.slick-center {
    background-color: #fff;
  }
  .slick-pagination-interactive .slick-center {
    background-color: #fff;
  }
  .slick-pagination-interactive .slick-center .slick-pagination-interactive-title {
    color: #377dff;
  }
  .slick-pagination-interactive .slick-center .slick-pagination-interactive-text {
    color: #677788;
  }
  .slick-pagination-modern {
    width: 100%;
    max-width: 12.5rem;
  }
  .slick-pagination-modern .slick-slide {
    cursor: pointer;
    opacity: 0.7;
    transform: scale(0.7);
    backface-visibility: hidden;
    transition: 0.3s;
  }
  .slick-pagination-modern .slick-current {
    opacity: 1;
    transform: scale(1);
  }
  .slick-pagination-line .slick-slide {
    opacity: 0.5;
    cursor: pointer;
    font-weight: 400;
    padding-bottom: 0.25rem;
  }
  .slick-pagination-line .slick-slide.slick-current {
    opacity: 1;
  }
  .slick-pagination-line-progress {
    position: relative;
    display: block;
    width: 100%;
    height: 0.1875rem;
    background-color: #fff;
    margin-top: 0.5rem;
  }
  .slick-pagination-line-progress-helper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0;
    height: 100%;
    transition: none;
  }
  .slick-pagination-line.slick-dots-ready .slick-current .slick-pagination-line-progress-helper {
    width: 100%;
    background-color: #377dff;
    transition-property: width;
    transition-timing-function: linear;
  }
  @media (max-width: 991.98px) {
    .slick-pagination-line-wrapper {
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
    }
  }
  .spinner-border,
  .spinner-grow {
    vertical-align: middle;
  }
  .stats-progress {
    position: relative;
    width: 13.75rem;
    height: 13.75rem;
    border-radius: 50%;
    box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
    margin-left: auto;
    margin-right: auto;
  }
  .stats-progress-info {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    padding: 2rem 2rem;
    transform: translate(0, -50%);
  }
  .step {
    position: relative;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .step.step-dashed .step-icon::after {
    border-left-style: dashed;
  }
  .step-title {
    display: block;
    color: #1e2022;
    font-weight: 600;
  }
  .step-text:last-child {
    color: #677788;
    margin-bottom: 0;
  }
  .step-border-last-0 .step-item:last-child .step-icon::after {
    display: none;
  }
  .step .step-item {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    margin-bottom: 1.5rem;
  }
  .step-item-between .step-item:last-child {
    flex: 0 0 auto;
    width: auto;
  }
  .step .step-content-wrapper {
    position: relative;
    display: flex;
    width: 100%;
  }
  .step .step-content {
    flex: 1;
  }
  .step-item.collapse:not(.show) {
    display: none;
  }
  .step-item .step-title-description {
    display: none;
  }
  .step-item.focus .step-title-description {
    display: block;
  }
  .step .step-avatar {
    font-size: 1rem;
    font-weight: 600;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .step .step-avatar-img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
  .step .step-avatar::after {
    position: absolute;
    top: 3.59375rem;
    left: 1.5625rem;
    height: calc(100% - 2.65625rem);
    border-left: 0.125rem solid #e7eaf3;
    content: '';
  }
  .step-avatar-xs .step-avatar,
  .step-avatar-xs.step-avatar {
    font-size: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
  }
  .step-avatar-xs .step-avatar::after,
  .step-avatar-xs.step-avatar::after {
    top: 2.21875rem;
    left: 0.8125rem;
    width: 1.0625rem;
    height: calc(100% - 1.28125rem);
  }
  .step-avatar-xs .step-divider::after {
    left: 0.875rem;
  }
  .step-avatar-sm .step-avatar,
  .step-avatar-sm.step-avatar {
    font-size: 0.8125rem;
    width: 2.6875rem;
    height: 2.6875rem;
  }
  .step-avatar-sm .step-avatar::after,
  .step-avatar-sm.step-avatar::after {
    top: 3.15625rem;
    left: 1.28125rem;
    width: 1.0625rem;
    height: calc(100% - 2.21875rem);
  }
  .step-avatar-sm .step-divider::after {
    left: 1.34375rem;
  }
  .step-avatar-lg .step-avatar,
  .step-avatar-lg.step-avatar {
    font-size: 1.25rem;
    width: 4.25rem;
    height: 4.25rem;
  }
  .step-avatar-lg .step-avatar::after,
  .step-avatar-lg.step-avatar::after {
    top: 4.71875rem;
    left: 2.0625rem;
    width: 1.0625rem;
    height: calc(100% - 3.78125rem);
  }
  .step-avatar-lg .step-divider::after {
    left: 2.125rem;
  }
  .step-divider {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    height: 1rem;
    font-weight: 600;
  }
  .step-divider::after {
    position: absolute;
    top: 1.46875rem;
    left: 1.5625rem;
    height: calc(100% - 0.53125rem);
    border-left: 0.125rem solid #e7eaf3;
    content: '';
  }
  .step .step-icon {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .step .step-icon::after {
    position: absolute;
    top: 3.59375rem;
    left: 1.5625rem;
    height: calc(100% - 2.65625rem);
    border-left: 0.125rem solid #e7eaf3;
    content: '';
  }
  .step .step-icon-pseudo::before {
    display: block;
    width: 0.25rem;
    height: 0.25rem;
    background-color: #97a4af;
    border-radius: 50%;
    content: '';
  }
  .step-icon-xs .step-icon,
  .step-icon-xs.step-icon {
    font-size: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
  }
  .step-icon-xs .step-icon::after,
  .step-icon-xs.step-icon::after {
    top: 2.21875rem;
    left: 0.8125rem;
    width: 1.0625rem;
    height: calc(100% - 1.28125rem);
  }
  .step-icon-xs .step-divider::after {
    left: 0.875rem;
  }
  .step-icon-sm .step-icon,
  .step-icon-sm.step-icon {
    font-size: 0.8125rem;
    width: 2.6875rem;
    height: 2.6875rem;
  }
  .step-icon-sm .step-icon::after,
  .step-icon-sm.step-icon::after {
    top: 3.15625rem;
    left: 1.28125rem;
    width: 1.0625rem;
    height: calc(100% - 2.21875rem);
  }
  .step-icon-sm .step-divider::after {
    left: 1.34375rem;
  }
  .step-icon-lg .step-icon,
  .step-icon-lg.step-icon {
    font-size: 1.25rem;
    width: 4.25rem;
    height: 4.25rem;
  }
  .step-icon-lg .step-icon::after,
  .step-icon-lg.step-icon::after {
    top: 4.71875rem;
    left: 2.0625rem;
    width: 1.0625rem;
    height: calc(100% - 3.78125rem);
  }
  .step-icon-lg .step-divider::after {
    left: 2.125rem;
  }
  @media (min-width: 576px) {
    .step-sm.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }
    .step-sm .step-item {
      flex-grow: 1;
      flex: 1;
      margin-bottom: 0;
    }
    .step-sm:not(.step-inline) .step-content-wrapper {
      display: block;
    }
    .step-sm .step-icon {
      margin-bottom: 1rem;
    }
    .step-sm .step-icon::after {
      top: 1.5625rem;
      left: 4.0625rem;
      width: calc(100% - 4.0625rem);
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      border-left: none;
    }
    .step-sm .step-icon.step-icon-xs::after,
    .step-sm.step-icon-xs .step-icon::after {
      top: 0.875rem;
      left: 2.6875rem;
      width: calc(100% - 2.6875rem);
    }
    .step-sm .step-icon.step-icon-sm::after,
    .step-sm.step-icon-sm .step-icon::after {
      top: 1.34375rem;
      left: 3.625rem;
      width: calc(100% - 3.625rem);
    }
    .step-sm .step-icon.step-icon-lg::after,
    .step-sm.step-icon-lg .step-icon::after {
      top: 2.125rem;
      left: 5.1875rem;
      width: calc(100% - 5.1875rem);
    }
  }
  @media (min-width: 768px) {
    .step-md.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }
    .step-md .step-item {
      flex-grow: 1;
      flex: 1;
      margin-bottom: 0;
    }
    .step-md:not(.step-inline) .step-content-wrapper {
      display: block;
    }
    .step-md .step-icon {
      margin-bottom: 1rem;
    }
    .step-md .step-icon::after {
      top: 1.5625rem;
      left: 4.0625rem;
      width: calc(100% - 4.0625rem);
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      border-left: none;
    }
    .step-md .step-icon.step-icon-xs::after,
    .step-md.step-icon-xs .step-icon::after {
      top: 0.875rem;
      left: 2.6875rem;
      width: calc(100% - 2.6875rem);
    }
    .step-md .step-icon.step-icon-sm::after,
    .step-md.step-icon-sm .step-icon::after {
      top: 1.34375rem;
      left: 3.625rem;
      width: calc(100% - 3.625rem);
    }
    .step-md .step-icon.step-icon-lg::after,
    .step-md.step-icon-lg .step-icon::after {
      top: 2.125rem;
      left: 5.1875rem;
      width: calc(100% - 5.1875rem);
    }
  }
  @media (min-width: 992px) {
    .step-lg.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }
    .step-lg .step-item {
      flex-grow: 1;
      flex: 1;
      margin-bottom: 0;
    }
    .step-lg:not(.step-inline) .step-content-wrapper {
      display: block;
    }
    .step-lg .step-icon {
      margin-bottom: 1rem;
    }
    .step-lg .step-icon::after {
      top: 1.5625rem;
      left: 4.0625rem;
      width: calc(100% - 4.0625rem);
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      border-left: none;
    }
    .step-lg .step-icon.step-icon-xs::after,
    .step-lg.step-icon-xs .step-icon::after {
      top: 0.875rem;
      left: 2.6875rem;
      width: calc(100% - 2.6875rem);
    }
    .step-lg .step-icon.step-icon-sm::after,
    .step-lg.step-icon-sm .step-icon::after {
      top: 1.34375rem;
      left: 3.625rem;
      width: calc(100% - 3.625rem);
    }
    .step-lg .step-icon.step-icon-lg::after,
    .step-lg.step-icon-lg .step-icon::after {
      top: 2.125rem;
      left: 5.1875rem;
      width: calc(100% - 5.1875rem);
    }
  }
  @media (min-width: 1200px) {
    .step-xl.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }
    .step-xl .step-item {
      flex-grow: 1;
      flex: 1;
      margin-bottom: 0;
    }
    .step-xl:not(.step-inline) .step-content-wrapper {
      display: block;
    }
    .step-xl .step-icon {
      margin-bottom: 1rem;
    }
    .step-xl .step-icon::after {
      top: 1.5625rem;
      left: 4.0625rem;
      width: calc(100% - 4.0625rem);
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      border-left: none;
    }
    .step-xl .step-icon.step-icon-xs::after,
    .step-xl.step-icon-xs .step-icon::after {
      top: 0.875rem;
      left: 2.6875rem;
      width: calc(100% - 2.6875rem);
    }
    .step-xl .step-icon.step-icon-sm::after,
    .step-xl.step-icon-sm .step-icon::after {
      top: 1.34375rem;
      left: 3.625rem;
      width: calc(100% - 3.625rem);
    }
    .step-xl .step-icon.step-icon-lg::after,
    .step-xl.step-icon-lg .step-icon::after {
      top: 2.125rem;
      left: 5.1875rem;
      width: calc(100% - 5.1875rem);
    }
  }
  @media (min-width: 576px) {
    .step-sm.step-centered {
      text-align: center;
    }
    .step-sm.step-centered .step-item:last-child .step-icon::after {
      display: none;
    }
    .step-sm.step-centered .step-icon {
      margin-left: auto;
      margin-right: auto;
    }
    .step-sm.step-centered .step-icon::after {
      width: calc(100% - 3.125rem);
      left: calc(50% + 2.5rem);
    }
    .step-sm.step-centered .step-icon.step-icon-xs::after,
    .step-sm.step-centered.step-icon-xs .step-icon::after {
      width: calc(100% - 1.75rem);
      left: calc(50% + 1.8125rem);
    }
    .step-sm.step-centered .step-icon.step-icon-sm::after,
    .step-sm.step-centered.step-icon-sm .step-icon::after {
      width: calc(100% - 2.6875rem);
      left: calc(50% + 2.28125rem);
    }
    .step-sm.step-centered .step-icon.step-icon-lg::after,
    .step-sm.step-centered.step-icon-lg .step-icon::after {
      width: calc(100% - 4.25rem);
      left: calc(50% + 3.0625rem);
    }
  }
  @media (min-width: 768px) {
    .step-md.step-centered {
      text-align: center;
    }
    .step-md.step-centered .step-item:last-child .step-icon::after {
      display: none;
    }
    .step-md.step-centered .step-icon {
      margin-left: auto;
      margin-right: auto;
    }
    .step-md.step-centered .step-icon::after {
      width: calc(100% - 3.125rem);
      left: calc(50% + 2.5rem);
    }
    .step-md.step-centered .step-icon.step-icon-xs::after,
    .step-md.step-centered.step-icon-xs .step-icon::after {
      width: calc(100% - 1.75rem);
      left: calc(50% + 1.8125rem);
    }
    .step-md.step-centered .step-icon.step-icon-sm::after,
    .step-md.step-centered.step-icon-sm .step-icon::after {
      width: calc(100% - 2.6875rem);
      left: calc(50% + 2.28125rem);
    }
    .step-md.step-centered .step-icon.step-icon-lg::after,
    .step-md.step-centered.step-icon-lg .step-icon::after {
      width: calc(100% - 4.25rem);
      left: calc(50% + 3.0625rem);
    }
  }
  @media (min-width: 992px) {
    .step-lg.step-centered {
      text-align: center;
    }
    .step-lg.step-centered .step-item:last-child .step-icon::after {
      display: none;
    }
    .step-lg.step-centered .step-icon {
      margin-left: auto;
      margin-right: auto;
    }
    .step-lg.step-centered .step-icon::after {
      width: calc(100% - 3.125rem);
      left: calc(50% + 2.5rem);
    }
    .step-lg.step-centered .step-icon.step-icon-xs::after,
    .step-lg.step-centered.step-icon-xs .step-icon::after {
      width: calc(100% - 1.75rem);
      left: calc(50% + 1.8125rem);
    }
    .step-lg.step-centered .step-icon.step-icon-sm::after,
    .step-lg.step-centered.step-icon-sm .step-icon::after {
      width: calc(100% - 2.6875rem);
      left: calc(50% + 2.28125rem);
    }
    .step-lg.step-centered .step-icon.step-icon-lg::after,
    .step-lg.step-centered.step-icon-lg .step-icon::after {
      width: calc(100% - 4.25rem);
      left: calc(50% + 3.0625rem);
    }
  }
  @media (min-width: 992px) {
    .step-lg.step-centered {
      text-align: center;
    }
    .step-lg.step-centered .step-item:last-child .step-icon::after {
      display: none;
    }
    .step-lg.step-centered .step-icon {
      margin-left: auto;
      margin-right: auto;
    }
    .step-lg.step-centered .step-icon::after {
      width: calc(100% - 3.125rem);
      left: calc(50% + 2.5rem);
    }
    .step-lg.step-centered .step-icon.step-icon-xs::after,
    .step-lg.step-centered.step-icon-xs .step-icon::after {
      width: calc(100% - 1.75rem);
      left: calc(50% + 1.8125rem);
    }
    .step-lg.step-centered .step-icon.step-icon-sm::after,
    .step-lg.step-centered.step-icon-sm .step-icon::after {
      width: calc(100% - 2.6875rem);
      left: calc(50% + 2.28125rem);
    }
    .step-lg.step-centered .step-icon.step-icon-lg::after,
    .step-lg.step-centered.step-icon-lg .step-icon::after {
      width: calc(100% - 4.25rem);
      left: calc(50% + 3.0625rem);
    }
  }
  .step .step-is-invalid-icon,
  .step .step-is-valid-icon {
    display: none;
  }
  .step .active .step-icon,
  .step .active.is-valid .step-icon {
    color: #fff;
    background-color: #377dff;
  }
  .step .active .step-title,
  .step .active.is-valid .step-title {
    color: #377dff;
  }
  .step .is-valid .step-icon {
    color: #fff;
    background-color: #377dff;
  }
  .step .is-valid .step-title {
    color: #377dff;
  }
  .step .is-valid .step-is-valid-icon {
    display: inline-flex;
  }
  .step .is-valid .step-is-default-icon,
  .step .is-valid .step-is-invalid-icon {
    display: none;
  }
  .step .is-invalid .step-icon {
    color: #fff;
    background-color: #ed4c78;
  }
  .step .is-invalid .step-title {
    color: #ed4c78;
  }
  .step .is-invalid .step-is-invalid-icon {
    display: inline-flex;
  }
  .step .is-invalid .step-is-default-icon,
  .step .is-invalid .step-is-valid-icon {
    display: none;
  }
  .step-icon-primary {
    color: #fff;
    background-color: #377dff;
  }
  .step-icon-primary.step-icon-pseudo::before {
    background-color: #fff;
  }
  .step-icon-secondary {
    color: #fff;
    background-color: #71869d;
  }
  .step-icon-secondary.step-icon-pseudo::before {
    background-color: #fff;
  }
  .step-icon-success {
    color: #fff;
    background-color: #00c9a7;
  }
  .step-icon-success.step-icon-pseudo::before {
    background-color: #fff;
  }
  .step-icon-info {
    color: #fff;
    background-color: #09a5be;
  }
  .step-icon-info.step-icon-pseudo::before {
    background-color: #fff;
  }
  .step-icon-warning {
    color: #1e2022;
    background-color: #f5ca99;
  }
  .step-icon-warning.step-icon-pseudo::before {
    background-color: #1e2022;
  }
  .step-icon-danger {
    color: #fff;
    background-color: #ed4c78;
  }
  .step-icon-danger.step-icon-pseudo::before {
    background-color: #fff;
  }
  .step-icon-light {
    color: #1e2022;
    background-color: #f7faff;
  }
  .step-icon-light.step-icon-pseudo::before {
    background-color: #1e2022;
  }
  .step-icon-dark {
    color: #fff;
    background-color: #21325b;
  }
  .step-icon-dark.step-icon-pseudo::before {
    background-color: #fff;
  }
  .step-icon-indigo {
    color: #fff;
    background-color: #2d1582;
  }
  .step-icon-indigo.step-icon-pseudo::before {
    background-color: #fff;
  }
  .step-icon-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
  }
  .step-icon-soft-primary.step-icon-pseudo::before {
    background-color: #377dff;
  }
  .step-icon-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, 0.1);
  }
  .step-icon-soft-secondary.step-icon-pseudo::before {
    background-color: #71869d;
  }
  .step-icon-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
  }
  .step-icon-soft-success.step-icon-pseudo::before {
    background-color: #00c9a7;
  }
  .step-icon-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, 0.1);
  }
  .step-icon-soft-info.step-icon-pseudo::before {
    background-color: #09a5be;
  }
  .step-icon-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
  }
  .step-icon-soft-warning.step-icon-pseudo::before {
    background-color: #f5ca99;
  }
  .step-icon-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, 0.1);
  }
  .step-icon-soft-danger.step-icon-pseudo::before {
    background-color: #ed4c78;
  }
  .step-icon-soft-light {
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0.1);
  }
  .step-icon-soft-light.step-icon-pseudo::before {
    background-color: #f7faff;
  }
  .step-icon-soft-dark {
    color: #21325b;
    background-color: rgba(33, 50, 91, 0.1);
  }
  .step-icon-soft-dark.step-icon-pseudo::before {
    background-color: #21325b;
  }
  .step-icon-soft-indigo {
    color: #2d1582;
    background-color: rgba(45, 21, 130, 0.1);
  }
  .step-icon-soft-indigo.step-icon-pseudo::before {
    background-color: #2d1582;
  }
  .step-inline .step-content-wrapper {
    align-items: center;
  }
  .step-inline .step-item:last-child .step-title::after {
    display: none;
  }
  .step-inline .step-title {
    display: inline-block;
  }
  @media (min-width: 576px) {
    .step-sm.step-inline.step-dashed .step-title::after {
      border-top-style: dashed;
    }
    .step-sm.step-inline .step-item {
      overflow: hidden;
    }
    .step-sm.step-inline .step-icon {
      margin-bottom: 0;
    }
    .step-sm.step-inline .step-icon::after {
      display: none;
    }
    .step-sm.step-inline .step-title::after {
      position: absolute;
      top: 1.5625rem;
      width: 100%;
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      margin-left: 0.9375rem;
      content: '';
    }
    .step-sm.step-inline .step-icon-xs + .step-content .step-title::after,
    .step-sm.step-inline.step-icon-xs .step-content .step-title::after {
      top: 0.875rem;
    }
    .step-sm.step-inline .step-icon-sm + .step-content .step-title::after,
    .step-sm.step-inline.step-icon-sm .step-content .step-title::after {
      top: 1.34375rem;
    }
    .step-sm.step-inline .step-icon-lg + .step-content .step-title::after,
    .step-sm.step-inline.step-icon-lg .step-content .step-title::after {
      top: 2.125rem;
    }
  }
  @media (min-width: 768px) {
    .step-md.step-inline.step-dashed .step-title::after {
      border-top-style: dashed;
    }
    .step-md.step-inline .step-item {
      overflow: hidden;
    }
    .step-md.step-inline .step-icon {
      margin-bottom: 0;
    }
    .step-md.step-inline .step-icon::after {
      display: none;
    }
    .step-md.step-inline .step-title::after {
      position: absolute;
      top: 1.5625rem;
      width: 100%;
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      margin-left: 0.9375rem;
      content: '';
    }
    .step-md.step-inline .step-icon-xs + .step-content .step-title::after,
    .step-md.step-inline.step-icon-xs .step-content .step-title::after {
      top: 0.875rem;
    }
    .step-md.step-inline .step-icon-sm + .step-content .step-title::after,
    .step-md.step-inline.step-icon-sm .step-content .step-title::after {
      top: 1.34375rem;
    }
    .step-md.step-inline .step-icon-lg + .step-content .step-title::after,
    .step-md.step-inline.step-icon-lg .step-content .step-title::after {
      top: 2.125rem;
    }
  }
  @media (min-width: 992px) {
    .step-lg.step-inline.step-dashed .step-title::after {
      border-top-style: dashed;
    }
    .step-lg.step-inline .step-item {
      overflow: hidden;
    }
    .step-lg.step-inline .step-icon {
      margin-bottom: 0;
    }
    .step-lg.step-inline .step-icon::after {
      display: none;
    }
    .step-lg.step-inline .step-title::after {
      position: absolute;
      top: 1.5625rem;
      width: 100%;
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      margin-left: 0.9375rem;
      content: '';
    }
    .step-lg.step-inline .step-icon-xs + .step-content .step-title::after,
    .step-lg.step-inline.step-icon-xs .step-content .step-title::after {
      top: 0.875rem;
    }
    .step-lg.step-inline .step-icon-sm + .step-content .step-title::after,
    .step-lg.step-inline.step-icon-sm .step-content .step-title::after {
      top: 1.34375rem;
    }
    .step-lg.step-inline .step-icon-lg + .step-content .step-title::after,
    .step-lg.step-inline.step-icon-lg .step-content .step-title::after {
      top: 2.125rem;
    }
  }
  @media (min-width: 1200px) {
    .step-xl.step-inline.step-dashed .step-title::after {
      border-top-style: dashed;
    }
    .step-xl.step-inline .step-item {
      overflow: hidden;
    }
    .step-xl.step-inline .step-icon {
      margin-bottom: 0;
    }
    .step-xl.step-inline .step-icon::after {
      display: none;
    }
    .step-xl.step-inline .step-title::after {
      position: absolute;
      top: 1.5625rem;
      width: 100%;
      height: 1.0625rem;
      border-top: 0.125rem solid #e7eaf3;
      margin-left: 0.9375rem;
      content: '';
    }
    .step-xl.step-inline .step-icon-xs + .step-content .step-title::after,
    .step-xl.step-inline.step-icon-xs .step-content .step-title::after {
      top: 0.875rem;
    }
    .step-xl.step-inline .step-icon-sm + .step-content .step-title::after,
    .step-xl.step-inline.step-icon-sm .step-content .step-title::after {
      top: 1.34375rem;
    }
    .step-xl.step-inline .step-icon-lg + .step-content .step-title::after,
    .step-xl.step-inline.step-icon-lg .step-content .step-title::after {
      top: 2.125rem;
    }
  }
  @media (min-width: 576px) {
    .step-timeline-sm {
      margin-left: 0;
      margin-right: 0;
    }
    .step-timeline-sm .step-item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;
    }
    .step-timeline-sm .step-item:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;
      margin-left: auto;
      margin-right: 50%;
    }
    .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
      flex-direction: row-reverse;
    }
    .step-timeline-sm .step-item:nth-child(even) .step-icon {
      margin-left: 0;
      margin-right: -1.5625rem;
    }
    .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
      margin-right: -0.875rem;
    }
    .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
      margin-right: -1.34375rem;
    }
    .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
      margin-right: -2.125rem;
    }
    .step-timeline-sm .step-item:nth-child(even) .step-content {
      margin-right: 1.5rem;
    }
    .step-timeline-sm .step-icon {
      margin-left: -1.5625rem;
    }
    .step-timeline-sm .step-icon::after {
      left: auto;
      width: auto;
    }
    .step-timeline-sm .step-icon-xs {
      margin-left: -0.875rem;
    }
    .step-timeline-sm .step-icon-sm {
      margin-left: -1.34375rem;
    }
    .step-timeline-sm .step-icon-lg {
      margin-left: -2.125rem;
    }
  }
  @media (min-width: 768px) {
    .step-timeline-md {
      margin-left: 0;
      margin-right: 0;
    }
    .step-timeline-md .step-item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;
    }
    .step-timeline-md .step-item:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;
      margin-left: auto;
      margin-right: 50%;
    }
    .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
      flex-direction: row-reverse;
    }
    .step-timeline-md .step-item:nth-child(even) .step-icon {
      margin-left: 0;
      margin-right: -1.5625rem;
    }
    .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
      margin-right: -0.875rem;
    }
    .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
      margin-right: -1.34375rem;
    }
    .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
      margin-right: -2.125rem;
    }
    .step-timeline-md .step-item:nth-child(even) .step-content {
      margin-right: 1.5rem;
    }
    .step-timeline-md .step-icon {
      margin-left: -1.5625rem;
    }
    .step-timeline-md .step-icon::after {
      left: auto;
      width: auto;
    }
    .step-timeline-md .step-icon-xs {
      margin-left: -0.875rem;
    }
    .step-timeline-md .step-icon-sm {
      margin-left: -1.34375rem;
    }
    .step-timeline-md .step-icon-lg {
      margin-left: -2.125rem;
    }
  }
  @media (min-width: 992px) {
    .step-timeline-lg {
      margin-left: 0;
      margin-right: 0;
    }
    .step-timeline-lg .step-item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;
    }
    .step-timeline-lg .step-item:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;
      margin-left: auto;
      margin-right: 50%;
    }
    .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
      flex-direction: row-reverse;
    }
    .step-timeline-lg .step-item:nth-child(even) .step-icon {
      margin-left: 0;
      margin-right: -1.5625rem;
    }
    .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
      margin-right: -0.875rem;
    }
    .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
      margin-right: -1.34375rem;
    }
    .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
      margin-right: -2.125rem;
    }
    .step-timeline-lg .step-item:nth-child(even) .step-content {
      margin-right: 1.5rem;
    }
    .step-timeline-lg .step-icon {
      margin-left: -1.5625rem;
    }
    .step-timeline-lg .step-icon::after {
      left: auto;
      width: auto;
    }
    .step-timeline-lg .step-icon-xs {
      margin-left: -0.875rem;
    }
    .step-timeline-lg .step-icon-sm {
      margin-left: -1.34375rem;
    }
    .step-timeline-lg .step-icon-lg {
      margin-left: -2.125rem;
    }
  }
  @media (min-width: 1200px) {
    .step-timeline-xl {
      margin-left: 0;
      margin-right: 0;
    }
    .step-timeline-xl .step-item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;
    }
    .step-timeline-xl .step-item:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;
      margin-left: auto;
      margin-right: 50%;
    }
    .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
      flex-direction: row-reverse;
    }
    .step-timeline-xl .step-item:nth-child(even) .step-icon {
      margin-left: 0;
      margin-right: -1.5625rem;
    }
    .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
      margin-right: -0.875rem;
    }
    .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
      margin-right: -1.34375rem;
    }
    .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
      margin-right: -2.125rem;
    }
    .step-timeline-xl .step-item:nth-child(even) .step-content {
      margin-right: 1.5rem;
    }
    .step-timeline-xl .step-icon {
      margin-left: -1.5625rem;
    }
    .step-timeline-xl .step-icon::after {
      left: auto;
      width: auto;
    }
    .step-timeline-xl .step-icon-xs {
      margin-left: -0.875rem;
    }
    .step-timeline-xl .step-icon-sm {
      margin-left: -1.34375rem;
    }
    .step-timeline-xl .step-icon-lg {
      margin-left: -2.125rem;
    }
  }
  .table th {
    font-weight: 400;
  }
  .table thead th {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    border-width: 0;
  }
  .table .btn {
    white-space: nowrap;
  }
  .table-heighlighted thead th {
    border-bottom-color: #1e2022;
    border-top: none;
  }
  .table-heighlighted tfoot td {
    border-top: 0.125rem solid #1e2022;
  }
  .table-light-bordered {
    border-color: rgba(255, 255, 255, 0.3);
  }
  .table-light-bordered td,
  .table-light-bordered th,
  .table-light-bordered thead th {
    border-color: rgba(255, 255, 255, 0.3);
  }
  .table-nowrap td,
  .table-nowrap th {
    white-space: nowrap;
  }
  .table-align-middle tbody td {
    vertical-align: middle;
  }
  .table-thead-bordered thead th {
    border-top: 0.0625rem solid #e7eaf3;
    border-bottom: 0.0625rem solid #e7eaf3;
  }
  [class*='text-highlight-'] {
    background: left 1em/1em 0.2em;
  }
  .text-highlight-primary {
    background-image: linear-gradient(
      to bottom,
      rgba(55, 125, 255, 0.5) 0,
      rgba(55, 125, 255, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-secondary {
    background-image: linear-gradient(
      to bottom,
      rgba(113, 134, 157, 0.5) 0,
      rgba(113, 134, 157, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-success {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 201, 167, 0.5) 0,
      rgba(0, 201, 167, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-info {
    background-image: linear-gradient(
      to bottom,
      rgba(9, 165, 190, 0.5) 0,
      rgba(9, 165, 190, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-warning {
    background-image: linear-gradient(
      to bottom,
      rgba(245, 202, 153, 0.5) 0,
      rgba(245, 202, 153, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-danger {
    background-image: linear-gradient(
      to bottom,
      rgba(237, 76, 120, 0.5) 0,
      rgba(237, 76, 120, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-light {
    background-image: linear-gradient(
      to bottom,
      rgba(247, 250, 255, 0.5) 0,
      rgba(247, 250, 255, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-dark {
    background-image: linear-gradient(
      to bottom,
      rgba(33, 50, 91, 0.5) 0,
      rgba(33, 50, 91, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-indigo {
    background-image: linear-gradient(
      to bottom,
      rgba(45, 21, 130, 0.5) 0,
      rgba(45, 21, 130, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  [class*='sticky-block'] {
    max-width: 100%;
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .media {
      width: 100%;
    }
    .ie-slick-equal-height .slick-track .slick-slide {
      display: block;
    }
    .ie-main-hero {
      height: 686px;
    }
    .ie-showcase-mockup {
      height: 373px;
    }
    .ie-device-and-mobile {
      height: 638px;
    }
    .ie-curved-y {
      height: 26px;
    }
    .ie-curved-x {
      width: 26px;
      height: 370px;
      margin-right: -4px;
    }
  }
  .gradient-y-sm-primary {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0,
      rgba(55, 125, 255, 0.075) 100%
    );
    background-repeat: repeat-x;
  }
  .gradient-y-gray {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0,
      rgba(231, 234, 243, 0.5) 100%
    );
    background-repeat: repeat-x;
  }
  .gradient-y-lg-white {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
    background-repeat: repeat-x;
  }
  .gradient-y-three-sm-primary {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(55, 125, 255, 0.075) 50%,
      rgba(255, 255, 255, 0)
    );
    background-repeat: no-repeat;
  }
  .gradient-x-three-sm-primary {
    background-image: linear-gradient(
      to right,
      rgba(55, 125, 255, 0.03),
      rgba(9, 165, 190, 0.05) 50%,
      rgba(245, 202, 153, 0.05)
    );
    background-repeat: no-repeat;
  }
  [class*='gradient-x-overlay-'],
  [class*='gradient-y-overlay-'],
  [class*='gradient-y-three-overlay-'] {
    position: relative;
    z-index: 1;
  }
  [class*='gradient-x-overlay-']::before,
  [class*='gradient-y-overlay-']::before,
  [class*='gradient-y-three-overlay-']::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
  }
  .gradient-x-overlay-lg-dark-video::before {
    z-index: 2;
    background-image: linear-gradient(
      to right,
      rgba(33, 50, 91, 0.95) 0,
      rgba(33, 50, 91, 0.95) 100%
    );
    background-repeat: repeat-x;
  }
  .gradient-x-overlay-sm-dark::before {
    background-image: linear-gradient(
      to right,
      rgba(33, 50, 91, 0.25) 0,
      rgba(30, 32, 34, 0.25) 100%
    );
    background-repeat: repeat-x;
  }
  .gradient-x-overlay-lg-dark::before {
    background-image: linear-gradient(
      to right,
      rgba(33, 50, 91, 0.9) 0,
      rgba(33, 50, 91, 0.9) 100%
    );
    background-repeat: repeat-x;
  }
  .gradient-y-overlay-lg-dark::before {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 40%,
      rgba(12, 13, 14, 0.6) 100%
    );
    background-repeat: repeat-x;
  }
  .gradient-x-overlay-sm-indigo::before {
    background-image: linear-gradient(45deg, transparent, rgba(45, 21, 130, 0.05));
    background-repeat: repeat-x;
  }
  .gradient-y-overlay-lg-white::before {
    background-image: linear-gradient(to bottom, #fff 0, rgba(255, 255, 255, 0.9) 100%);
    background-repeat: repeat-x;
  }
  .gradient-y-overlay-sm-gray-900::before {
    background-image: linear-gradient(
      to bottom,
      rgba(30, 32, 34, 0.3) 0,
      rgba(33, 50, 91, 0.2) 100%
    );
    background-repeat: repeat-x;
  }
  .bg-img-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .bg-img-hero-bottom {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
  .bg-img-hero-center {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .bg-img-hero-fixed {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
  }
  .bg-soft-primary {
    background-color: rgba(55, 125, 255, 0.1);
  }
  .bg-soft-secondary {
    background-color: rgba(113, 134, 157, 0.1);
  }
  .bg-soft-success {
    background-color: rgba(0, 201, 167, 0.1);
  }
  .bg-soft-info {
    background-color: rgba(9, 165, 190, 0.1);
  }
  .bg-soft-warning {
    background-color: rgba(245, 202, 153, 0.1);
  }
  .bg-soft-danger {
    background-color: rgba(237, 76, 120, 0.1);
  }
  .bg-soft-light {
    background-color: rgba(247, 250, 255, 0.1);
  }
  .bg-soft-dark {
    background-color: rgba(33, 50, 91, 0.1);
  }
  .bg-soft-indigo {
    background-color: rgba(45, 21, 130, 0.1);
  }
  .rounded-pseudo {
    border-radius: 0.3125rem;
  }
  .rounded-pseudo::after,
  .rounded-pseudo::before {
    border-radius: 0.3125rem;
  }
  .rounded-lg-pseudo {
    border-radius: 0.75rem;
  }
  .rounded-lg-pseudo::after,
  .rounded-lg-pseudo::before {
    border-radius: 0.75rem;
  }
  .border-dashed {
    border: 0.0625rem dashed #e7eaf3;
  }
  .border-3 {
    border-width: 0.1875rem !important;
  }
  .top-auto {
    top: auto;
  }
  .bottom-auto {
    bottom: auto;
  }
  .right-auto {
    right: auto;
  }
  .left-auto {
    left: auto;
  }
  .top-0 {
    top: 0;
  }
  .right-0 {
    right: 0;
  }
  .bottom-0 {
    bottom: 0;
  }
  .left-0 {
    left: 0;
  }
  .top-50 {
    top: 50%;
  }
  .right-50 {
    right: 50%;
  }
  .bottom-50 {
    bottom: 50%;
  }
  .left-50 {
    left: 50%;
  }
  @media (min-width: 576px) {
    .position-sm-absolute {
      position: absolute;
    }
  }
  @media (min-width: 768px) {
    .top-md-0 {
      top: 0;
    }
    .right-md-0 {
      right: 0;
    }
    .bottom-md-0 {
      bottom: 0;
    }
    .left-md-0 {
      left: 0;
    }
    .position-md-absolute {
      position: absolute;
    }
  }
  @media (min-width: 992px) {
    .top-lg-0 {
      top: 0;
    }
    .right-lg-0 {
      right: 0;
    }
    .bottom-lg-0 {
      bottom: 0;
    }
    .left-lg-0 {
      left: 0;
    }
    .position-lg-absolute {
      position: absolute;
    }
  }
  .content-centered-y {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  @media (min-width: 768px) {
    .content-centered-y-md {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  @media (min-width: 992px) {
    .content-centered-y-lg {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  .opacity-xs {
    opacity: 0.2;
  }
  .opacity-sm {
    opacity: 0.4;
  }
  .opacity {
    opacity: 0.6;
  }
  .opacity-lg {
    opacity: 0.8;
  }
  .shadow-primary-lg {
    box-shadow: 0 0 50px rgba(55, 125, 255, 0.4) !important;
  }
  .shadow-soft {
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
  }
  .min-w-3rem {
    min-width: 1rem;
  }
  .min-w-4rem {
    min-width: 1.5rem;
  }
  .min-w-8rem {
    min-width: 3.5rem;
  }
  .min-w-21rem {
    min-width: 10rem;
  }
  .max-w-3rem {
    max-width: 1rem;
  }
  .max-w-4rem {
    max-width: 1.5rem;
  }
  .max-w-5rem {
    max-width: 2rem;
  }
  .max-w-6rem {
    max-width: 2.5rem;
  }
  .max-w-7rem {
    max-width: 3rem;
  }
  .max-w-8rem {
    max-width: 3.5rem;
  }
  .max-w-9rem {
    max-width: 4rem;
  }
  .max-w-10rem {
    max-width: 4.5rem;
  }
  .max-w-11rem {
    max-width: 5rem;
  }
  .max-w-13rem {
    max-width: 6rem;
  }
  .max-w-15rem {
    max-width: 7rem;
  }
  .max-w-19rem {
    max-width: 9rem;
  }
  .max-w-23rem {
    max-width: 11rem;
  }
  .max-w-27rem {
    max-width: 13rem;
  }
  .max-w-33rem {
    max-width: 16rem;
  }
  .max-w-35rem {
    max-width: 17rem;
  }
  .max-w-40rem {
    max-width: 19.5rem;
  }
  .max-w-50rem {
    max-width: 24.5rem;
  }
  .h-4rem {
    height: 0.25rem;
  }
  .h-250rem {
    height: 15.625rem;
  }
  .h-380rem {
    height: 23.75rem;
  }
  .min-h-270rem {
    min-height: 16.875rem;
  }
  .min-h-300rem {
    min-height: 18.75rem;
  }
  .min-h-380rem {
    min-height: 23.75rem;
  }
  .min-h-450rem {
    min-height: 28.125rem;
  }
  .min-h-500rem {
    min-height: 31.25rem;
  }
  .min-h-620rem {
    min-height: 38.75rem;
  }
  @media (min-width: 576px) {
    .max-w-sm-15rem {
      max-width: 7rem;
    }
    .max-w-sm-40rem {
      max-width: 19.5rem;
    }
  }
  @media (min-width: 768px) {
    .vh-md-30 {
      height: 30vh;
    }
    .vh-md-70 {
      height: 70vh;
    }
    .min-vh-md-100 {
      min-height: 100vh;
    }
    .max-w-md-13rem {
      max-width: 6rem;
    }
  }
  @media (min-width: 992px) {
    .vh-lg-100 {
      height: 100vh;
    }
    .min-vh-lg-100 {
      min-height: 100vh;
    }
    .min-h-lg-600rem {
      min-height: 37.5rem;
    }
  }
  .text-inherit {
    color: inherit;
  }
  .text-cap {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03125rem;
  }
  .text-lh-sm {
    line-height: 1.2;
  }
  .text-lh-lg {
    line-height: 1.8;
  }
  .font-size-1 {
    font-size: 0.875rem;
  }
  .font-size-2 {
    font-size: 1.5rem;
  }
  .font-size-3 {
    font-size: 2rem;
  }
  .font-size-4 {
    font-size: 2.5rem;
  }
  .font-size-5 {
    font-size: 3rem;
  }
  .text-dark {
    color: #1e2022 !important;
  }
  .text-dark[href]:hover {
    color: #377dff !important;
  }
  .text-secondary[href]:hover {
    color: #377dff !important;
  }
  .text-body[href]:hover,
  .text-muted[href]:hover {
    color: #0052ea !important;
  }
  .text-white-70 {
    color: rgba(255, 255, 255, 0.7);
  }
  .text-white-70[href]:hover {
    color: #fff;
  }
  a:hover .text-hover-primary {
    color: #0052ea !important;
  }
  .text-warning {
    color: #ec9a3c !important;
  }
  .transform-rotate-1 {
    transform: rotate(-40deg);
    transform-origin: 30% 20%;
  }
  .transform-rotate-2 {
    transform: rotate(-17deg);
    transform-origin: 0 0;
  }
  .transform-rotate-3 {
    transform: rotate(15deg);
    transform-origin: 90% -20%;
  }
  .transform-rotate-4 {
    transform-origin: 100% 0;
    transform: translateY(-50%);
  }
  .transform-rotate-5 {
    transform: rotate(-22deg);
  }
  .transform-rotate-6 {
    transform: translate3d(20%, -55%, 0);
  }
  .transition-3d-hover {
    transition: all 0.2s ease-in-out;
  }
  .transition-3d-hover:focus,
  .transition-3d-hover:hover {
    transform: translateY(-3px);
  }
  .transition-zoom-hover {
    transform: scale(1);
    transition: transform 0.3s;
  }
  .transition-zoom-hover:focus,
  .transition-zoom-hover:hover {
    transform: scale(1.05);
  }
  .z-index-n1 {
    z-index: -1;
  }
  .z-index-2 {
    z-index: 2;
  }
  .z-index-4 {
    z-index: 4;
  }
  .z-index-999 {
    z-index: 999;
  }
  .z-index-9999 {
    z-index: 9999;
  }
  .hs-unfold {
    position: relative;
    display: inline-block;
  }
  .hs-unfold-content {
    display: block !important;
    opacity: 0;
    pointer-events: none;
  }
  .hs-unfold-content-initialized {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .hs-unfold-hidden {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
  .hs-unfold-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    background-color: rgba(33, 50, 91, 0.125);
  }
  .hs-unfold-content.hs-unfold-reverse-y {
    top: auto;
    bottom: 100%;
    margin-top: 0;
  }
  .hs-unfold-has-submenu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.5rem;
  }
  .hs-video-bg-video video {
    object-fit: cover;
  }
  .hs-nav-scroller-horizontal {
    position: relative;
    z-index: 2;
  }
  .hs-nav-scroller-horizontal .nav {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    scroll-behavior: smooth;
  }
  .hs-nav-scroller-horizontal .nav .nav-item {
    white-space: nowrap;
  }
  .hs-nav-scroller-horizontal .nav::-webkit-scrollbar {
    display: none;
  }
  .hs-nav-scroller-horizontal .nav-tabs {
    padding-bottom: 2px;
  }
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next,
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
    position: absolute;
    height: 100%;
    z-index: 1;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link {
    width: 3.125rem;
    color: #677788;
  }
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link:hover {
    color: #0052ea;
  }
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
    left: 0;
    margin-left: -0.125rem;
  }
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link {
    padding: 0.5rem 0;
    background-image: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x;
  }
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
    right: 0;
    margin-right: -0.125rem;
  }
  .hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
    padding: 0.5rem 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 50%);
    background-repeat: repeat-x;
    text-align: right;
  }
  .hs-nav-scroller-vertical {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .hs-nav-scroller-vertical::-webkit-scrollbar {
    width: 0.5rem;
  }
  .hs-nav-scroller-vertical::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .hs-nav-scroller-unfold {
    position: static;
  }
  .hs-chartjs-tooltip-wrap {
    position: absolute;
    z-index: 3;
    transition: opacity 0.2s ease-in-out;
  }
  .hs-chartjs-tooltip {
    background-color: #fff;
    border: 0.0625rem solid #e7eaf3;
    box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
    border-radius: 0.3125rem;
    padding: 1rem 1rem;
  }
  .hs-chartjs-tooltip-header {
    font-weight: 600;
  }
  .hs-chartjs-tooltip-body {
    color: #677788;
  }
  @media (max-width: 991.98px) {
    .cbp-filter-scrollbar {
      max-width: 100%;
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
    }
    .cbp-filter-scrollbar::-webkit-scrollbar {
      height: 0.25rem;
    }
    .cbp-filter-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .cbp-filter-scrollbar:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  .cbp-filter-item-active.label.label-soft-secondary {
    color: #fff;
    background-color: #377dff;
  }
  .cbp-filter-item-active.nav-link {
    color: #377dff;
  }
  .cbp-l-loadMore-stop {
    pointer-events: none;
  }
  .cbp-caption-zoom .cbp-caption-defaultWrap {
    transition: transform 0.2s ease-out;
  }
  .cbp-caption-zoom .cbp-caption-activeWrap {
    background-color: rgba(33, 50, 91, 0.5);
  }
  .cbp-caption-zoom .cbp-caption:hover .cbp-caption-defaultWrap {
    transform: scale(1.1);
  }
  .dz-dropzone {
    cursor: pointer;
  }
  .dz-dropzone-boxed {
    width: 100%;
    background-color: #f8fafd;
    border: 0.125rem dashed #e7eaf3;
    border-radius: 0.3125rem;
    padding: 3rem 3rem;
    display: flex;
    flex-wrap: wrap;
  }
  .dz-dropzone .dz-message {
    width: 100%;
    text-align: center;
  }
  .dz-dropzone .dz-details {
    margin-bottom: 1rem;
  }
  .dz-dropzone .dz-file-preview {
    background-color: #fff;
    border-radius: 0.3125rem;
    padding: 1rem 1rem;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .dz-dropzone .dz-file-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dz-dropzone .dz-filename {
    margin-bottom: 0.25rem;
  }
  .dz-dropzone .dz-img {
    max-width: 3.125rem;
    border-radius: 0.3125rem;
    margin-right: 0.75rem;
  }
  .dz-dropzone .dz-img-inner {
    border-radius: 0.3125rem;
  }
  .dz-dropzone .dz-close-icon,
  .dz-dropzone .dz-size {
    color: #677788;
  }
  .dz-dropzone .dz-title {
    font-size: 0.75rem;
    font-weight: 600;
  }
  .dz-dropzone .dz-size {
    font-size: 80%;
  }
  .dz-dropzone .dz-file-initials {
    display: inline-block;
    width: 3.125rem;
    height: 3.125rem;
    line-height: 3.125rem;
    font-weight: 600;
    font-size: 1rem;
    color: #377dff;
    text-align: center;
    background-color: rgba(55, 125, 255, 0.1);
    border-radius: 0.3125rem;
    margin-right: 0.75rem;
  }
  .dz-dropzone [data-dz-thumbnail]:not([src]) {
    display: none;
    margin-bottom: 0;
  }
  .dz-dropzone .dz-progress {
    margin-bottom: 1rem;
  }
  .dz-dropzone .dz-processing .dz-error-mark,
  .dz-dropzone .dz-processing .dz-success-mark {
    display: none;
  }
  .dz-dropzone .dz-processing.dz-error .dz-error-mark,
  .dz-dropzone .dz-processing.dz-success .dz-success-mark {
    display: block;
  }
  .dz-dropzone .dz-processing .dz-error-mark {
    color: #ed4c78;
  }
  .dz-dropzone .dz-processing .dz-success-mark {
    color: #00c9a7;
  }
  .dzsparallaxer {
    color: inherit;
  }
  .fancybox-theme .fancybox-slide.animated {
    display: block;
    opacity: 0;
    z-index: 0;
  }
  .fancybox-theme .fancybox-slide.animated.fancybox-slide--current {
    opacity: 1;
    z-index: 1;
  }
  .fancybox-theme .fancybox-content {
    background-color: transparent;
  }
  .fancybox-theme .fancybox-bg {
    background-color: #1e2022;
  }
  .fancybox-theme .fancybox-button svg {
    margin-bottom: 0;
  }
  .fancybox-theme .fancybox-progress {
    background-color: #377dff;
  }
  .blur-30 {
    filter: blur(30px);
  }
  .leaflet-bar,
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .leaflet-bar,
  .leaflet-popup-content-wrapper {
    border-radius: 0.3125rem;
  }
  .leaflet-popup {
    margin-bottom: 3rem;
  }
  .quill-custom .ql-container {
    position: static;
  }
  .quill-custom .ql-container.ql-snow,
  .quill-custom .ql-toolbar.ql-snow {
    border-color: #e7eaf3;
  }
  .quill-custom .ql-toolbar.ql-snow {
    padding: 0.75rem 1.5rem;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .quill-custom .ql-container.ql-snow {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .quill-custom .ql-formats:first-child {
    padding-left: 0;
  }
  .quill-custom .ql-formats:first-child button {
    margin-right: 0.5rem;
  }
  .quill-custom .ql-formats:first-child button:first-child {
    margin-left: -0.25rem;
  }
  .quill-custom .ql-editor {
    position: relative;
    padding: 1.5rem 1.5rem;
  }
  .quill-custom .ql-editor p {
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    color: #1e2022;
  }
  .quill-custom .ql-editor.ql-blank::before {
    left: 1.5rem;
    color: #97a4af;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
  }
  .quill-custom .ql-snow.ql-toolbar .ql-fill {
    fill: #677788;
  }
  .quill-custom .ql-snow.ql-toolbar .ql-stroke {
    stroke: #677788;
  }
  .quill-custom .ql-snow.ql-toolbar button:hover {
    color: #377dff;
  }
  .quill-custom .ql-snow.ql-toolbar button:hover .ql-fill {
    fill: #377dff;
  }
  .quill-custom .ql-snow.ql-toolbar button:hover .ql-stroke {
    stroke: #377dff;
  }
  .quill-custom .ql-snow .ql-toolbar button,
  .quill-custom .ql-snow.ql-toolbar button {
    width: 1.75rem;
    height: 1.75rem;
    padding: 0.25rem 0.25rem;
  }
  .quill-custom .ql-snow .ql-toolbar button svg,
  .quill-custom .ql-snow.ql-toolbar button svg {
    margin-bottom: 0;
  }
  .quill-custom .ql-snow .ql-tooltip {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    z-index: 1;
    min-width: 20rem;
    border-width: 0;
    text-align: center;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    padding: 1.5rem 1.5rem;
    border-radius: 0.3125rem;
    transform: translate(-50%, -50%);
  }
  .quill-custom .ql-snow .ql-tooltip::before {
    display: block;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    border-bottom: 0.0625rem solid #e7eaf3;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
  .quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
  .quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
    display: none;
  }
  .quill-custom .ql-snow .ql-tooltip a.ql-preview,
  .quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text'] {
    min-width: 20rem;
    font-size: 1rem;
    line-height: normal;
    border: 0.0625rem solid #e7eaf3;
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.3125rem;
    transition: 0.3s;
  }
  .quill-custom .ql-snow .ql-tooltip a.ql-preview {
    display: block;
  }
  .quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
    border-color: #377dff;
  }
  .quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text'] {
    height: auto;
    display: block;
    font-family: 'Open Sans', sans-serif;
  }
  .quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text']:focus {
    border-color: rgba(55, 125, 255, 0.4);
    box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .quill-custom .ql-snow .ql-action {
    display: inline-block;
    color: #fff;
    background-color: #377dff;
    font-size: 1rem;
    line-height: normal;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    transition: 0.3s;
  }
  .quill-custom .ql-snow .ql-action:hover {
    background-color: #1366ff;
  }
  .quill-custom .ql-snow .ql-tooltip a.ql-action::after,
  .quill-custom .ql-snow .ql-tooltip a.ql-remove::before {
    padding-right: 0;
    margin-left: 0;
    border-right: none;
  }
  .quill-custom .ql-snow .ql-tooltip a.ql-remove {
    border: 0.0625rem solid #e7eaf3;
    font-size: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    margin-left: 0.5rem;
    transition: 0.3s;
  }
  .quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
    color: #0052ea;
    box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
  }
  .select2-custom {
    position: relative;
  }
  .select2-custom .select2-custom-hide {
    display: none;
  }
  .select2-custom-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
  @media (min-width: 576px) {
    .select2-custom-sm-right .select2-container--open {
      right: 0 !important;
      left: auto !important;
    }
  }
  @media (min-width: 768px) {
    .select2-custom-md-right .select2-container--open {
      right: 0 !important;
      left: auto !important;
    }
  }
  @media (min-width: 992px) {
    .select2-custom-lg-right .select2-container--open {
      right: 0 !important;
      left: auto !important;
    }
  }
  @media (min-width: 1200px) {
    .select2-custom-xl-right .select2-container--open {
      right: 0 !important;
      left: auto !important;
    }
  }
  .select2-dropdown {
    border-color: #e7eaf3;
  }
  .select2-container--open .select2-dropdown--below {
    border-radius: 0.3125rem;
    border-top: 0.0625rem solid #e7eaf3;
    margin-top: 0.5rem;
  }
  .select2-container--default.select2-container--open.select2-container--below
    .select2-selection--multiple,
  .select2-container--default.select2-container--open.select2-container--below
    .select2-selection--single {
    border-radius: 0.3125rem;
  }
  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: rgba(55, 125, 255, 0.4);
  }
  .select2-container--default .custom-select.select2-selection--multiple,
  .select2-container--default .form-control.select2-selection--multiple {
    height: auto;
    min-height: calc(1.6em + 1.625rem);
  }
  .select2-container--default .custom-select-sm.select2-selection--multiple,
  .select2-container--default .form-control-sm.select2-selection--multiple {
    min-height: calc(1.5em + 1.375rem);
  }
  .select2-container--default .form-control.select2-selection--multiple .select2-selection__choice {
    margin-top: 0.7rem;
  }
  .select2-container--default
    .custom-select-sm.select2-selection--multiple
    .select2-selection__choice,
  .select2-container--default
    .form-control-sm.select2-selection--multiple
    .select2-selection__choice {
    margin-top: 0.5rem;
  }
  .select2-container--default .form-control.select2-selection--multiple .select2-search--inline {
    margin-top: 0.25rem;
  }
  .select2-container--default
    .form-control-lg.select2-selection--multiple
    .select2-selection__choice {
    margin-top: 0.6125rem;
  }
  .select2-container--default .select2-selection--multiple {
    border-color: #e7eaf3;
    padding: 0 0;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    position: relative;
    background-color: #e7eaf3;
    border-color: transparent;
    padding-right: 1.125rem;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    position: absolute;
    right: 0.125rem;
    color: #71869d;
  }
  .select2-container--default .select2-search__field {
    width: 100% !important;
  }
  .select2-container--default .select2-search__field::placeholder {
    color: #97a4af;
  }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #e7eaf3;
    background-color: #f7faff;
    padding: 0.625rem 1.125rem;
    border-radius: 0.3125rem;
  }
  .select2-container--default .select2-search--dropdown .select2-search__field::placeholder {
    color: #97a4af;
  }
  .select2-container--default .select2-search--dropdown .select2-search__field:focus {
    border-color: rgba(55, 125, 255, 0.4);
    box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
  }
  .select2-container--default .select2-selection.active .select2-selection__placeholder {
    color: #1e2022;
  }
  .select2-container--default .custom-select .select2-search--inline .select2-search__field,
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-top: 0.7rem;
  }
  .select2-container--default .custom-select-sm .select2-search--inline .select2-search__field,
  .select2-container--default .form-control-sm .select2-search--inline .select2-search__field {
    margin-top: 0.5rem;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .select2-container--default .select2-results__option {
    padding: 0.5rem 3rem 0.5rem 0.5rem;
  }
  .select2-container--default .select2-results__option[aria-selected='true'] {
    color: #1e2022;
    background-color: rgba(189, 197, 209, 0.3);
  }
  .select2-container--default .select2-results__option:first-child,
  .select2-container--default .select2-results__option:first-child:hover {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .select2-container--default .select2-results__option:last-child,
  .select2-container--default .select2-results__option:last-child:hover {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .select2-container--default .select2-results__option {
    position: relative;
  }
  .select2-container--default .select2-results__option[aria-selected='true']::after {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    width: 1rem;
    height: 1rem;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
      no-repeat right center/1rem 1rem;
    content: '';
    transform: translateY(-50%);
  }
  .select2-container--default
    .select2-results__option--highlighted[aria-selected]:not([aria-selected='true']) {
    color: #1e2022;
    background-color: rgba(189, 197, 209, 0.3);
  }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #e7eaf3;
  }
  .select2-selection__placeholder {
    color: #97a4af;
  }
  .select2-selection--multiple .select2-selection__placeholder {
    position: absolute;
    top: 50%;
    left: 0;
    padding-left: 0.75rem;
    transform: translateY(-50%);
  }
  .range-slider-custom {
    height: 1.25rem;
  }
  .range-slider-custom .irs {
    height: 1.25rem;
  }
  .range-slider-custom .irs-line {
    height: 0.25rem;
  }
  .range-slider-custom .irs-bar {
    height: 0.25rem;
    background-color: #377dff;
  }
  .range-slider-custom .irs-handle {
    width: 1.75rem;
    height: 1.75rem;
    top: 0.875rem;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  }
  .range-slider-custom .irs-handle i:first-child {
    display: none;
  }
  .range-slider-custom .irs-handle.state_hover {
    transform: scale(1.3);
  }
  .range-slider-custom .irs-from,
  .range-slider-custom .irs-single,
  .range-slider-custom .irs-to {
    top: -2.25rem;
    display: inline-block;
    min-width: 2.5rem;
    background-color: #fff;
    color: #1e2022;
    font-size: 0.875rem;
    text-shadow: none;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
    border-radius: 0.3125rem;
    padding: 0.5rem 0.5rem;
  }
  .range-slider-custom .irs-from::before,
  .range-slider-custom .irs-single::before,
  .range-slider-custom .irs-to::before {
    border-top-color: #fff;
  }
  .range-slider-custom-grid .irs-grid-pol {
    top: 0.5rem;
    height: 0.75rem;
    background-color: #e7eaf3;
  }
  .range-slider-custom-grid .irs-grid-pol.small {
    display: none;
  }
  .range-slider-custom-grid .irs-grid-text {
    top: 2rem;
    font-size: 0.875rem;
  }
  .slick-cloned [data-hs-slick-carousel-slide-animated-element-animation] {
    opacity: 0;
  }
  .slick {
    background: url('../../resources/auth/circle-preloading.svg') no-repeat 50% 50%;
  }
  .slick .js-next,
  .slick .js-prev {
    opacity: 0;
  }
  .slick.slick-initialized {
    background: 0 0;
  }
  .slick.slick-initialized .js-slide,
  .slick.slick-initialized .js-thumb {
    visibility: visible;
    height: auto;
    overflow: visible;
  }
  .slick.slick-initialized .js-next,
  .slick.slick-initialized .js-prev {
    opacity: 1;
  }
  .slick .js-slide,
  .slick .js-thumb {
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }
  .slick .js-slide:first-child,
  .slick .js-thumb:first-child {
    height: auto;
  }
  .tagify {
    --tag-bg: unset;
    --tag-hover: unset;
    align-items: center;
    border-color: #e7eaf3;
    border-radius: 0.25rem;
  }
  .tagify:hover {
    border-color: #e7eaf3;
  }
  .tagify.tagify--focus {
    border-color: rgba(55, 125, 255, 0.4);
  }
  .tagify__tag {
    background-color: #e7eaf3;
    border-radius: 0.25rem;
  }
  .tagify__tag:first-child {
    margin-left: 0;
  }
  .tagify__tag > div {
    color: #1e2022;
    line-height: normal;
    padding: 0.3125rem 2rem 0.3125rem 1rem;
    border-radius: 0.25rem;
  }
  .tagify__tag > div * {
    display: inline-flex;
    vertical-align: baseline;
  }
  .tagify__tag__removeBtn {
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
  }
  .tagify__tag__removeBtn:hover {
    background-color: #ed4c78;
  }
  .tagify__tag__removeBtn:hover + div::before {
    box-shadow: none !important;
  }
  .tagify__tag__removeBtn:hover + div .tagify__tag-text {
    opacity: 1;
  }
  .tagify-form-control {
    display: flex;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tagify__input:first-child {
    padding-left: 0;
    margin-left: 0;
  }
  .tagify__input::before {
    line-height: 1.8;
  }
  .tagify__input .tagify__tag > div {
    padding: 0.3125rem 2rem 0.3125rem 1rem;
    line-height: normal;
  }
  .tagify__dropdown__menu {
    border: 0.0625rem solid #e7eaf3;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    margin-top: -1px;
  }
  .tagify__dropdown__item--active {
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
  }
  .tagify.tagify-form-control-list {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tagify__dropdown__wrapper {
    border: none;
  }
  .typed-cursor {
    opacity: 1;
    animation: blink 0.7s infinite;
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
