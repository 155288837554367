/*------------------------------------------------------------------
 * Theme Name: iofrm - theme
 * Theme URI: http://www.brandio.io/envato/iofrm
 * Author: Brandio
 * Author URI: http://www.brandio.io/
 * Copyright 2019 Brandio.
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. General Styles.
2. Responsive Styles.
-------------------------------------------------------------------*/
/* -----------------------------------
    1 - General Styles
------------------------------------*/
.form-body {
  background-color: #0b4697;
}

.website-logo {
  display: inline-block;
  top: 50px;
  left: 50px;
  right: initial;
  bottom: initial;
}

.website-logo img {
  width: 110px;
}

.website-logo .logo {
  background-image: url('https://avada-popups.firebaseapp.com/images/AvadaLogo-white.png?b24ff4e9b7bedd9c1ec9046df5a366be');
}

.website-logo .logo img {
  width: 100px;
}

.website-logo-inside img {
  width: 100px;
}

.website-logo-inside .logo {
  background-image: url('https://avada-popups.firebaseapp.com/images/AvadaLogo-white.png?b24ff4e9b7bedd9c1ec9046df5a366be');
}

.website-logo-inside .logo img {
  width: 100px;
}

.img-holder {
  width: 40%;
  background-color: #5cbaff;
}

.img-holder .info-holder h3 {
  color: #fff;
  text-align: left;
}

.img-holder .info-holder h3 span {
  color: #fff;
}

.img-holder .info-holder h2 {
  color: #fff;
  text-align: left;
}

.img-holder .info-holder h2 span {
  color: #fff;
}

.img-holder .info-holder p {
  color: #fff;
  text-align: left;
}

.img-holder .bg {
  opacity: 0.23;
  background-image: none;
}
.form-holder {
  height: 100%;
}
.form-holder .form-content ::-webkit-input-placeholder {
  color: #526489;
}

.form-holder .form-content :-moz-placeholder {
  color: #526489;
}

.form-holder .form-content ::-moz-placeholder {
  color: #526489;
}

.form-holder .form-content :-ms-input-placeholder {
  color: #526489;
}

.form-content .form-group {
  color: #fff;
}

.form-content .form-items {
  text-align: left;
}

.form-content h3 {
  color: #fff;
  text-align: left;
}

.form-content p {
  text-align: left;
}

.form-content label {
  color: #fff;
  text-align: left;
}

.form-content .page-links a {
  color: #fff;
  font-size: 16px;
}

.form-content .page-links a:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-content .page-links a.active:after {
  background-color: #fff;
}

.form-content .page-links a:hover:after,
.form-content .page-links a:focus:after {
  background-color: #fff;
}

.form-content input,
.form-content .dropdown-toggle.btn-default {
  border: 1px solid transparent;
  background-color: #fff;
  color: #8d8d8d;
}
.HCC-Login__Input:focus {
  border: 1px solid rgba(95, 198, 168, 1) !important;
}
.form-content input:hover,
.form-content input:focus,
.form-content .dropdown-toggle.btn-default:hover,
.form-content .dropdown-toggle.btn-default:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8d8d8d;
}

.form-content textarea {
  border: 0;
  background-color: #fff;
  color: #8d8d8d;
}

.form-content textarea:hover,
.form-content textarea:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8d8d8d;
}

.form-content .custom-file-label {
  border: 0;
  background-color: #fff;
  color: #8d8d8d;
}

.form-content .custom-file-label:after {
  color: #0b4697;
}

.form-content .custom-file:hover .custom-file-label,
.form-content .custom-file:focus .custom-file-label {
  border: 0;
  background-color: #ebeff8;
  color: #8d8d8d;
}

.form-content input[type='checkbox']:not(:checked) + label,
.form-content input[type='checkbox']:checked + label,
.form-content input[type='radio']:not(:checked) + label,
.form-content input[type='radio']:checked + label {
  color: #fff;
  font-weight: 700;
}

.form-content input[type='checkbox']:checked + label,
.form-content input[type='radio']:checked + label {
  color: #fff;
}

.form-content input[type='checkbox']:checked + label:before,
.form-content input[type='radio']:checked + label:before {
  background: #fff;
  border: 0px solid #fff;
}

.form-content input[type='checkbox']:not(:checked) + label:before,
.form-content input[type='radio']:not(:checked) + label:before {
  background: transparent;
  border: 2px solid #fff;
}

.form-content input[type='checkbox']:not(:checked) + label:after,
.form-content input[type='checkbox']:checked + label:after {
  color: #0b4697;
}

.form-content input[type='radio']:not(:checked) + label:after,
.form-content input[type='radio']:checked + label:after {
  background-color: #0b4697;
}

.form-content .custom-options input[type='checkbox']:not(:checked) + label,
.form-content .custom-options input[type='checkbox']:checked + label,
.form-content .custom-options input[type='radio']:not(:checked) + label,
.form-content .custom-options input[type='radio']:checked + label {
  color: #606060;
  background-color: rgba(247, 247, 247, 0.75);
}

.form-content .custom-options input[type='checkbox']:checked + label,
.form-content .custom-options input[type='radio']:checked + label {
  color: #606060;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
}

.form-content .form-button .ibtn {
  background-color: #fff;
  color: #29a4ff;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  font-size: 15px;
}

.form-content .form-button .ibtn:hover,
.form-content .form-button .ibtn:focus {
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.form-content .form-button a {
  color: #fff;
}

.form-content .other-links span {
  color: #fff;
}

.form-content .other-links div.text {
  color: #fff;
  margin-bottom: 10px;
}

.form-content .other-links a {
  color: #fff;
}

.form-content .form-sent .tick-holder .tick-icon {
  background-color: rgba(255, 255, 255, 0);
}

.form-content .form-sent .tick-holder .tick-icon:before {
  background-color: #fff;
}

.form-content .form-sent .tick-holder .tick-icon:after {
  background-color: #fff;
}

.form-content .form-sent h3 {
  color: #fff;
}

.form-content .form-sent p {
  color: #fff;
}

.form-content .form-sent .info-holder {
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.form-content .form-sent .info-holder span {
  color: #fff;
}

.form-content .form-sent .info-holder a {
  color: #fff;
}

@keyframes tick-anime3 {
  0% {
    background-color: rgba(255, 255, 255, 0);
    -webkit-transform: rotate(35deg) scale(2);
    -moz-transform: rotate(35deg) scale(2);
    -ms-transform: rotate(35deg) scale(2);
    transform: rotate(35deg) scale(2);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-transform: rotate(45deg) scale(1);
    -moz-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

.alert {
  color: #fff;
}

.alert.alert-primary {
  background-color: rgba(226, 240, 255, 0.1);
  border-color: #fff;
}

.alert.alert-primary hr {
  border-top-color: #fff;
}

.alert.alert-secondary {
  background-color: rgba(240, 240, 240, 0.1);
  border-color: #fff;
}

.alert.alert-secondary hr {
  border-top-color: #fff;
}

.alert.alert-success {
  background-color: rgba(247, 255, 240, 0.1);
  border-color: #fff;
}

.alert.alert-success hr {
  border-top-color: #fff;
}

.alert.alert-danger {
  background-color: rgba(255, 250, 250, 0.1);
  border-color: #fff;
}

.alert.alert-danger hr {
  border-top-color: #fff;
}

.alert.alert-warning {
  background-color: rgba(255, 248, 225, 0.1);
  border-color: #fff;
}

.alert.alert-warning hr {
  border-top-color: #fff;
}

.alert.alert-info {
  background-color: rgba(220, 237, 241, 0.1);
  border-color: #fff;
}

.alert.alert-info hr {
  border-top-color: #fff;
}

.alert.alert-light {
  background-color: rgba(254, 254, 254, 0.1);
  border-color: #fff;
}

.alert.alert-light hr {
  border-top-color: #fff;
}

.alert.alert-dark {
  background-color: rgba(214, 216, 217, 0.1);
  border-color: #fff;
}

.alert.alert-dark hr {
  border-top-color: #fff;
}

.alert.with-icon.alert-primary:before {
  color: #fff;
}

.alert.with-icon.alert-secondary:before {
  color: #fff;
}

.alert.with-icon.alert-success:before {
  color: #fff;
}

.alert.with-icon.alert-danger:before {
  color: #fff;
}

.alert.with-icon.alert-warning:before {
  color: #fff;
}

.alert.with-icon.alert-info:before {
  color: #fff;
}

.alert.with-icon.alert-light:before {
  color: #fff;
}

.alert.with-icon.alert-dark:before {
  color: #fff;
}

.alert a,
.alert a.alert-link {
  color: #fff;
}

.alert .close {
  color: #fff;
}

.alert .close span {
  color: #fff;
}

.form-subtitle {
  color: #fff;
}

.rad-with-details .more-info {
  color: #fff;
}

.form-body.without-side .img-holder .info-holder img {
  display: inline-block;
}

.form-body.without-side .form-content .page-links a:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-body.without-side .form-content .page-links a.active:after {
  background-color: #fff;
}

.form-body.without-side .form-content .page-links a:hover:after,
.form-body.without-side .form-content .page-links a:focus:after {
  background-color: #fff;
}

.form-body.without-side .form-content .form-button .ibtn {
  background-color: #fff;
  color: #29a4ff;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button .ibtn:hover,
.form-body.without-side .form-content .form-button .ibtn:focus {
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

/* -----------------------------------
    2 - Responsive Styles
------------------------------------*/
@media (max-width: 992px) {
  .form-holder {
    margin-left: 0;
  }

  .website-logo {
    top: 50px;
    left: 50px;
    right: initial;
    bottom: initial;
  }

  .website-logo .logo {
    background-image: url('https://avada-popups.firebaseapp.com/images/AvadaLogo-white.png?b24ff4e9b7bedd9c1ec9046df5a366be');
  }

  .form-body.without-side .website-logo .logo {
    background-image: url('https://avada-popups.firebaseapp.com/images/AvadaLogo-white.png?b24ff4e9b7bedd9c1ec9046df5a366be');
  }
  .form-content .form-items {
    max-width: 100%;
  }
  .form-holder .form-content {
    padding-left: 25px;
    padding-right: 25px;
  }
  .form-content .platform-input-wrapper .platform-input-button {
    width: 100% !important;
  }
  .form-content .platform-input-wrapper {
    flex-direction: column;
  }
}
