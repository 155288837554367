.HCC-Tooltip__Wrapper {
  position: relative;
  display: flex;

  .HCC-Tooltip__Label {
  }

  .HCC-Tooltip__Content {
    width: 20rem;
    position: absolute;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 5px;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    z-index: 100; // > 21
  }

  &.above {
    .HCC-Tooltip__Content {
      bottom: 100%;
      right: 0;
      transform: translate(50%, 0);
    }
  }

  &.below {
    .HCC-Tooltip__Content {
      top: 100%;
      right: 0;
      transform: translate(50%, 0);
    }
  }

  .HCC-Tooltip__Icon {
    margin-left: 0.4rem;
    cursor: pointer;

    &:hover {
      .HCC-Tooltip__Content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
