@import 'custom';
@import 'theme.min';
@import 'animation';
@import 'styles';
// @import url('https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');
@import url('https://cdn.jsdelivr.net/gh/loadingio/loading.css@v2.0.0/dist/loading.min.css');
@import url('https://cdn.jsdelivr.net/gh/loadingio/ldbutton@v1.0.1/dist/ldbtn.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css');

#HCC-login-page {
  font-family: SF Pro Display, sans-serif;
  font-size: 16px;
}
