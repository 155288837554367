.HCC-StoreList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          //min-width: 10rem;
          &:nth-child(1) {
            min-width: 4rem;
            max-width: 4rem;
          }
          &:nth-child(2) {
            min-width: 20rem;
            max-width: 25rem;
          }
          //&:nth-child(4) {
          //  min-width: 10rem;
          //  max-width: 15rem;
          //}
          &:nth-child(5) {
            min-width: 10rem;
            max-width: 15rem;
          }
          &:nth-child(6) {
            min-width: 10rem;
            max-width: 15rem;
          }
          &:nth-child(7) {
            min-width: 10rem;
            max-width: 15rem;
          }
          &:nth-last-child(1) {
            min-width: 6rem;
            max-width: 6rem;
          }
          &:nth-last-child(2) {
            min-width: 10rem;
            max-width: 10rem;
          }
        }
      }
    }
  }
}

.HCC-StoreForm__PlatformSelect {
  &.checked {
    .Polaris-Thumbnail {
      cursor: pointer;
      border-color: #E85D04;
      border-width: 2px;
    }
  }
  &.pending {
    .Polaris-Thumbnail {
      filter: opacity(0.5);
      cursor: not-allowed;
    }
  }
}

.HCC-Store__Log{
  ul li::marker {
    color: #E85D04;
  }
}

.HCC-Sheet__BulkMappingProduct {
  .HCC-BulkMappingProduct__HeaderButton{
    margin-bottom: 1rem;
  }
  .HCC-BulkMappingProduct__BodyTab1 {
    .HCC-AdvancedResourceList__Items {
      .Polaris-ResourceList {
        .Polaris-FormLayout__Items {
          .Polaris-FormLayout__Item {
            &:nth-child(1) {
              min-width: 5rem;
              max-width: 5rem;
            }
            &:nth-last-child(1) {
              min-width: 15rem;
              max-width: 15rem;
            }
          }
        }
      }
    }
  }
  .HCC-BulkMappingProduct__BodyTab2 {
    .HCC-AdvancedResourceList__Items {
      .Polaris-ResourceList {
        .Polaris-FormLayout__Items {
          .Polaris-FormLayout__Item {
            &:nth-child(1) {
              min-width: 4rem;
              max-width: 4rem;
            }
            &:nth-child(2) {
              min-width: 20rem;
              max-width: 30%;
            }
            &:nth-last-child(1) {
              min-width: 6rem;
              max-width: 6rem;
            }
          }
        }
      }
    }
    .HCC--BulkMappingProduct_CanUpdate {
      font-weight: bold;
      display: flex;
      align-items: center;

      .Polaris-Icon{
        margin-left: 0.5rem;
        svg {
          fill: #e85d04;
        }
      }
    }
  }
}