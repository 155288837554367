@import '~@shopify/polaris/build/esm/styles.css';
@import 'colors';
@import 'grid/grid';
@import 'polaris';
@import 'pages';
@import 'hooks';
@import 'components';
@import 'auth';
@import 'checkbox-tree';

body[data-lock-scrolling] {
  overflow: auto hidden;
}
