.HCC--CategoryList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(1) {
            min-width: 20rem;
            max-width: 40%;
          }

          &:nth-child(2) {
            min-width: 5rem;
            max-width: 20%;
          }

          &:nth-child(3) {
            min-width: 5rem;
            max-width: 20%;
          }

          &:nth-child(4) {
            min-width: 20rem;
            max-width: 20%;
          }

          &:nth-last-child(1) {
            min-width: 8rem;
            max-width: 8rem;
          }
        }
      }
    }
  }
}