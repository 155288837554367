.HCC-DragDropList__Container {
  margin-left: -2rem;
  .Polaris-ResourceItem__ListItem {
    &::marker {
      content: none;
    }
    &:last-of-type {
      .Polaris-ResourceItem__ItemWrapper {
        border-radius: unset;
      }
    }
    .Polaris-ResourceItem {
      &:hover {
        background-color: unset;
        cursor: auto;
      }
      .Polaris-ResourceItem__Container {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
      }
    }
  }
}
