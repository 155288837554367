.HCC-OrderList__Body {
  &.supplier {
    .HCC-AdvancedResourceList__Items {
      .Polaris-ResourceList {
        .Polaris-FormLayout__Items {
          .Polaris-FormLayout__Item {
            &:nth-child(2) {
              min-width: 7rem;
              max-width: 15rem;
            }
            &:nth-child(3) {
              min-width: 30rem;
              max-width: 35rem;
            }
            &:nth-child(4) {
              min-width: 10rem;
              max-width: 15rem;
            }
            &:nth-child(5) {
              min-width: 5rem;
              max-width: 7rem;
            }
            &:nth-child(6) {
              min-width: 7rem;
              max-width: 10rem;
            }
            &:nth-child(7),
            &:nth-child(8) {
              min-width: 15rem;
              max-width: 20rem;
            }
            &:nth-child(9) {
              min-width: 20rem;
              max-width: 20rem;
            }
          }
        }
      }
    }
  }
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-last-child(1) {
            min-width: 7rem;
            max-width: 7rem;
          }
        }
      }
    }
  }
  .HCC-AdvancedResourceList__ChildCustomTable {
    margin: -1.2rem -2rem;

    .Polaris-IndexTable__TableHeading--first {
      width: 1rem;
      margin: 0;
      padding-left: 4.9rem;
      visibility: hidden;
      .Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
        //visibility: hidden;
      }
    }
    .Polaris-IndexTable__TableHeading--last {
      &.Polaris-IndexTable__TableHeading {
        padding-right: 3rem;
      }
    }
    .Polaris-IndexTable__TableCell--first {
      padding-left: 3.4rem;
    }
    .Polaris-IndexTable__TableCell {
      &:nth-last-child(1) {
        padding-right: 3rem;
      }
    }
  }
}

.HCC-OrderList__Tabs {
  position: relative;
  .HCC-OrderList__Tab--Badge {
    position: absolute;
    top: -2.5rem;
    right: -2.5rem;
    border-radius: 2rem;
    .Polaris-Badge {
      background-color: inherit;
      color: inherit;
    }
  }
}

.HCC-OrderAddItem__Campaign {
  border-top: 0.1rem solid #e1e3e5;
}
.HCC-OrderAddItem__Campaign--Pagination {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.HCC-OrderAddItem__Variants {
  border-top: 0.1rem solid #e1e3e5;
  .Polaris-ResourceItem__Content {
    margin-left: 3rem;
  }
}

.HCC-OrderCreate__ItemList {
  .Polaris-FormLayout__Items {
    .Polaris-FormLayout__Item {
      &:first-child {
        min-width: 50rem !important;
        p {
          max-width: 43rem;
          overflow-wrap: break-word;
        }
      }
      &:nth-last-child(1) {
        min-width: 3rem !important;
        max-width: 3rem;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        display: flex;
        justify-content: center;
      }
      &:nth-last-child(2) {
        display: flex;
        justify-content: center;
      }
      &:nth-last-child(3) {
        max-width: 5rem !important;
      }
    }
  }
}
.isCreate {
  .Polaris-ResourceItem__ListItem {
    + .Polaris-ResourceItem__ListItem:nth-of-type(odd) {
      border-top: unset;
      .Polaris-ResourceItem__Container {
        padding: 0 7.6rem 1.2rem;
      }
    }
  }
}

.HCC-OrderCreate__ItemChild {
  display: flex;
  flex-direction: column;
  .HCC-ImageUploader__Container {
    .Polaris-DropZone {
      width: 8rem;
      height: 8rem;
    }
    .Polaris-Thumbnail {
      height: 8rem;
      min-width: 8rem;
    }
  }
}

.HCC-Sheet__Wrapper {
  .HCC-Sheet__Body.HCC-Sheet__Order {
    background-color: #fafbfb;
  }
}

.HCC-OrderForm__Request.Polaris-Card {
  background-color: #fff5ea;

  .Polaris-Card__Section {
    .Polaris-FormLayout:last-child {
      margin-top: 3rem;
      border-top: solid 1px #dfdfdf;
    }
  }
  .Polaris-Card__Footer {
    border-top: solid 1px #dfdfdf;
    padding: 1.5rem;
  }
}

//Import Order
.HCC-ModalHeader__TextHint {
  .Polaris-DisplayText.Polaris-DisplayText--sizeSmall {
    font-size: 1.4rem;
    font-weight: 300;
    color: #8f9091;
  }
}

.HCC-ImportModal__Form {
  .Polaris-DropZone-FileUpload {
    padding: 2rem;
    .Polaris-DropZone-FileUpload__Action {
      background-color: transparent;

      .Polaris-Stack__Item {
        &:nth-child(1) {
          width: 4rem;
          height: 4rem;
          .Polaris-Icon {
            width: 100%;
            height: 100%;
          }
        }
        &:nth-child(2) {
          button {
            .Polaris-Button__Text {
              font-weight: 600;
              text-decoration: underline;
            }
          }
          span {
            font-size: 1.4rem;
            color: rgb(32, 34, 35);
            font-weight: 400;
          }
        }
      }
    }
  }
  .HCC-ImportModal__DownloadSample {
    .Polaris-Button__Text {
      color: #e85d04;
    }
  }
}

.HCC-OrderForm__Layout {
  .Polaris-Button--plain:not(.Polaris-Button--disabled) {
    .Polaris-Button__Text {
      color: #e85d04 !important;
    }
  }
  strong {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .Polaris-Stack__Item > span {
    &:nth-child(1) {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.HCC-OrderItemView__SelectGroup {
  .Polaris-Select:not(.Polaris-Select--disabled) {
    .Polaris-Select__SelectedOption {
      color: rgba(232, 93, 4, 1);
    }
  }
  .Polaris-Select__Backdrop {
    box-shadow: none;
    border: none;
  }
  .Polaris-Stack {
    .Polaris-Stack__Item {
      width: 150px;
      padding-bottom: 0.5rem;
      &:nth-child(1) {
        min-width: 13rem;
        display: flex;
        .Polaris-TextStyle--variationStrong {
          padding-top: 0.8rem;
        }
      }
    }
  }
}
.HCC-OrderItemView__ProductDetail {
  .Polaris-Stack__Item {
    &:nth-child(2) {
      width: 220px;
      overflow-wrap: break-word;
    }
  }
}
.Polaris-IndexTable__StickyTableHeader--isSticky {
  visibility: hidden;
}
.HCC-OrderForm__Request {
  .Polaris-Card {
    background: rgba(255, 246, 244, 1);
  }
}
.HCC-OrderForm__RequestDetails {
  .Polaris-Stack__Item {
    margin-top: 0.4rem !important;
  }
}
.HCC-OrderForm_ShippingAddress {
  .Polaris-TextField__Spinner {
    display: none;
  }
}
.HCC-OrderLogList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          // min-width: 10rem;

          &:nth-child(1) {
            min-width: 4rem;
            max-width: 4rem;
          }
          &:nth-child(2),
          &:nth-child(3) {
            max-width: 1.5rem;
          }
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(7) {
            min-width: 10rem;
            max-width: 15rem;
          }
          &:nth-child(5) {
            min-width: 20rem;
            max-width: 25rem;
          }

          &:nth-last-child(1) {
            min-width: 3rem;
            max-width: 3rem;
          }
          &:nth-last-child(2) {
            min-width: 10rem;
            max-width: 10rem;
          }
        }
      }
    }
  }
}
