.HCC-Popup__FloatingButton {
  border-radius: 50%;
  transition: all .3s ease-in-out;
  width: 56px;
  height: 56px;
  background-color: #28b2bb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #fff;
  font-size: 2em;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .16), 0 1px 10px rgba(0, 0, 0, .16)
}

.HCC-Popup__FloatingButton.right {
  right: 30px;
  bottom: 100px
}

.HCC-Popup__FloatingButton.left {
  left: 30px;
  bottom: 100px
}

.HCC-Popup__FloatingButton:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, .16), 0 5px 10px rgba(0, 0, 0, .16)
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s
}

.animated.fast {
  -webkit-animation-duration: .8s;
  animation-duration: .8s
}

.animated.faster {
  -webkit-animation-duration: .5s;
  animation-duration: .5s
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0)
  }
  to {
    opacity: 1;
    transform: translateZ(0)
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0)
  }
  to {
    opacity: 1;
    transform: translateZ(0)
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)
  }
  to {
    opacity: 1;
    transform: translateZ(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)
  }
  to {
    opacity: 1;
    transform: translateZ(0)
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft
}

@-webkit-keyframes tada {
  0% {
    transform: scaleX(1)
  }
  10%, 20% {
    transform: scale3d(.9, .9, .9) rotate(-3deg)
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg)
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg)
  }
  to {
    transform: scaleX(1)
  }
}

@keyframes tada {
  0% {
    transform: scaleX(1)
  }
  10%, 20% {
    transform: scale3d(.9, .9, .9) rotate(-3deg)
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg)
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg)
  }
  to {
    transform: scaleX(1)
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
