.Polaris-TopBar {
  background-color: #1e252b;
  filter: drop-shadow(0px 1px 4px #1e252b);
}
.Polaris-TopBar__SearchField {
  display: none;
}
.Polaris-TopBar-Menu__ActivatorWrapper {
  padding-right: 2.5rem;
}
.Polaris-TopBar__SecondaryMenu {
  width: 100%;
}
.TopBar-ToggleNavigationButton {
  button {
    left: -3rem;
    transform: translateY(4%);
    .Polaris-Button__Icon {
      width: 15px;
      padding-top: 1rem;
    }
    .Polaris-Icon > svg {
      fill: white !important;
    }
  }
  .Polaris-Icon > svg {
    fill: white !important;
  }
}
