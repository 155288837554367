.Polaris-Button--primary:not(.Polaris-Button--destructive) {
  --p-button-color: var(--pgc-default-color);
  --p-button-color-hover: var(--pgc-darker-color);
  --p-button-color-active: var(--pgc-darker-color);
  --p-button-color-depressed: var(--pgc-darker-color);
}

.Polaris-Link,
.Polaris-Checkbox__Backdrop,
.Polaris-RadioButton__Backdrop {
  --p-interactive: #e85d04;
  &:hover {
    --p-interactive-hovered: #e85d04;
  }
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
  --p-action-primary: var(--pgc-default-color);
}
