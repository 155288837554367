:root {
  --sheet--width: 38rem;
}

$sheetWidth: var(--sheet--width, '38rem');

@media (min-width: 48.0625em) {
  .Polaris-Sheet__Container {
    width: $sheetWidth;
    .Polaris-Sheet {
      width: $sheetWidth;
    }
  }
}

.Polaris-Sheet__Container {
  ~ .Polaris-Backdrop--transparent {
    background-color: var(--p-backdrop);
  }
}

.HCC-Sheet__Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .HCC-Sheet__Header,
  .HCC-Sheet__Footer {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .HCC-Sheet__Header {
    border-bottom: 1px solid #dfe3e8;
    justify-content: space-between;
    height: 5.6rem;
    padding: 1.6rem 2rem;
    > .Polaris-Stack {
      width: 100%;
    }
  }

  .HCC-Sheet__Footer {
    border-top: 1px solid #dfe3e8;
    justify-content: end;
    height: 7rem;
    padding: 1.4rem 2rem;
  }

  .HCC-Sheet__Body {
    height: 100%;
    &.HCC-Sheet__BodySection {
      padding: 1.6rem;
    }
    > .Polaris-Card {
      min-height: 100%;
      border-radius: 0;
    }
  }
  .HCC-TextArea {
    min-height: 128px;
    height: fit-content;
    width: 100%;
    resize: vertical;
    min-height: 60px;
    border: 0.1rem solid rgba(186, 191, 195, 1);
    border-radius: 4px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
      Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
  }
  .HCC-TextArea:focus {
    border: 0.1rem solid rgba(186, 191, 195, 1);
    border-radius: 4px;
  }
  .HCC-TextArea:focus-visible {
    outline: rgba(186, 191, 195, 1);
  }
}

.HCC-Category-Form-Ckeditor {
  .ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible {
    width: 28rem;
  }
}

.ck.ck-editor__main .ck-content.ck-editor__editable {
  min-height: 14rem !important;
}
