@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css');
.Polaris-Navigation__PrimaryNavigation {
  background-color: #3c4349;
}
.Polaris-Navigation__ListItem {
  .Polaris-Navigation__Item--selected {
    background-color: #e85d04 !important;
    color: #fff !important;
    .Polaris-Navigation__Icon svg {
      fill: var(--p-icon);
    }
  }
  .Polaris-Navigation__Item {
    &:hover:not(.Polaris-Navigation__Item--selected) {
      opacity: 0.6;
      background-color: #e85d04;
      color: #5c5f62;
    }
    border-color: transparent !important;
    border-radius: 0;
    margin: 0;
    overflow: hidden;
    padding-left: 1.6rem;
  }
}

.Polaris-Navigation__SecondaryNavigation {
  .Polaris-Navigation__Item--selected {
    background-color: var(--pgc-lighter-color);
    color: #fff !important;
  }
  .Polaris-Navigation__Item {
    color: #fff;
    padding-left: 5.2rem;
  }
}

.Polaris-Frame__Navigation {
  &.Polaris-Frame__Navigation--enterActive {
    .Polaris-Navigation {
      border-right: unset;

      .Polaris-Navigation__PrimaryNavigation {
        padding-top: 3rem;

        > .Polaris-Navigation__Section {
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
        }
      }
    }

    .Polaris-Frame__NavigationDismiss {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .Polaris-Navigation {
    .Polaris-Navigation__PrimaryNavigation {
      > .Polaris-Navigation__Section {
        border-top: unset;
      }
    }
  }
}

.Polaris-Navigation__ListItem .Polaris-Navigation__Item:not(.Polaris-Navigation__Item--selected) {
  color: #5c5f62;
  .Polaris-Navigation__Icon svg {
    fill: #5c5f62;
  }
}
.Polaris-Navigation--subNavigationActive {
  background-color: transparent !important;
  color: white !important;
  .Polaris-Navigation__Icon svg {
    fill: white !important;
  }
}
// //icon and text color on hover
.Polaris-Navigation__ListItem
  .Polaris-Navigation__Item:hover:not(.Polaris-Navigation__Item--selected) {
  color: white !important;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  .Polaris-Icon__Svg {
    fill: white !important;
  }
}
// icon and text color
.Polaris-Navigation__ListItem
  .Polaris-Navigation__Item:not(:hover):not(.Polaris-Navigation__Item--selected):not(.Polaris-Navigation--subNavigationActive) {
  color: white !important;
  content: icon;
  transition: opacity 0.5s ease-in-out;
  opacity: 0.4;
  .Polaris-Navigation__Icon svg {
    fill: white;
    transition: opacity 0.5s ease-in-out;
  }
}
.Polaris-Navigation__ItemWrapper {
  margin-bottom: 0.5rem;
}

.HCC-Frame__Content {
  .HCC-Frame__Content--DisplayContent {
    flex: 1;
  }
  min-height: 94vh !important;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding-left: 5.5rem;
  -webkit-transition: padding 0.3s ease;
  -moz-transition: padding 0.3s ease;
  -o-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
  &.HCC-Frame__Content--isExpanded {
    padding-left: 24rem;
  }
}

.HCC-Frame__Navigation {
  position: fixed !important;
  z-index: 100;
  height: 95%;
  .Polaris-Navigation {
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
    overflow: hidden;
    min-width: 0;
    width: 5.5rem; // collapsed width
    background-color: #ffffff !important;

    .Polaris-Navigation__Item:not(.Polaris-Navigation--subNavigationActive) {
      .Polaris-Navigation__Icon svg {
        fill: var(--avada-icon-navigation);
      }
    }

    .Polaris-Navigation__ListItem {
      .Polaris-Navigation--subNavigationActive:not(.Polaris-Navigation__Item--selected) {
        -webkit-transition: background-color 0.3s ease;
        -moz-transition: background-color 0.3s ease;
        -o-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
        background-color: var(--p-background-selected);
      }

      .Polaris-Navigation__SecondaryNavigation {
        -webkit-transition: max-height 0.2s ease;
        -moz-transition: max-height 0.2s ease;
        -o-transition: max-height 0.2s ease;
        transition: max-height 0.2s ease;
        overflow: hidden;
        margin-bottom: 0;
        max-height: 0;
      }
    }

    .Polaris-Navigation__PrimaryNavigation {
      > .Polaris-Navigation__Section--withSeparator:nth-child(1) {
        border-top: none;
        padding-bottom: 1.2rem;
      }
      > .Polaris-Navigation__Section + .Polaris-Navigation__Section {
        padding: 1.2rem 0;
      }
    }
  }

  &:hover,
  &.HCC-Frame__Navigation--isExpanded {
    min-width: fit-content;

    .Polaris-Navigation {
      width: 24rem; // expanded width

      .Polaris-Navigation__ListItem {
        .Polaris-Navigation--subNavigationActive:not(.Polaris-Navigation__Item--selected) {
          background-color: unset;
        }

        .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
          //overflow: visible;
          max-height: 100%;
        }
      }
    }
  }
}

// truncate menu title when collapsing
.Polaris-Navigation {
  .Polaris-Navigation__Item {
    align-items: center;
    overflow: hidden;
    border-top: 0 !important;

    .Polaris-Navigation__Text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
// Footer
.HCC-Frame__Footer {
  margin-top: 3rem;
  background: rgba(251, 251, 251, 1);
  text-align: center;
  padding: 10px 0 10px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
// Settings
.Polaris-Navigation__ListItem__isSettings {
  display: flex;
  .Polaris-Navigation__ItemWrapper {
    order: 2;
  }
  .Polaris-Navigation--isExpanded {
    order: 1;
  }
}
// add chevron icon to drop list
.HCC-Frame__Navigation--isExpanded {
  .Polaris-Navigation__Item:not(.Polaris-Navigation--subNavigationActive) .ChevronIcon::after,
  .ChevronIcon-settings::before {
    content: '\f054';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    padding-right: 15px;
    right: 0;
    position: absolute;
  }
  .Polaris-Navigation--subNavigationActive .ChevronIcon::before {
    content: '\f078';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    padding-right: 15px;
    right: 0;
    position: absolute;
  }
  .Polaris-Navigation--subNavigationActive .ChevronIcon-settings::before {
    content: '\f077';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    padding-right: 15px;
    right: 0;
    position: absolute;
  }
}
