// Shipping Class
.HCC--ShippingClassList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(1) {
            min-width: 15rem;
            max-width: 15%;
          }

          &:nth-child(2) {
            min-width: 15rem;
            max-width: 15%;
          }

          &:nth-child(3) {
            min-width: 20rem;
            max-width: 35%;
          }

          &:nth-child(4) {
            min-width: 20rem;
            max-width: 35%;
          }

          &:nth-child(5) {
            min-width: 13rem;
            max-width: 13rem;
          }

          &:nth-last-child(1) {
            min-width: 8rem;
            max-width: 8rem;
          }
        }
      }
    }
  }
}

.HCC--ShippingClassForm_SupplierLabel {
  .Polaris-Stack__Item:has(.HCC--ShippingClassForm_SupplierLabel__label) {
    font-weight: bold;
    width: 10rem;

    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// Carrier
.HCC--CarrierList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(1) {
            min-width: 20rem;
            max-width: 30%;
          }

          &:nth-child(2) {
            min-width: 20rem;
            max-width: 20%;
          }

          &:nth-child(3) {
            min-width: 20rem;
            max-width: 30%;
          }

          &:nth-child(4) {
            min-width: 13rem;
            max-width: 13rem;
          }

          &:nth-last-child(1) {
            min-width: 8rem;
            max-width: 8rem;
          }
        }
      }
    }
  }
}

// warehouse

.HCC--WareHouse__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(1) {
            min-width: 4rem;
            max-width: 5%;
          }

          &:nth-last-child(1) {
            min-width: 8rem;
            max-width: 8rem;
          }

          &:nth-last-child(2) {
            min-width: 15rem;
            max-width: 15rem;
          }
        }
      }
    }
  }

  .HCC--WareHouse__Description {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//News
.HCC--News__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {

          &:nth-child(2) {
            min-width: 20rem;
            max-width: 15%;
          }

          &:nth-last-child(1) {
            min-width: 15rem;
            max-width: 15rem;
          }
        }
      }
    }
  }

  .HCC--News_Content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.HCC-Sheet__News {
  .HCC--News__SendTo {
    color: #e85d04;
    padding: 0.3rem 1rem;
    border: 1px solid #e85d04;
    width: fit-content;
    border-radius: 5px;
  }

  .HCC--NewsForm__SendMail {
    margin-top: 1rem;
  }
}


//Notifications

.Avada-NotificationList__Item {
  .Polaris-FormLayout {
    .Polaris-FormLayout__Items {
      .Polaris-FormLayout__Item {
        &:nth-child(1) {
          max-width: 22rem;
        }

        &:nth-child(2) {
          min-width: 3rem;
          max-width: 3rem;
          max-height: 22px;
        }
      }
    }
  }
}

//.Polaris-TextField--multiline {
//  > .Polaris-TextField__Input {
//    overflow: hidden;
//  }
//}

.HCC-Notification__Editor {
  font-family: Fira code, Fira Mono, monospace;
  counter-reset: line;
  border: 1px solid #c9cccf;
  border-radius: 0.5rem;

  #HCC-Notification__CodeArea {
    outline: none;
    padding-left: 5rem !important;

    &::selection {
      background-color: #accef7;
    }
  }

  pre {
    padding-left: 5rem !important;
  }

  .editorLineNumber {
    left: 0;
    position: absolute;
    text-align: right;
    font-weight: 100;
    color: #cccccc;
    width: 3rem;
  }
}
