.HCC-UserList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(2) {
            min-width: 4rem;
            max-width: 4rem;
          }
          &:nth-child(3) {
            min-width: 20rem;
            max-width: 25rem;
          }
          &:nth-child(4) {
            min-width: 20rem;
            max-width: 25rem;
          }
          &:nth-child(5) {
            // Net Sold column
            min-width: 20rem;
            max-width: 20rem;
          }
          &:nth-child(6) {
            min-width: 20rem;
            max-width: 20rem;
          }
          &:nth-last-child(4) {
            // Created column
            min-width: 15rem;
            max-width: 20rem;
            word-break: normal;
          }
          &:nth-last-child(3) {
            min-width: 15rem;
            max-width: 20rem;
          }
          &:nth-last-child(2) {
            min-width: 15rem;
            max-width: 20rem;
          }
          &:nth-last-child(1) {
            min-width: 6rem;
            max-width: 6rem;
          }
        }
      }
    }
  }
}

.HCC-UserList__RoleColumn {
  padding: 0 1rem;
  border: 1px solid;
  width: fit-content;
  border-radius: 5px;
}
.Polaris-TopBar-UserMenu__Details > * {
  color: white;
}
.Polaris-TopBar-Menu__Activator {
  &::after {
    margin-right: 5rem !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: transparent;
  }
  &:nth-child(1) {
    background-color: transparent;
  }
  &:nth-child(2) {
    background-color: transparent;
  }
}
.Polaris-Button--plain:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content,
.Polaris-Button--plain.Polaris-Button--pressed:not(.Polaris-Button--iconOnly)
  > .Polaris-Button__Content {
  background-color: transparent;
}

.UserRole {
  text-align: center;
  margin-bottom: 2rem;
}
.UserRole > p {
  color: white;
  margin: 10px 0 15px 0;
  font-size: large;
}
.UserRole > strong {
  margin-bottom: 10px;
  color: white;
  font-size: larger;
}
.HCC-UserBalance {
  display: flex;
  padding: 0 1rem;
  border: 1px solid;
  width: fit-content;
  border-radius: 5px;
}
.HCC-UserBalance_detail {
  // text-align: center;
  margin-left: 1rem;
}
.HCC-Sheet__User {
  .Polaris-TextStyle--variationStrong {
    font-size: 18px;
  }
  display: flex;
  flex-direction: column;
  background: rgba(246, 246, 247, 1);

  .Polaris-Stack .Polaris-Stack__Item {
    margin-top: 1rem !important;
  }

  .HCC-Sheet__User-FormWrapper-Overview {
    order: 1;
  }
  .HCC-Sheet__User-FormWrapper-Address {
    transform: translateY(2rem);
    order: 2;
  }
  .Polaris-TextField__Spinner {
    display: none;
  }
}
.HCC-ChangePassword__Modal {
  width: 100%;
  .HCC-ChangePassword__Modal__Input {
    width: 60%;
  }
  .HCC-ChangePassword__Modal__InputWrapper {
    display: flex;
  }
  .HCC-ChangePassword__Modal__togglePassword {
    padding: 3.5rem 0 0 1rem;
  }
}

.HCC_User__SellerCode{
  max-width: calc(50% - 1rem);
  min-width: 22rem;
}
