.billing-wrap-5-col > .Polaris-Card,
.billing-wrap-4-col > .Polaris-Card,
.billing-wrap-3-col > .Polaris-Card,
.billing-wrap-2-col > .Polaris-Card {
  display: inline-block;
  text-align: center;
  box-shadow: none;
  border-radius: 0;
  padding: 0 20px 0 20px;
  border-right: 1px solid #d6d6d6;
  &:last-child {
    border-right: none;
  }
  .Polaris-Stack__Item {
    text-align: start;
    span {
      color: rgba(92, 95, 98, 1);
      font-size: 14px;
    }
  }
}
.billing-wrap-5-col > .Polaris-Card {
  width: 20%;
}
.billing-wrap-4-col > .Polaris-Card {
  width: 25%;
}
.billing-wrap-3-col > .Polaris-Card {
  width: 33.3%;
}
.billing-wrap-2-col > .Polaris-Card {
  width: 50%;
}
.billing-wrap-1-col > .Polaris-Card {
  width: 100%;
}
.billing-data-amount {
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  display: inline-block;
  margin-top: 10px;
  line-height: 1;
  text-align: start;
  font-weight: 600;
  width: 100%;
}
.billing-data-amount::first-letter {
  color: rgba(92, 95, 98, 1);
  padding-right: 0.5rem;
}
.billing-overall-label {
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .overall-tooltip {
    cursor: pointer;
  }
}
.card-header-action {
  text-align: right;
}
.page-custom-title {
  float: left;
  font-size: 2rem;
  font-weight: 600;
}
.billing-overall-label {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  .billing-overall-content {
    width: 194px;
    position: absolute;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 5px;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &.below {
    .billing-overall-content {
      top: 100%;

      right: 50%;
      transform: translate(50%, 0);
    }
  }
  &.above {
    .billing-overall-content {
      bottom: 100%;
      right: 50%;
      transform: translate(50%, 0);
    }
  }
  .icon-hover {
    display: inline-block;
    cursor: pointer;
    &:hover {
      .billing-overall-content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.HCC-TransactionList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(2) {
            min-width: 3rem;
            max-width: 4rem;
          }
          &:nth-child(3) {
            min-width: 15rem;
            max-width: 20rem;
          }
        }
      }
    }
  }
}
