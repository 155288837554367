.HCC-Row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -.5rem;
  margin-left: -.5rem;
}

.HCC-Col {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.HCC-Col-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.HCC-Col-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.HCC-Col-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

@media screen and (max-width: 512px) {
  .HCC-Col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .HCC-Col-md {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .HCC-Col-md-4 {
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }

  .HCC-Col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .HCC-Col > div {
    position: relative;
  }
}

@media screen and (min-width: 1280px) {
  .HCC-Col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .HCC-Col-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .HCC-Col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .HCC-Col-lg-8 {
    -ms-flex-preferred-size: 66.66666666%;
    flex-basis: 66.66666666%;
    max-width: 66.66666666%;
  }
}

@media screen and (min-width: 1440px) {
  .HCC-Col-slg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .HCC-Col-slg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .HCC-Col-slg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .HCC-Col-slg-8 {
    -ms-flex-preferred-size: 66.66666666%;
    flex-basis: 66.66666666%;
    max-width: 66.66666666%;
  }
}
