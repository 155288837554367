@import 'polaris/icons';
@import 'polaris/buttons';
@import 'polaris/navigation';
@import 'polaris/topbar';
.Polaris-Navigation {
  user-select: none;
}
.Polaris-Modal__Body {
  > .Polaris-Card {
    border-radius: 0;
  }
}
// .Polaris-Button--disabled {
//   opacity: 0.5;
//   color: white !important;
// }
.Polaris-Button--primary {
  background: #e85d04;
}
.Polaris-Button--primary:hover {
  background: #e85d04;
}
.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title::before {
  background: #e85d04 !important;
}
.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
  background: #e85d04 !important;
}
.Polaris-Header-Title {
  font-weight: bolder;
}
