.HCC-OtherFeeList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(1), &:nth-last-child(3) {
            min-width: 15rem;
            max-width: 15rem;
          }
          &:nth-last-child(1), &:nth-last-child(2) {
            min-width: 10rem;
            max-width: 10rem;
          }
        }
      }
    }
  }
}

.HCC-OtherFee__ConditionValue--column {
  max-width: 50rem;
}

.HCC-OtherFee__ConditionValue--wrapper {
  .Polaris-Icon {
    zoom: 0.5;
  }
  &.multi-group {
    .HCC-OtherFee__ConditionValue--group {
      margin-left: 1.6rem;
      &:last-child {
        margin-bottom: -0.8rem;
      }
      .Polaris-List__Item:last-child {
        margin-bottom: 0.8rem;
      }
    }
  }
}
