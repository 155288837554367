.react-checkbox-tree {
  font-size: 1.4rem !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}
// .rct-checkbox {
//   padding: 5px 5px !important;
// }
.rct-icon-half-check::before,
.rct-icon-check::before,
.rct-icon-uncheck::before {
  content: '' !important;
}
.rct-icon-check,
.rct-icon-uncheck,
.rct-icon-half-check {
  transform: translateY(3px);
  display: flex;
  align-self: center;
  position: relative;
  height: 16px !important;
  width: 16px !important;
  border: 1px solid black;
  border-radius: 4px;
}
.rct-icon-check,
.rct-icon-half-check {
  background-color: #e85d04 !important;
}
.rct-icon-check::after,
.rct-icon-half-check::after {
  content: '' !important;
  display: block;
  position: absolute;
  top: -1px;
  left: 4px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
