.HCC-ForgotPassword__label{
  color:black;
  padding: 2rem 0 1rem;
}
.HCC-ForgotPassword_successForm{
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 0 3px 6px 0 #888888;
  width: 86%;
  margin-left: 7%;
}
.HCC-ForgotPassword_successForm__topLabel{
  text-align: center;
  color: #e85d04 !important;
  padding-bottom: 1rem;
}
.HCC-ForgotPassword_successForm__content{
  color: gray;
}