/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #00000026;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background-color: #c1c1c1;
  border-radius: 10px;
  cursor: pointer;
}

.HCC-ScrollBar--isSubdued {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #0000000d;
  }
}
